import { NMAAHCPropTypes } from "assets";
import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";

import { NMAAHC_OBJECT_REGEX } from "../metadata/metadata.jsx";

// Openseadragon components must be lazy loaded for gatsby build to pass
const IIIFViewer = lazy(() => import("./iiif-viewer.jsx"));

const IIIFContainer = ({ count, image }) => {
  const isNMAAHCObject = NMAAHC_OBJECT_REGEX.test(image.objectNumber);
  const iiifImageApiUrl =
    image.iiifUrl ||
    (isNMAAHCObject &&
      `https://ids.si.edu/ids/iiif/NMAAHC-${image.title.replaceAll(
        " ",
        "_"
      )}/info.json`);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <IIIFViewer
        count={count}
        iiifImageApiUrl={iiifImageApiUrl}
        imageUrl={image.url}
      />
    </Suspense>
  );
};

IIIFContainer.propTypes = {
  count: PropTypes.number,
  image: NMAAHCPropTypes.Image.isRequired,
};

export default IIIFContainer;

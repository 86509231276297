import { NMAAHCPropTypes, Theme, VideoSourceParser } from "assets";
import { ActionButton, FormattedText, Metadata, VideoPlayer } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./video-promo.module.scss";

const VideoPromo = ({
  backgroundCoverToggle,
  backgroundImageUrl,
  caption,
  coverImage,
  fontColor,
  headline,
  isConstellation,
  showMetadata,
  screenReaderText,
  src,
  withComponentSpacing,
}) => {
  const [showVideo, setShowVideo] = useState(false);

  const captionClassName = classNames(
    styles.caption,
    Theme.addClass(Theme.Subtle, false)
  );

  const headlineClassName = classNames(styles.headline, styles.leftAlign);

  const containerClassName = classNames(styles.videoPromo, {
    "container-fluid": withComponentSpacing,
    [styles.componentMargin]: withComponentSpacing,
    [styles.lightFont]: fontColor == "light",
    [styles.darkFont]: fontColor == "dark",
    [styles.withBackgroundImage]: backgroundImageUrl,
    [styles.backgroundCover]: backgroundCoverToggle,
  });

  const rowClassName = classNames("center-xs", {
    row: withComponentSpacing,
  });

  const columnClassName = classNames({
    "col-xs-12 col-sm-10 col-md-9 col-lg-7": withComponentSpacing,
  });

  return (
    <div
      className={containerClassName}
      data-testid="video-promo"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={rowClassName}>
        <div className={columnClassName}>
          <FormattedText
            className={headlineClassName}
            outerElement={<h2 />}
            text={headline}
            deepLink
          />
          {showVideo && <VideoPlayer muted={false} src={src} autoplay ui />}
          <div className={styles.coverImage}>
            {!showVideo && (
              <GatsbyImage
                alt={coverImage?.altText}
                image={getImage(coverImage?.landscape)}
              />
            )}
            <div className={styles.summary}>
              {!showVideo && (
                <ActionButton
                  icon="play"
                  onClick={() => setShowVideo(true)}
                  screenReaderText={screenReaderText || headline}
                  text={"Watch Video"}
                />
              )}
              <FormattedText className={captionClassName} text={caption} />
              {showMetadata && (
                <Metadata
                  {...coverImage}
                  noLine={isConstellation || !caption}
                  type="component"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoPromo.propTypes = {
  backgroundCoverToggle: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  caption: PropTypes.string,
  coverImage: NMAAHCPropTypes.Image,
  fontColor: PropTypes.string,
  headline: PropTypes.string,
  isConstellation: PropTypes.bool,
  screenReaderText: PropTypes.string,
  showMetadata: PropTypes.bool,
  src: PropTypes.string.isRequired,
  withComponentSpacing: PropTypes.bool,
};

VideoPromo.defaultProps = {
  isConstellation: false,
  showMetadata: true,
  withComponentSpacing: true,
};

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const VideoPromoFragment = graphql`
  fragment VideoPromoFragment on CraftAPI_componentList_videoPromo_BlockType {
    __typename
    id
    coverImage {
      ... on CraftAPI_image_Asset {
        ...ImageMetadataFragment
        id
        title
        url
        landscape: imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 2)
          }
        }
        portrait: imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        altText
      }
    }
    caption
    fontColor
    headline
    src
    backgroundCoverToggle
    backgroundImage {
      ... on CraftAPI_image_Asset {
        url
      }
    }
    videoSrc {
      embeddedAsset {
        url
        code
      }
    }
  }
`;

const convert = (videoPromo) => {
  return (
    <VideoPromo
      backgroundCoverToggle={videoPromo.backgroundCoverToggle}
      backgroundImageUrl={videoPromo.backgroundImage?.[0]?.url}
      caption={videoPromo.caption}
      coverImage={videoPromo?.coverImage[0]}
      fontColor={videoPromo?.fontColor}
      headline={videoPromo.headline}
      key={videoPromo.id}
      src={
        videoPromo?.src ||
        VideoSourceParser.getValidSource(videoPromo.videoSrc[0])
      }
    />
  );
};

export { convert, VideoPromo as default, VideoPromoFragment };

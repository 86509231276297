// extracted by mini-css-extract-plugin
export var adjustNumericFont = "chrono-slider-module--adjustNumericFont--cQITr";
export var area = "chrono-slider-module--area--qPHVA";
export var chronoBullet = "chrono-slider-module--chronoBullet--jZM9b";
export var chronoKnob = "chrono-slider-module--chronoKnob--Lv-Vj";
export var chronoSlider = "chrono-slider-module--chronoSlider--YUcwQ";
export var chronoTrack = "chrono-slider-module--chronoTrack--7aj3l";
export var displayChronoKnob = "chrono-slider-module--displayChronoKnob--6Oimy";
export var displayYear = "chrono-slider-module--displayYear--trgm8";
export var endYear = "chrono-slider-module--endYear--ZoRLN";
export var fill = "chrono-slider-module--fill--cOPGW";
export var startYear = "chrono-slider-module--startYear--qSxMz";
export var values = "chrono-slider-module--values--Lcn2g";
/** Zero-indexed roman numeral conversion **/
const RomanNumerals = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
];

export default RomanNumerals;

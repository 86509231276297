import { Theme } from "assets";
import DirectionNavigationLink from "atoms/direction-navigation-link/direction-navigation-link";
import classNames from "classnames";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import React from "react";

import * as styles from "./direction-navigation.module.scss";

const DirectionNavigation = ({ nextTarget, previousTarget, theme }) => {
  let containerClasses = classNames(
    Theme.addClass(theme, true),
    styles.linksWrapper,
    "row"
  );

  // Only set the cluster nav buttons to the 6-column layout if both exist
  const linkClasses = classNames("col-xs-12 col-md-6 col-lg-5");

  return (
    <div className={containerClasses} data-testid="direction-navigation">
      {previousTarget !== null && (
        <div className={linkClasses}>
          <DirectionNavigationLink
            direction={"backward"}
            target={previousTarget}
          />
        </div>
      )}
      {previousTarget === null && <div className={linkClasses} />}
      {nextTarget !== null && (
        <div className={linkClasses}>
          <DirectionNavigationLink direction={"forward"} target={nextTarget} />
        </div>
      )}
      {nextTarget === null && <div className={linkClasses} />}
    </div>
  );
};

DirectionNavigation.propTypes = {
  nextTarget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  previousTarget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  ...Theme.PropType,
};

DirectionNavigation.defaultProps = {
  theme: Theme.Black,
};

const CHAPTER_TYPE = "chapter";
const CLUSTER_TYPE = "cluster";
const DETAIL_TYPE = "detail";
const EXHIBIT_TYPE = "exhibit";
const PILLAR_TYPE = "pillar";
const PTPTOPIC_TYPE = "ptpTopic";
const STORY_TYPE = "story";

const DirectionNavigationFragment = graphql`
  fragment DirectionNavigationFragment on CraftAPI_EntryInterface {
    parent {
      id
    }
    prev(section: $section) {
      id
      prev(section: $section) {
        id
        title
        uri
        typeHandle
      }
      title
      uri
      typeHandle
    }
    next(section: $section) {
      id
      title
      uri
      typeHandle
    }
    typeHandle
  }
`;

const TypedDirectionNavigationFragment = graphql`
  fragment TypedDirectionNavigationFragment on CraftAPI_EntryInterface {
    prev(section: $section, type: $type) {
      id
      title
      uri
      typeHandle
    }
    next(section: $section, type: $type) {
      id
      title
      uri
      typeHandle
    }
    typeHandle
  }
`;

/**
 * Converts the provided page heirarchy data into a directional navigation
 *
 * @param pageData the GraphQL response data
 * @returns           the Cluster page and its child components
 */
const convert = (pageData) => {
  let { parent, prev, next, typeHandle } = pageData;

  if (typeHandle === CLUSTER_TYPE && prev?.id === parent?.id) {
    // If the previous page for a cluster is actually the chapter parent, we actually want to go back one more step previous
    prev = prev.prev;
  }

  if ((typeHandle === PTPTOPIC_TYPE || typeHandle === STORY_TYPE) && !next) {
    next = {
      title: "Stories",
      id: "111111111",
      uri: "stories",
    };
  }

  // if current page is the last page in the exhibit, send users to exhibits landing page
  if (
    next?.typeHandle === EXHIBIT_TYPE ||
    (next?.typeHandle === DETAIL_TYPE &&
      [PILLAR_TYPE, CHAPTER_TYPE, CLUSTER_TYPE].includes(prev?.typeHandle))
  ) {
    next = {
      title: "All Exhibitions",
      id: "111111111",
      uri: "exhibitions",
    };
  }

  if (typeHandle === EXHIBIT_TYPE) {
    next = pageData?.children?.[0] || null;
    prev = {
      title: "All Exhibitions",
      id: "111111111",
      uri: "exhibitions",
    };
  }

  // if either next or previous type is a detail page, remove it from the navigation
  if (next?.typeHandle === DETAIL_TYPE) {
    next = null;
  }
  if (prev?.typeHandle === DETAIL_TYPE) {
    prev = null;
  }

  return (
    <nav
      aria-label="Directional Navigation"
      className={styles.navCtn}
      key="nav-upnext"
    >
      <div className={styles.linkCtn}>
        <DirectionNavigation nextTarget={next} previousTarget={prev} />
      </div>
    </nav>
  );
};

export {
  convert,
  DirectionNavigation as default,
  DirectionNavigationFragment,
  TypedDirectionNavigationFragment,
};

// extracted by mini-css-extract-plugin
export var bgOverlay = "text-hero-module--bg-overlay--JRKGM";
export var btn = "text-hero-module--btn--NpkY+";
export var chapterHero = "text-hero-module--chapterHero--Mo+vN";
export var collapseOverlay = "text-hero-module--collapse-overlay--uyTxC";
export var collapseSubtitle = "text-hero-module--collapse-subtitle--zekgh";
export var collapseText = "text-hero-module--collapse-text--wHTb+";
export var detailHero = "text-hero-module--detailHero--JnUOX";
export var hero = "text-hero-module--hero--QNx0Y";
export var listen = "text-hero-module--listen--bcBB7";
export var subtitle = "text-hero-module--subtitle--QGr7J";
export var subtitleContainer = "text-hero-module--subtitle-container--H1Y4M";
export var textOverlay = "text-hero-module--text-overlay--ylqgK";
export var titleContainer = "text-hero-module--title-container--nLPzW";
import { Theme, ThemeContext } from "assets";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";

import * as styles from "./carousel-wrapper.module.scss";

const CarouselWrapper = ({
  children,
  fontColor,
  onSelectedIndex,
  overrideScrollTo,
  showNavDots,
  startIndex,
  textAlignment,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({ containScroll: "trimSnaps" });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(startIndex);
  const { theme } = useContext(ThemeContext);

  const lightFont =
    fontColor === "light" ||
    theme === Theme.Dark ||
    theme === Theme.Black ||
    theme === Theme.Afrofuturism;

  const scrollTo = useCallback(
    (index, jump) => embla?.scrollTo(index, jump),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    onSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    scrollTo(startIndex, true);
  }, [embla, startIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  useEffect(() => {
    if (!embla) return;
    // fixes issue with tabbing back to first slide
    if (overrideScrollTo === 0) scrollTo(startIndex, false);
    if (overrideScrollTo) scrollTo(overrideScrollTo, false);
  }, [embla, overrideScrollTo]);

  const navDots = (index) => {
    return (
      <li className={styles.carouselNavDots} key={index}>
        <button
          aria-label={"Quote " + (index + 1)}
          className={classNames(styles.carouselNavDot, {
            [styles.activeNavDot]: index === selectedIndex || startIndex,
          })}
          onClick={() => {
            scrollTo(index, false);
          }}
        >
          &nbsp;
        </button>
      </li>
    );
  };

  return (
    <>
      <div className={styles.carousel}>
        <div className={`embla ${styles.carouselViewport}`} ref={viewportRef}>
          <div className={`embla__container ${styles.carouselContainer}`}>
            {children}
          </div>
          {showNavDots && (
            <ul
              className={classNames(styles.carouselNav, {
                [styles.lightFont]: lightFont,
                [styles.leftAlign]: textAlignment === "left",
              })}
            >
              {children?.map((child, index) => navDots(index))}
            </ul>
          )}
        </div>
        <button
          aria-label={"Previous Slide"}
          className={classNames(
            styles.carouselButton,
            styles.carouselButtonPrev,
            {
              [styles.lightFont]: lightFont,
            }
          )}
          hidden={!prevBtnEnabled}
          onClick={() => {
            scrollTo(selectedIndex - 1, false);
          }}
        >
          <i className="icon-back"></i>
        </button>
        <button
          aria-label={"Next Slide"}
          className={classNames(
            styles.carouselButton,
            styles.carouselButtonNext,
            {
              [styles.lightFont]: lightFont,
            }
          )}
          hidden={!nextBtnEnabled}
          onClick={() => {
            scrollTo(selectedIndex + 1, false);
          }}
        >
          <i className="icon-forward"></i>
        </button>
      </div>
    </>
  );
};

CarouselWrapper.propTypes = {
  children: PropTypes.any,
  fontColor: PropTypes.string,
  onSelectedIndex: PropTypes.func,
  overrideScrollTo: PropTypes.number,
  showNavDots: PropTypes.bool,
  startIndex: PropTypes.number,
  textAlignment: PropTypes.string,
};

CarouselWrapper.defaultProps = {
  showNavDots: false,
  startIndex: 0,
};

export default CarouselWrapper;

import "./nmaahc-modal-window.scss";

import { Display, NMAAHCPropTypes, Theme } from "assets";
import { CloseButton, FormattedText, Header, Listen, Metadata } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";

import * as styles from "./nmaahc-modal-window.module.scss";

const NMAAHCModalWindow = ({
  applyTransition,
  audioAsset,
  backgroundImage,
  backgroundCover,
  children,
  closeTo,
  closeToState,
  dissectionShift,
  halfWidth,
  label,
  leftSide,
  metadata,
  onClose,
  showModal,
  subtitle,
  text,
  showClose,
  theme,
  title,
}) => {
  const modalHeaderClassName = classNames({
    [styles.modalHeader]: true,
    ["ReactModal__Overlay--light"]: theme === Theme.Light,
    ["ReactModal__Overlay--dark"]: theme === Theme.Dark,
    ["ReactModal__Overlay--black"]: theme === Theme.Black,
  });

  const textClassName = classNames({
    [styles.modalText]: true,
    [styles.modalTextLight]: theme === Theme.Light,
    [styles.modalTextDark]: theme === Theme.Dark,
    ...Theme.addClass(theme, false),
  });

  const closeBarClassName = classNames(
    styles.closeBar,
    Theme.addClass(theme, false),
    {
      "half-width-modal-button-bar": halfWidth,
    }
  );

  const overlayClassName = classNames(`ReactModal__Overlay--${theme}`, {
    "ReactModal__Overlay--no-transition": !applyTransition,
    "half-width-modal-container": halfWidth,
    "half-width-modal-left-side": halfWidth && leftSide,
  });

  const appElement =
    typeof document !== "undefined"
      ? document.getElementsByTagName("main")[0]
      : undefined;

  const customStyles = {
    overlay: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "auto auto",
    },
    content: {
      backgroundColor: "transparent",
    },
  };

  if (backgroundCover) {
    (customStyles.overlay.width = "100vw"),
    (customStyles.overlay.marginLeft = "50%"),
    (customStyles.overlay.transform = "translateX(-50%)"),
    (customStyles.overlay.backgroundPosition = "center");
    customStyles.overlay.backgroundSize = "cover";
  }

  return (
    <Modal
      appElement={appElement}
      className={`ReactModal__Content--${theme}`}
      closeTimeoutMS={500}
      contentLabel={label}
      isOpen={showModal}
      onRequestClose={onClose}
      overlayClassName={overlayClassName}
      style={customStyles}
      preventScroll
    >
      <div className={closeBarClassName}>
        <CloseButton
          labelDisplay={Display.Show}
          onClick={onClose}
          show={showClose}
          theme={theme}
          to={closeTo}
          toState={closeToState}
        />
      </div>
      <div
        className={classNames(styles.modalContentContainer, {
          [styles.heightAllScreen]: metadata === undefined,
        })}
        data-testid="modal-content"
      >
        <div
          className={classNames("row", "center-xs", {
            [styles.heightAllScreen]: metadata === undefined,
            [styles.dissectionShift]: dissectionShift,
          })}
          data="test"
        >
          <div className={classNames("col-xs", styles.maxHeight)}>
            <div className={modalHeaderClassName}>
              <Header subtitle={subtitle} title={title} />
            </div>
            {children}
          </div>
        </div>
        {metadata && <Metadata {...metadata} theme={theme} />}
        {text && (
          <div className="row center-xs">
            <div className="col-xs-10 col-sm-8">
              <FormattedText
                className={textClassName}
                text={text}
                theme={theme}
              />
            </div>
          </div>
        )}
        {audioAsset?.title && audioAsset?.url && (
          <div className="row center-xs">
            <div className={`${styles.listen} col-xs-10 col-sm-8`}>
              <Listen audioAsset={audioAsset} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

NMAAHCModalWindow.propTypes = {
  applyTransition: PropTypes.bool,
  audioAsset: NMAAHCPropTypes.Audio,
  buttonBarClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  closeTo: PropTypes.string,
  closeToState: PropTypes.shape({}),
  containerClass: PropTypes.string,
  dissectionShift: PropTypes.bool,
  halfWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  leftSide: PropTypes.bool,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  ...Theme.PropType,
  ...Metadata.PropType,
};

NMAAHCModalWindow.defaultProps = {
  applyTransition: true,
  dissectionShift: false,
  halfWidth: false,
  showClose: true,
  theme: Theme.Dark,
};

export default NMAAHCModalWindow;

import { NMAAHCPropTypes, Theme } from "assets";
import { ImageWithCaption } from "atoms";
import { ImageModal, MediaCarouselModal } from "molecules";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

const ExpandableImage = ({
  audioAsset,
  centeredCaption,
  defaultExpand,
  galleryMedia,
  galleryStartIndex,
  image,
  imageFullSize,
  imageCaption,
  imageClassName,
  imageLayout,
  imageObjectFit,
  imageObjectPosition,
  imageWrapperClassName,
  video,
  label,
  modalCaptionOnly,
  modalText,
  withIcon,
  onClose,
  onImageReveal,
  subtitle,
  theme,
  title,
}) => {
  const [showModal, setShowModal] = useState(defaultExpand);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    onClose();
    setShowModal(false);
  };

  const imageContainer = () => {
    return (
      <ImageWithCaption
        audioAsset={audioAsset}
        caption={
          modalCaptionOnly
            ? undefined
            : imageCaption ||
              image?.displayTitle ||
              image?.objectTitle ||
              image?.objectName
        }
        centeredCaption={centeredCaption}
        fullImgClickable={!video}
        image={image}
        imageClassName={imageClassName}
        imageWrapperClassName={imageWrapperClassName}
        layout={imageLayout}
        objectFit={imageObjectFit}
        objectPosition={imageObjectPosition}
        onExpand={openModal}
        title={title}
        video={video}
        withIcon={video ? false : withIcon}
      />
    );
  };

  const carouselModal = () => {
    return (
      showModal && (
        <MediaCarouselModal
          media={galleryMedia}
          onClose={closeModal}
          onImageReveal={(slideIndex) => {
            onImageReveal(slideIndex);
          }}
          showModal={showModal}
          startIndex={galleryStartIndex}
        />
      )
    );
  };

  const imageModal = () => {
    return (
      showModal && (
        <ImageModal
          audioAsset={audioAsset}
          image={imageFullSize ? imageFullSize : image}
          label={label}
          onClose={closeModal}
          showModal={showModal}
          subtitle={subtitle}
          text={modalText}
          theme={theme}
          title={title}
        />
      )
    );
  };

  return (
    <Fragment>
      {imageContainer()}
      {galleryMedia ? carouselModal() : imageModal()}
    </Fragment>
  );
};

ExpandableImage.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  centeredCaption: PropTypes.bool,
  defaultExpand: PropTypes.bool,
  galleryMedia: PropTypes.array,
  galleryStartIndex: PropTypes.number,
  image: NMAAHCPropTypes.Image.isRequired,
  imageCaption: PropTypes.string,
  imageClassName: PropTypes.string,
  imageFullSize: NMAAHCPropTypes.Image,
  imageLayout: PropTypes.oneOf(["constrained", "fixed", "fullWidth"]),
  imageObjectFit: NMAAHCPropTypes.ImageObjectFit,
  imageObjectPosition: PropTypes.string,
  imageWrapperClassName: PropTypes.string,
  label: PropTypes.string,
  linkField: PropTypes.string,
  modalCaptionOnly: PropTypes.bool,
  modalText: PropTypes.string,
  onClose: PropTypes.func,
  onImageReveal: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.object,
  withIcon: PropTypes.bool,
  ...Theme.PropType,
};

ExpandableImage.defaultProps = {
  centeredCaption: false,
  defaultExpand: false,
  video: null,
  modalCaptionOnly: false,
  withIcon: true,
  label: "Image Modal",
  theme: Theme.Dark,
  onImageReveal: () => {},
  onClose: () => {},
};

export default ExpandableImage;

// extracted by mini-css-extract-plugin
export var endPageMatchHeight = "dissection-points-module--endPageMatchHeight--+X0RH";
export var image = "dissection-points-module--image--6QAjP";
export var imageWrapper = "dissection-points-module--image-wrapper--zSqHA";
export var minDesktopWidth = "768px";
export var pin = "dissection-points-module--pin--ZLt+7";
export var pinActive = "dissection-points-module--pinActive--TnVnQ";
export var pinContentNavMobile = "dissection-points-module--pinContentNavMobile--p8awg";
export var pinContentNavWindow = "dissection-points-module--pinContentNavWindow--a9n6Q";
export var pinContentShowTranscript = "dissection-points-module--pinContentShowTranscript--NNwIN";
export var pinContentShowTranscriptCaret = "dissection-points-module--pinContentShowTranscriptCaret--VBQDQ";
export var pinContentShowTranscriptIcon = "dissection-points-module--pinContentShowTranscriptIcon--Z25Xt";
export var pinContentShowTranscriptLeft = "dissection-points-module--pinContentShowTranscriptLeft--WJTG6";
export var pinContentShowTranscriptText = "dissection-points-module--pinContentShowTranscriptText--Fr6he";
export var pinContentTitle = "dissection-points-module--pinContentTitle--RF-Nx";
export var pinImage = "dissection-points-module--pinImage--33271";
export var pinList = "dissection-points-module--pinList--4+Nwp";
export var pinSpot = "dissection-points-module--pinSpot--PSrLi";
export var pinSpotButton = "dissection-points-module--pinSpotButton--HOUFR";
export var pinSpotPlain = "dissection-points-module--pinSpotPlain--wKQUX";
export var pinSpotViewed = "dissection-points-module--pinSpotViewed--PXDB2";
export var pinTextWrapper = "dissection-points-module--pinTextWrapper--tmnvs";
export var pinWindow = "dissection-points-module--pinWindow--O3kdK";
export var pinWindowActive = "dissection-points-module--pinWindowActive--lTwch";
export var pinWindowBottom = "dissection-points-module--pinWindowBottom--mYgEb";
export var pinWindowInfo = "dissection-points-module--pinWindowInfo--kVSBT";
export var pinWindowInfoAbout = "dissection-points-module--pinWindowInfoAbout--89-Bt";
export var pinWindowRight = "dissection-points-module--pinWindowRight--oMPhe";
export var pinWindowScrollWrapper = "dissection-points-module--pinWindowScrollWrapper--Jlzc7";
export var pinWindowText = "dissection-points-module--pinWindowText--tEJSw";
export var transcriptToggle = "dissection-points-module--transcript-toggle--T77Tv";
export var transform = "dissection-points-module--transform--EhMZ1";
export var turningThePageImageCropping = "dissection-points-module--turningThePageImageCropping--Kz4yx";
export var turningThePageWrapper = "dissection-points-module--turningThePageWrapper--sq6o+";
export var verticalScrollContainer = "dissection-points-module--verticalScrollContainer--fiE-c";
export var wrapper = "dissection-points-module--wrapper--VLvXT";
import { Display, Theme } from "assets";
import { FormattedText } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { ChronoSlider, SubNavList } from "molecules";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./chrono-navigation.module.scss";

const getYear = (str) =>
  moment().isSameOrBefore(str, "year") ? "Today" : moment(str).format("YYYY");

const isNumeric = /\d+/;

const ChronoNavigation = ({ activePageId, dates, parent, title }) => {
  const [expanded, setExpanded] = useState(false);

  const expandToggle = (e) => {
    e?.preventDefault();
    setExpanded(!expanded);
  };

  // sort date objects
  const sorted = dates?.slice().sort((a, b) => b.startDate - a.startDate);
  let currentYear;
  const subNavData = sorted.map(({ id, startDate, title, uri }) => {
    if (id === activePageId) {
      currentYear = getYear(startDate);
    }
    return {
      activePage: id === activePageId,
      subtitle: getYear(startDate),
      to: `/${uri}`,
      title,
    };
  });

  const buttonClass = classNames(styles.expandButton, {
    [styles.expanded]: expanded,
    [styles.bumpUp]: isNumeric.test(currentYear),
  });

  const expanderClass = classNames(styles.expander, {
    [styles.expanded]: expanded,
    row: true,
    ...Theme.addClass(Theme.Dark, true),
  });

  const chronoSliderData = dates.map(({ id, startDate, uri }) => ({
    id,
    label: getYear(startDate),
    path: uri,
  }));

  return (
    <nav
      className={classNames(
        styles.container,
        Theme.addClass(Theme.Black, true)
      )}
      data-testid="chrono-navigation"
    >
      <div className="row center-xs">
        <div className="col-xs-11">
          <div className={styles.chronoIntro}>
            <FormattedText text={"Stories from present to past"} />
            <FormattedText
              text={"Use the slider to explore significant moments by year"}
            />
          </div>
        </div>
      </div>
      <div className="hidden-mobile">
        <div className="row middle-sm">
          <div className="col-sm-offset-1 col-md-offset-2">
            <div className={styles.chronoNavigationLabel}>
              <span className={styles.title}>
                <i className={`${styles.icon} ${styles.rewind} icon-rewind`} />
                <strong>Years</strong>
              </span>
            </div>
          </div>
          <div className="col-sm-7 col-md-6">
            <ChronoSlider
              activePageId={activePageId}
              data={chronoSliderData}
              reverse
            />
          </div>
        </div>
      </div>
      <div className="hidden-desktop hidden-tablet">
        <div
          className={classNames("container-fluid", {
            [styles.grey]: expanded,
          })}
        >
          <div
            className={classNames("row", "middle-xs", {
              "center-xs": expanded,
            })}
          >
            {!expanded && (
              <div className="col-xs-8 col-sm-9">
                <button
                  className={styles.titleButton}
                  data-testid="expand-button"
                  onClick={expandToggle}
                >
                  <i
                    className={`${styles.icon} ${styles.rewind} icon-rewind`}
                  />
                  <strong>Year</strong>
                </button>
              </div>
            )}
            <div className="col-xs-3">
              <button
                className={buttonClass}
                data-testid="expand-button"
                onClick={expandToggle}
              >
                {expanded ? "Hide" : currentYear}
                <i aria-label="Expand" className="icon-expand-vertical" />
              </button>
            </div>
          </div>
        </div>
        <div className={classNames("container-fluid", styles.grey)}>
          <div className="row">
            <div className="col-xs-12">
              <div className={expanderClass} data-testid="expander">
                <h2>{title}</h2>
                <SubNavList
                  data={subNavData}
                  mode="timeline"
                  showBylines={Display.Hidden}
                  showDividers={Display.Hidden}
                  theme={Theme.Dark}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

ChronoNavigation.propTypes = {
  activePageId: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.shape({})),
  parent: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
  }),
  title: PropTypes.string,
};

ChronoNavigation.defaultProps = {
  dates: [],
};

const ChronoNavigationFragment = graphql`
  fragment ChronoNavigationFragment on CraftAPI_story_ptpEntry_Entry {
    id
    parent {
      title
      dates: children {
        ... on CraftAPI_story_ptpEntry_Entry {
          id
          startDate
          title
          uri
        }
        title
      }
      parent {
        title
        uri
      }
    }
  }
`;

/**
 * Converts the provided chapter navigation data into a chapter navigation
 *
 * @param chronoNavData  the GraphQL response data
 * @returns               the chapter navigation
 */
const convert = (chronoNavData) => {
  return (
    <ChronoNavigation
      {...chronoNavData.parent}
      activePageId={chronoNavData.id}
      dates={chronoNavData.parent.dates}
      key="chrono-navigation"
    />
  );
};

export { ChronoNavigationFragment, convert, ChronoNavigation as default };

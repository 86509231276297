// extracted by mini-css-extract-plugin
export var byline = "card-module--byline--PDPOs";
export var caption = "card-module--caption--fDqYa";
export var card = "card-module--card--mMCO5";
export var cardContent = "card-module--card-content--MUatB";
export var errorText = "card-module--errorText--iwQNa";
export var explored = "card-module--explored--jCzJE";
export var externalLinkIcon = "card-module--externalLinkIcon--ZdNGZ";
export var externalLinkIconContainer = "card-module--externalLinkIconContainer--Xmo2H";
export var horizontalLayout = "card-module--horizontalLayout--lysO3";
export var landscapeImage = "card-module--landscape-image--Ikyfz";
export var portraitImage = "card-module--portrait-image--R-6iA";
export var readMore = "card-module--readMore--vnk6q";
export var status = "card-module--status--nErMK";
export var storyCard = "card-module--storyCard--0Hxow";
export var storyCardImage = "card-module--storyCardImage--W-20t";
export var storyType = "card-module--storyType--TUDJ8";
export var storybook = "card-module--storybook--WhOt8";
export var subtitle = "card-module--subtitle--c2LU2";
export var tagContainer = "card-module--tagContainer--dDgMy";
export var tags = "card-module--tags--n3AV0";
export var title = "card-module--title--Cwj7A";
export var titleLink = "card-module--titleLink--+tTa5";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./checkbox.module.scss";

const Checkbox = ({ checked, onClick }) => {
  const checkboxClass = classNames(styles.generalCheckboxStyles, {
    [styles.checked]: checked,
  });

  const checkedCenterClass = classNames({
    [styles.checkedCenter]: checked,
    [styles.dontShow]: !checked,
  });

  return (
    <div
      className={checkboxClass}
      data-testid="checkbox"
      onClick={() => onClick()}
    >
      <div className={checkedCenterClass} />
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;

import { Theme, ThemeContext } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import * as styles from "./scroller.module.scss";

const MIN_BAR_LENGTH = 20;

const HORIZONTAL = "horizontal";
const VERTICAL = "vertical";

/**
 * Calculates the width of the Embla container element
 *
 * @param container   the embla container element
 * @returns           the width in pixels
 */
const calcEmblaWidth = (container) =>
  container?.childNodes
    ? Object.values(container.childNodes).reduce(
      (total, i) => total + i.offsetWidth,
      0
    )
    : 0;

/**
 * Returns the slide index that should be scrolled to based on the provided
 * percentage. Embla does not allow for programmatically scrolling to an
 * arbitrary percentage, so this attempts to pick the closest slide.
 *
 * @param container   the embla container element
 * @param percentage  the percentage that has been scrolled to
 * @return            the embla slide best matching the percentage
 */
const getEmblaIndex = (container, percentage) => {
  // calculate the number of pixels from the left we should scroll to
  const offset = percentage * calcEmblaWidth(container);
  const slides = Object.values(container.childNodes);

  for (let i = 0; i < slides.length; i++) {
    // reached last slide, return this index
    if (i === slides.length - 1) return i;
    // calculate the pixel width of all slides up until ths one
    const priorSlideWidths = slides
      .filter((c, j) => j < i)
      .reduce((total, c) => total + c.offsetWidth, 0);
    // check if the offset falls within the current slideWidths
    if (priorSlideWidths + slides[i].offsetWidth >= offset) {
      // offset falls within this slide, but it may actually be closer to the next slide
      if ((offset - priorSlideWidths) / slides[i].offsetWidth > 0.5)
        return i + 1;
      else return i;
    }
  }

  return 0;
};

const Scroller = forwardRef(({ direction, embla, scrollBarColor }, ref) => {
  const horizontal = direction === HORIZONTAL;
  const trackRef = useRef();
  const [barMin, setBarMin] = useState(0);
  const [barLength, setBarLength] = useState(MIN_BAR_LENGTH);
  const [dragging, setDragging] = useState(false);
  const [mousePosition, setMousePosition] = useState(0);

  // Callback for mouse down on the bar which sets dragging state
  const onBarMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
    setMousePosition(horizontal ? e.clientX : e.clientY);
  }, []);

  // Callback for mouse down on the track itself which moves the scroll
  const onTrackMouseDown = useCallback(
    (e) => {
      const trackLength = horizontal
        ? trackRef.current.clientWidth
        : trackRef.current.clientHeight;
      const clickPoint = horizontal
        ? e.pageX - trackRef.current.offsetLeft
        : e.pageY - trackRef.current.offsetTop;
      // New offset for the bar - number of pixels from the start of the track
      const offset = Math.min(
        Math.max(0, clickPoint - barLength / 2),
        trackLength - barLength
      );

      const percentage = offset / (trackLength - barLength);
      // Scroll the actual container
      if (horizontal) {
        if (embla) {
          embla.scrollTo(getEmblaIndex(ref.current, percentage));
        } else {
          const { clientWidth, scrollWidth } = ref.current;
          ref.current.scrollLeft = (scrollWidth - clientWidth) * percentage;
        }
      } else {
        const { clientHeight, scrollHeight } = ref.current;
        ref.current.scrollTop = (scrollHeight - clientHeight) * percentage;
      }

      setBarMin(offset);
    },
    [embla]
  );

  // Callback for the document to capture the mouse up which will cancel dragging
  const onMouseUp = useCallback(
    (e) => {
      if (dragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [dragging, embla]
  );

  // Callback for the document to capture when the mouse moves which will scroll if dragging
  const onMouseMove = useCallback(
    (e) => {
      if (dragging && ref.current) {
        e.preventDefault();
        e.stopPropagation();

        const trackLength = horizontal
          ? trackRef.current.clientWidth
          : trackRef.current.clientHeight;
        const delta = (horizontal ? e.clientX : e.clientY) - mousePosition;
        // New offset for the bar - number of pixels from the start of the track
        const offset = Math.min(
          Math.max(0, barMin + delta),
          trackLength - barLength
        );

        const percentage = offset / (trackLength - barLength);
        // Scroll the actual container
        if (horizontal) {
          if (embla) {
            const index = getEmblaIndex(ref.current, percentage);
            embla.scrollTo(index);
          } else {
            const { clientWidth, scrollWidth } = ref.current;
            ref.current.scrollLeft = (scrollWidth - clientWidth) * percentage;
          }
        } else {
          const { clientHeight, scrollHeight } = ref.current;
          ref.current.scrollTop = (scrollHeight - clientHeight) * percentage;
        }

        setBarMin(offset);
        setMousePosition(horizontal ? e.clientX : e.clientY);
      }
    },
    [dragging, mousePosition, barMin, barLength, embla]
  );

  // Callback for when the user scrolls/pans the host container
  const onHostScroll = useCallback(() => {
    if (!ref.current) return;

    if (horizontal) {
      let scrollLeft;
      if (embla) {
        // user is currently dragging the scrollbar, which already updates the scroller so break here
        if (dragging) return;
        // Embla applies "translate3d(-14.1709%, 0px, 0px)" to the container - this will translate to the pixel amount
        scrollLeft =
          new DOMMatrix(window.getComputedStyle(ref.current).transform).e * -1;
      } else {
        scrollLeft = ref.current.scrollLeft;
      }
      const trackWidth = trackRef.current.clientWidth;

      setBarMin(
        Math.max(
          0,
          Math.min(
            (scrollLeft / ref.current.scrollWidth) * trackWidth,
            trackWidth - barLength
          )
        )
      );
    } else {
      const { scrollTop, scrollHeight } = ref.current;
      const trackHeight = trackRef.current.clientHeight;

      setBarMin(
        Math.min(
          (scrollTop / scrollHeight) * trackHeight,
          trackHeight - barLength
        )
      );
    }
  }, [dragging, embla]);

  // Called whenever the window is resized to calculate the width of the bar
  const onWindowResize = () => {
    if (ref?.current && trackRef?.current) {
      if (horizontal) {
        let totalWidth = embla
          ? calcEmblaWidth(ref.current)
          : ref.current.scrollWidth;

        const { clientWidth } = ref.current;
        const trackWidth = trackRef.current.clientWidth;

        setBarLength(
          Math.max((trackWidth * clientWidth) / totalWidth, MIN_BAR_LENGTH)
        );
      } else {
        const { clientHeight, scrollHeight } = ref.current;
        const trackHeight = trackRef.current.clientHeight;

        setBarLength(
          Math.max((trackHeight * clientHeight) / scrollHeight, MIN_BAR_LENGTH)
        );
      }
    }
  };

  // Calculate the scrollbar width on initial load
  useEffect(() => {
    onWindowResize();

    ref?.current.addEventListener("scroll", onHostScroll, true);
    return () => {
      ref?.current?.removeEventListener("scroll", onHostScroll, true);
    };
  }, []);

  useEffect(() => {
    if (embla) {
      onWindowResize();
      embla.on("scroll", onHostScroll);
    }

    return () => {
      embla?.off("scroll", onHostScroll);
    };
  }, [dragging, embla]);

  // Register document and window listeners
  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseleave", onMouseUp);
    document.addEventListener("mouseup", onMouseUp);
    window.addEventListener("resize", onWindowResize);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseleave", onMouseUp);
      document.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("resize", onWindowResize);
    };
  }, [onMouseMove, onMouseUp]);

  const { theme } = useContext(ThemeContext);

  const barColor =
    scrollBarColor ||
    (Theme.DarkBackgrounds.includes(theme) ? "light" : "dark");

  // Gets the height of the content inside an element that a scroll bar will appear in to determine if the content is shorter than it's parent.
  // Used to prevent scrollbars from appearing on double event card slides unless they are needed
  const getScrollContentHeight = () => {
    let contentHeight = 0;
    if (ref?.current?.childNodes?.length) {
      for (const item of ref?.current?.childNodes) {
        contentHeight += item.clientHeight || 0;
      }
    }
    return contentHeight;
  };

  const scrollContainerContentHeight = horizontal
    ? null
    : getScrollContentHeight();
  const hideScrollBar = horizontal
    ? barLength > trackRef?.current?.clientWidth * 0.97
    : trackRef?.current?.clientHeight >= scrollContainerContentHeight;

  const className = classNames(styles.track, {
    // If the bar is as wide as the track (or just about), that means we don't need a scrollbar
    [styles.hidden]: !ref?.current || hideScrollBar,
    [styles.vertical]: !horizontal,
    [styles.lightBar]: barColor === "light",
    [styles.darkBar]: barColor === "dark",
  });

  let style;
  if (horizontal) {
    style = { width: `${barLength}px`, left: `${barMin}px` };
  } else {
    style = { height: `${barLength}px`, top: `${barMin}px` };
  }

  return (
    <div
      className={className}
      data-testid="scroller"
      onMouseDown={onTrackMouseDown}
      ref={trackRef}
    >
      <div className={styles.bar} onMouseDown={onBarMouseDown} style={style} />
    </div>
  );
});

Scroller.displayName = "Scroller";

Scroller.propTypes = {
  direction: PropTypes.oneOf([HORIZONTAL, VERTICAL]),
  embla: PropTypes.shape({
    off: PropTypes.func.isRequired,
    on: PropTypes.func.isRequired,
    scrollTo: PropTypes.func.isRequired,
  }),
  scrollBarColor: PropTypes.oneOf(["light", "dark"]),
};

Scroller.defaultProps = {
  direction: HORIZONTAL,
};

export default Scroller;

import { MainNavItem } from "atoms";
import classNames from "classnames";
import { SubNavList } from "molecules";
import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";

import * as styles from "./mainnav-list.module.scss";

const MainNavList = forwardRef(({ data }, ref) => {
  // whether one of the navigation items is :active
  const [activeItem, setActiveItem] = useState(false);
  const [subNavFocus, setSubNavFocus] = useState(false);

  const navListClassName = classNames(styles.mainNavList, {
    [styles.expanded]: data?.find((d) => d.isTabbed),
    [styles.hasActiveItem]: activeItem,
  });

  return (
    <ul
      className={navListClassName}
      data-testid="mainnav-list"
      onBlur={() => setActiveItem(false)}
      onFocus={() => setActiveItem(true)}
      ref={ref}
    >
      {data?.map(
        ({
          id,
          hasSubNav,
          subnavContent,
          icon,
          isExternal,
          isTabbed,
          label,
          onClick,
          pageType,
          to,
        }) => {
          let click;
          if (onClick) {
            click = (e) => {
              if (isTabbed) {
                e.stopPropagation();
                onClick(e);
                setSubNavFocus(false);
              } else {
                onClick(e);
                // If the user opened the menu with a keyboard, this tells the subnav
                // to put the focus on its first item.
                setSubNavFocus(e?.detail === 0);
              }
            };
          }

          return (
            <li key={id}>
              <MainNavItem
                hasSubNav={hasSubNav}
                icon={icon}
                isExternal={isExternal}
                isTabbed={isTabbed}
                label={label}
                onClick={click}
                pageType={pageType}
                to={to}
              />
              {hasSubNav && isTabbed && (
                <SubNavList data={subnavContent} willFocus={subNavFocus} />
              )}
            </li>
          );
        }
      )}
    </ul>
  );
});

MainNavList.displayName = "MainNavList";

MainNavList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      hasSubNav: PropTypes.bool,
      id: PropTypes.string.isRequired,
      isTabbed: PropTypes.bool,
      label: PropTypes.string,
      onClick: PropTypes.func,
      pageType: PropTypes.string,
    })
  ).isRequired,
};

export default MainNavList;

import { FormattedText, Tag } from "atoms";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./exhibit-section.module.scss";

const ExhibitSection = ({
  bannerColor,
  bannerIcon,
  bannerLabel,
  exhibit,
  text,
}) => {
  const coverImageCtn = exhibit?.coverImage ? (
    <GatsbyImage
      alt={exhibit?.coverImage[0]?.altText}
      image={getImage(exhibit?.coverImage[0]?.imageFile)}
    />
  ) : (
    <p>Missing Cover Image</p>
  );

  return (
    <div
      className={styles.container}
      data-testid="exhibit-section"
      style={{
        backgroundColor: exhibit?.exhibitCardBackground,
      }}
    >
      <div className={classNames(styles.content, "container-fluid")}>
        <div className={styles.image}>{coverImageCtn}</div>
        <div
          className={classNames(styles.textContainer, {
            [styles.darkFont]: exhibit?.exhibitCardTextColor === "dark",
            [styles.lightFont]: exhibit?.exhibitCardTextColor === "light",
          })}
        >
          <Tag color={bannerColor} icon={bannerIcon} text={bannerLabel} />
          <div>
            <Link to={`/${exhibit?.uri}`}>
              <FormattedText
                className={exhibit?.fontType}
                outerElement={<h2 />}
                text={exhibit?.formattedTitleOverride || exhibit?.title}
              />
            </Link>
          </div>
          <FormattedText text={text} />
        </div>
      </div>
    </div>
  );
};

ExhibitSection.propTypes = {
  bannerColor: PropTypes.string,
  bannerIcon: PropTypes.string,
  bannerLabel: PropTypes.string,
  exhibit: PropTypes.object,
  text: PropTypes.string,
};

const ExhibitSectionFragment = graphql`
  fragment ExhibitSectionFragment on CraftAPI_componentList_exhibitSection_BlockType {
    id
    exhibit {
      id
      title
      uri
      ... on CraftAPI_exhibit_exhibit_Entry {
        coverImage {
          ... on CraftAPI_image_Asset {
            altText
            url
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        exhibitCardBackground
        exhibitCardTextColor
        fontType
        formattedTitleOverride
      }
    }
    text
  }
`;

const convert = (data) => {
  return (
    <ExhibitSection
      bannerColor={data.bannerColor}
      bannerIcon={data.bannerIcon}
      bannerLabel={data.bannerLabel}
      exhibit={data.exhibit[0]}
      key={data.id}
      text={data.text}
    />
  );
};

export { convert, ExhibitSection as default, ExhibitSectionFragment };

// extracted by mini-css-extract-plugin
export var audioDescription = "simple-image-promo-module--audioDescription--5Xo5f";
export var backgroundCover = "simple-image-promo-module--backgroundCover--SZOj+";
export var backgroundImage = "simple-image-promo-module--backgroundImage--mS6Au";
export var content = "simple-image-promo-module--content--sQ3Zv";
export var darkFont = "simple-image-promo-module--darkFont--atGmI";
export var fullGradientBorder = "simple-image-promo-module--fullGradientBorder--j9gs0";
export var imageContainer = "simple-image-promo-module--imageContainer--yF4AW";
export var leftImage = "simple-image-promo-module--leftImage--lY7tp";
export var lightFont = "simple-image-promo-module--lightFont--AXyBp";
export var listenContainer = "simple-image-promo-module--listenContainer--6FNxR";
export var placeFirstRowLastForVisualPresentation = "simple-image-promo-module--placeFirstRowLastForVisualPresentation--zq8Wg";
export var simpleImagePromo = "simple-image-promo-module--simpleImagePromo--K0mJ6";
export var smallTitle = "simple-image-promo-module--smallTitle--K7WIM";
export var text = "simple-image-promo-module--text--q+W+x";
export var textContainer = "simple-image-promo-module--textContainer--6KpZR";
export var title = "simple-image-promo-module--title--Sy04K";
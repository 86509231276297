import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./chrono-track.module.scss";

const ChronoTrack = ({ orientation }) => {
  const className = classNames({
    [styles.horizontalTrack]: orientation === "horizontal",
    [styles.verticalTrack]: orientation === "vertical",
  });
  return <div className={className} />;
};
ChronoTrack.propTypes = {
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
};
ChronoTrack.defaultProps = {
  orientation: "horizontal",
};
export default ChronoTrack;

const getFontSizeStyling = (size) => {
  switch (size) {
    case "extraSmall":
      return "title-font-size-extra-small";
    case "small":
      return "title-font-size-small";
    case "medium":
      return "title-font-size-medium";
    case "large":
      return "title-font-size-large";
    case "extraLarge":
      return "title-font-size-extra-large";
    default:
      return "title-font-size-medium";
  }
};

export { getFontSizeStyling };

import PropTypes from "prop-types";
import React from "react";

import * as styles from "./map-legend-item.module.scss";

const MapLegendItem = ({ color, itemText }) => (
  <div className={styles.contentContainer}>
    <div
      className={styles.legendItemColor}
      style={{ backgroundColor: color }}
    />
    <span className={styles.legendItemText}>{itemText}</span>
  </div>
);

MapLegendItem.propTypes = {
  color: PropTypes.string.isRequired,
  itemText: PropTypes.string.isRequired,
};

export default MapLegendItem;

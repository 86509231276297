import { useEffect } from "react";

const useKeyDown = (key, callback) => {
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === key) callback();
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  });
};

export default useKeyDown;

import { NMAAHCPropTypes, Theme } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import AudioContext from "./audio-context";
import * as styles from "./listen.module.scss";

const Listen = ({ audioAsset, audioClosedCaption, className, theme }) => {
  const buttonClassName = classNames(className, styles.listen);

  const barClasses = (short) =>
    classNames(styles.bar, {
      [styles.shortBar]: short,
    });

  const textClassname = classNames(styles.text, Theme.addClass(theme, false));

  return (
    <AudioContext.Consumer>
      {(callback) => (
        <button
          className={buttonClassName}
          data-testid="listen"
          onClick={() =>
            callback({
              audioAsset,
              audioClosedCaption,
            })
          }
        >
          <span className={barClasses(true)} />
          <span className={barClasses()} />
          <span className={barClasses(true)} />
          <span className={textClassname}>Listen to audio</span>
        </button>
      )}
    </AudioContext.Consumer>
  );
};

Listen.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  audioClosedCaption: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  ...Theme.PropType,
};

export { AudioContext, Listen as default };

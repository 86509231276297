// extracted by mini-css-extract-plugin
export var back = "chrono-navigation-module--back--nACI6";
export var bumpUp = "chrono-navigation-module--bumpUp--VqubV";
export var buttonContainer = "chrono-navigation-module--buttonContainer--KCbAx";
export var chronoIntro = "chrono-navigation-module--chronoIntro--3qDRS";
export var chronoNavigationLabel = "chrono-navigation-module--chronoNavigationLabel--qSaUB";
export var container = "chrono-navigation-module--container--ff3ho";
export var current = "chrono-navigation-module--current--Ki6Qx";
export var expandButton = "chrono-navigation-module--expandButton--xKY3X";
export var expanded = "chrono-navigation-module--expanded--4dr2h";
export var expander = "chrono-navigation-module--expander--MU+4o";
export var fullWidth = "chrono-navigation-module--fullWidth--aKCnj";
export var grey = "chrono-navigation-module--grey--i-Gdo";
export var icon = "chrono-navigation-module--icon--3OzKW";
export var leftMargin = "chrono-navigation-module--leftMargin--NbmJn";
export var rewind = "chrono-navigation-module--rewind--wTDHo";
export var rightMargin = "chrono-navigation-module--rightMargin--nKtwK";
export var title = "chrono-navigation-module--title--m3BIc";
export var titleButton = "chrono-navigation-module--titleButton--ovK0L";
export var year = "chrono-navigation-module--year--Lyqmm";
import PropTypes from "prop-types";

/** Display constant for always hidden */
const Hidden = "hidden";
/** Display constant for hide on mobile **/
const HiddenMobile = "hiddenMobile";
/** Display constant for always show */
const Show = "show";
/** Display constant for showing only on desktop */
const ShowDesktop = "showDesktop";
/** Display constant for showing only on mobile */
const ShowMobile = "showMobile";

/** PropType to be used by properties with varying display settings */
const PropType = PropTypes.oneOf([
  Hidden,
  Show,
  ShowDesktop,
  ShowMobile,
  HiddenMobile,
]);

/**
 * Apply the CSS class(es) based on the provided display option
 *
 * @param display   the display rule to apply
 * @returns {{}}  a CSS class object to be used with classnames
 */
const addClass = (display) => ({
  hidden: display === Hidden,
  "hidden-mobile": display === ShowDesktop || display === HiddenMobile,
  "hidden-tablet": display === ShowDesktop || display === ShowMobile,
  "hidden-desktop": display === ShowMobile,
});

export {
  addClass,
  Hidden,
  HiddenMobile,
  PropType,
  Show,
  ShowDesktop,
  ShowMobile,
};

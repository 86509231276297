// extracted by mini-css-extract-plugin
export var label = "stats-module-module--label--XCzQD";
export var labelCol = "stats-module-module--labelCol--Qo7dW";
export var narrowContainer = "stats-module-module--narrowContainer--V07Ih";
export var smallStat = "stats-module-module--smallStat--QAG0w";
export var stat = "stats-module-module--stat--5P5-g";
export var statCol = "stats-module-module--statCol--No2fh";
export var statsSection = "stats-module-module--statsSection--9bJaC";
export var subStat = "stats-module-module--subStat--dACcM";
export var subStatLabel = "stats-module-module--subStatLabel--qpM72";
export var tableFill = "stats-module-module--tableFill--6cIV5";
export var tableReverse = "stats-module-module--tableReverse--2rXZO";
export var tableRowContainer = "stats-module-module--tableRowContainer--wNhIr";
export var wideContainer = "stats-module-module--wideContainer--J0Kdu";
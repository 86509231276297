// extracted by mini-css-extract-plugin
export var backgroundCover = "full-width-single-image-module--backgroundCover--7y7M+";
export var backgroundImage = "full-width-single-image-module--backgroundImage--l4MWN";
export var darkFont = "full-width-single-image-module--darkFont--WvXV5";
export var imageComponentSpacer = "full-width-single-image-module--imageComponentSpacer--Ai+mV";
export var lightFont = "full-width-single-image-module--lightFont--flGed";
export var smallSubtitle = "full-width-single-image-module--smallSubtitle--xKues";
export var smallTitle = "full-width-single-image-module--smallTitle--zNkZ4";
export var text = "full-width-single-image-module--text--6e2d8";
export var titlePadding = "full-width-single-image-module--titlePadding--1uKvE";
export var verticalMiddle = "full-width-single-image-module--verticalMiddle--e7qnO";
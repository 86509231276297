import { GoToComponent, NMAAHCPropTypes, Theme, useKeyDown } from "assets";
import { AudioContext, Seo } from "atoms";
import classNames from "classnames";
import { AudioPlayer, Notification } from "molecules";
import { Layout } from "organisms";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useState } from "react";

const EXHIBIT_TYPE_THEMED = "themed";

const PageLayout = ({
  children,
  coverImage,
  pageType,
  presentationType,
  theme,
  title,
  shortDescription,
}) => {
  // Audio player state
  const [audioPlayerVisible, setAudioPlayerVisible] = useState(false);
  const [audioSource, setAudioSource] = useState();
  const [audioTitle, setAudioTitle] = useState();
  const [audioClosedCaption, setAudioClosedCaption] = useState();
  const [audioTranscription, setAudioTranscription] = useState();
  const [audioCredit, setAudioCredit] = useState();
  const [audioCreditLink, setAudioCreditLink] = useState();
  const [audioCopyright, setAudioCopyright] = useState();
  useKeyDown("Escape", () => setAudioPlayerVisible(false));
  const onListen = ({ audioAsset, audioClosedCaption }) => {
    if (audioAsset.url === undefined)
      console.warn("Attempted to play audio, but no source was provided");
    else if (audioAsset.title === undefined)
      console.warn("Attempted to play audio, but no name was provided");
    else {
      setAudioSource(audioAsset.url);
      setAudioTitle(audioAsset.title);
      setAudioClosedCaption(audioClosedCaption);
      setAudioTranscription(audioAsset.transcription);
      setAudioCredit(audioAsset.creditLine);
      setAudioCreditLink(audioAsset.linkField);
      setAudioCopyright(audioAsset.copyright);
      setAudioPlayerVisible(true);
    }
  };

  // Scroll to a component after layout if specified
  useLayoutEffect(GoToComponent.scrollToComponent, []);

  // conditional website font class
  const layoutClass = classNames({
    freightSanSerif: presentationType === EXHIBIT_TYPE_THEMED,
  });

  return (
    <AudioContext.Provider value={onListen}>
      <main
        className={classNames(Theme.addClass(theme ? theme : "light", true))}
        data-testid="page-layout"
        key="page-layout"
      >
        <Notification />
        <Seo
          coverImage={coverImage}
          shortDescription={shortDescription}
          title={title}
        />
        {audioSource && (
          <AudioPlayer
            caption={audioClosedCaption}
            copyright={audioCopyright}
            credit={audioCredit}
            creditLink={audioCreditLink}
            onClose={() => setAudioPlayerVisible(false)}
            source={audioSource}
            title={audioTitle}
            transcription={audioTranscription}
            visible={audioPlayerVisible}
          />
        )}
        <Layout className={layoutClass} pageType={pageType}>
          {children}
        </Layout>
      </main>
    </AudioContext.Provider>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  coverImage: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  pageType: PropTypes.string,
  presentationType: PropTypes.string,
  shortDescription: PropTypes.string,
  ...Theme.PropType,
  title: PropTypes.string.isRequired,
};

PageLayout.defaultProps = {
  theme: Theme.Light,
};

export default PageLayout;

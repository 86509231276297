import { NMAAHCPropTypes } from "assets";
import { ImageWithCaption } from "atoms";
import React from "react";

import * as styles from "./constellation-image.module.scss";

const ConstellationImage = ({ image }) => (
  <div
    className={`row center-xs ${styles.imgWrapper}`}
    data-testid="constellation-image"
  >
    <div className="col-xs-10 col-md-8 col-lg-7">
      <ImageWithCaption image={image} objectFit="contain" isConstellation />
    </div>
  </div>
);

ConstellationImage.propTypes = {
  image: NMAAHCPropTypes.Image,
};

const convert = (imageData) => {
  return (
    <ConstellationImage
      key="constellation-component"
      {...imageData}
      image={imageData.coverImage?.[0]}
    />
  );
};

export { convert, ConstellationImage as default };

// extracted by mini-css-extract-plugin
export var activeCopyrightBtn = "audio-player-module--activeCopyrightBtn--vxR0-";
export var button = "audio-player-module--button--odISO";
export var captionText = "audio-player-module--caption-text--fj8Le";
export var captions = "audio-player-module--captions--Mj8zu";
export var ccButton = "audio-player-module--cc-button--ZsuN+";
export var closeBox = "audio-player-module--close-box--8rn9P";
export var closeButton = "audio-player-module--closeButton--vAy0s";
export var contentContainer = "audio-player-module--contentContainer--9XUAC";
export var controlsBox = "audio-player-module--controls-box--QziDS";
export var controlsBoxWithCopyright = "audio-player-module--controlsBoxWithCopyright--iZxwo";
export var copyCarrot = "audio-player-module--copyCarrot--I+5Eq";
export var copyright = "audio-player-module--copyright--jO7Bn";
export var copyrightButton = "audio-player-module--copyright-button--svmIj";
export var currentTime = "audio-player-module--current-time--mjPkN";
export var disableTransition = "audio-player-module--disable-transition--HR8-3";
export var endTime = "audio-player-module--end-time--B+0v9";
export var leftControls = "audio-player-module--left-controls--JFpKH";
export var muteButton = "audio-player-module--mute-button--Q5T5n";
export var nameBox = "audio-player-module--name-box--H6VBR";
export var nowListeningTo = "audio-player-module--now-listening-to--BKkgO";
export var playButtonBox = "audio-player-module--play-button-box--oDa91";
export var player = "audio-player-module--player--2USCR";
export var progressBar = "audio-player-module--progress-bar--WZxB3";
export var restartButton = "audio-player-module--restart-button--Ie-NK";
export var rightControls = "audio-player-module--right-controls--kT7Lz";
export var tabbed = "audio-player-module--tabbed--8P3am";
export var timeBox = "audio-player-module--time-box--VTrPf";
export var timeDisplay = "audio-player-module--time-display--DsKrI";
export var trackCredit = "audio-player-module--track-credit--2dpdP";
export var trackTitle = "audio-player-module--track-title--hW-qh";
export var transcriptButton = "audio-player-module--transcript-button--gTcdH";
export var transcription = "audio-player-module--transcription--sg3vz";
export var visible = "audio-player-module--visible--rrtgi";
export var wrapper = "audio-player-module--wrapper--sRSj5";
import { useWindowDimensions } from "assets";
import { ActionButton } from "atoms";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";

import * as styles from "./narration.module.scss";

const Narration = forwardRef(
  ({ audioMuted, onPlayPress, setAudioMuted, showPlayButton, track }, ref) => {
    const [transcriptionHidden, setTranscriptionHidden] = useState(false);
    const width = useWindowDimensions().width;
    const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);

    if (track?.audioURL) {
      useEffect(() => {
        if (ref?.current?.textTracks?.[0]) {
          if (transcriptionHidden) {
            ref.current.textTracks[0].mode = "hidden";
          } else {
            ref.current.textTracks[0].mode = "showing";
          }
        }
      }, [transcriptionHidden]);
    }

    return (
      <>
        {showPlayButton && (
          <div className={styles.playButtonContainer}>
            <ActionButton icon={"play"} onClick={onPlayPress} text={"Start"} />
          </div>
        )}
        <div
          className={classNames("row", {
            [styles.bottomBar]: !isMobile,
            [styles.mobileBar]: isMobile,
            ["center-xs"]: !isMobile,
            ["between-xs"]: isMobile,
          })}
        >
          <ActionButton
            aria-label={
              transcriptionHidden ? "Show Transcription" : "Hide Transcription"
            }
            color={"black"}
            icon={transcriptionHidden ? "hidden" : "visible"}
            onClick={() => setTranscriptionHidden(!transcriptionHidden)}
            text={isMobile ? null : "Transcript"}
            stroke
          />
          <ActionButton
            aria-label={audioMuted ? "Unmute Audio" : "Mute Audio"}
            color={audioMuted ? "black" : "orange"}
            icon={audioMuted ? "muted" : "unmuted"}
            onClick={() => setAudioMuted(!audioMuted)}
            stroke={audioMuted}
            text={isMobile ? null : audioMuted ? "Unmute" : "Mute"}
          />
        </div>
      </>
    );
  }
);

Narration.propTypes = {
  audioMuted: PropTypes.bool,
  onPlayPress: PropTypes.func,
  setAudioMuted: PropTypes.func,
  showPlayButton: PropTypes.bool,
  track: PropTypes.shape({
    audioURL: PropTypes.string,
    vttURL: PropTypes.string,
  }).isRequired,
};

Narration.defaultProps = {
  audioMuted: false,
  onPlayPress: () => {},
  showPlayButton: false,
};

Narration.displayName = "Narration";

export default Narration;

import { Display, Theme, ThemeContext } from "assets";
import { ActionButton, CloseButton, FormattedText } from "atoms";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import * as styles from "./notification.module.scss";

// Returns the key used to store whether the user has seen this message before
const getKey = (dateUpdated) => `notification-${dateUpdated}`;

// Session storage key indicating when the user first loaded the site
const getStartKey = (dateUpdated) => `siteLoad-${dateUpdated}`;

export const InternalNotification = ({
  dateUpdated,
  delay,
  header,
  longDescription,
}) => {
  const { theme } = useContext(ThemeContext);
  const [animate, setAnimate] = useState(false);
  const [visible, setVisible] = useState(false);

  // No message to display
  if (!longDescription) return <div data-testid="empty-notification" />;

  const onClose = () => {
    // Mark the notification as seen by the user
    localStorage.setItem(getKey(dateUpdated), "true");
    setVisible(false);
  };

  useEffect(() => {
    if (!localStorage.getItem(getKey(dateUpdated))) {
      // User hasn't seen the notification before
      let firstLoaded = sessionStorage.getItem(getStartKey(dateUpdated));

      if (!firstLoaded) {
        firstLoaded = new Date().toISOString();
        sessionStorage.setItem(getStartKey(dateUpdated), firstLoaded);
      }

      // Determine how much time has elapsed since the user first loaded the site
      const timeElapsed =
        new Date().getTime() - new Date(firstLoaded).getTime();
      const timeToShowNotification = delay * 1000 - timeElapsed;

      if (timeToShowNotification < 0) {
        // We've exceeded the amount of time until the user should see the notification - display immediately
        setVisible(true);
      } else {
        // Otherwise set a delay to show the animation
        setTimeout(() => {
          setAnimate(true);
          setVisible(true);
        }, timeToShowNotification);
      }
    }
  }, []);

  const className = classNames(styles.notification, {
    [styles.animate]: animate,
    [styles.visible]: visible,
  });

  return (
    <div className={className} data-testid="notification">
      <div className={styles.closeContainer}>
        <CloseButton
          labelDisplay={Display.Hidden}
          onClick={onClose}
          theme={Theme.Light}
        />
      </div>
      <h2>{header}</h2>
      <FormattedText text={longDescription} theme={theme} />
      <div className={styles.buttonContainer}>
        <ActionButton
          color={ActionButton.Colors.Black}
          onClick={onClose}
          text="Got it"
          slim
        />
      </div>
    </div>
  );
};

InternalNotification.propTypes = {
  dateUpdated: PropTypes.string,
  delay: PropTypes.number,
  header: PropTypes.string,
  longDescription: PropTypes.string,
};

export default function Notification(props) {
  let data;

  if (process.env.STORYBOOK) {
    data = [];
  } else {
    data = useStaticQuery(graphql`
      query {
        craftAPI {
          notification: globalSet(handle: "notification") {
            ... on CraftAPI_notification_GlobalSet {
              dateUpdated
              delay
              header
              longDescription
            }
          }
        }
      }
    `);
  }

  return <InternalNotification {...data?.craftAPI?.notification} {...props} />;
}

import { NMAAHCPropTypes, useScrollObserver } from "assets";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as styles from "./donor-shoutout.module.scss";

const DonorShoutout = ({ image, text, url }) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);

  const className = classNames(
    "container-fluid",
    styles.donorShoutout,
    scrolledClasses
  );

  return (
    <div className={className} data-testid="donor-shoutout" ref={ref}>
      <div className="row center-xs">
        <div className={`col-xs-10 col-md-6 large-font ${styles.text}`}>
          {text}
        </div>
      </div>
      {image && (
        <div className="row center-xs">
          <div className={styles.logoImage}>
            <a href={url} rel="noreferrer" target="_blank">
              <GatsbyImage
                alt={image.altText}
                data-testid="image"
                image={getImage(image.imageFile)}
              />
              <span className="visually-hidden">Donor Website</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

DonorShoutout.propTypes = {
  image: NMAAHCPropTypes.Image,
  text: PropTypes.string,
  url: PropTypes.string,
};

/**
 * The GraphQL fragment for retrieving DonorShoutout data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const DonorShoutoutFragment = graphql`
  fragment DonorShoutoutFragment on CraftAPI_componentList_donorShoutout_BlockType {
    id
    siteUrl
    text
    logoImage {
      ... on CraftAPI_image_Asset {
        altText
        imageFile {
          childImageSharp {
            gatsbyImageData(height: 46)
          }
        }
        url
      }
    }
  }
`;

const convert = (data) => (
  <DonorShoutout
    image={data.logoImage?.[0]}
    key={data.id}
    text={data.text}
    url={data.siteUrl}
  />
);

export { convert, DonorShoutout as default, DonorShoutoutFragment };

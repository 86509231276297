// extracted by mini-css-extract-plugin
export var backgroundCover = "timeline-promo-module--backgroundCover--xvZ5W";
export var backgroundImage = "timeline-promo-module--backgroundImage--o0Wg7";
export var btnContainer = "timeline-promo-module--btn-container--LRAgb";
export var colOne = "timeline-promo-module--colOne--b05An";
export var colTwo = "timeline-promo-module--colTwo--H3rrQ";
export var content = "timeline-promo-module--content--IJpGl";
export var darkFont = "timeline-promo-module--darkFont--hHbxW";
export var darkTitle = "timeline-promo-module--darkTitle--nqZan";
export var imgContainer = "timeline-promo-module--img-container--9YPXL";
export var lightFont = "timeline-promo-module--lightFont--1sbdb";
export var lightTitle = "timeline-promo-module--lightTitle--8Uo6G";
export var promoComponent = "timeline-promo-module--promo-component--ucR+n";
export var promoTitle = "timeline-promo-module--promo-title--qmOgG";
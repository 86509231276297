import { NMAAHCPropTypes, ThemeContext } from "assets";
import { FormattedText, Listen } from "atoms";
import classNames from "classnames";
import { VideoPromo } from "molecules";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import * as styles from "./pillar-hero.module.scss";

const PillarHero = ({
  audioAsset,
  backgroundImageUrl,
  fontColor,
  fontType,
  header,
  presentationType,
  longDescription,
  title,
  subtitle,
  videoPromo,
}) => {
  const { theme } = useContext(ThemeContext);

  const textClass = classNames([styles.textContainer], "col-xs-12", {
    "col-md-offset-1": videoPromo?.coverImage,
    "col-md-5": videoPromo?.coverImage,
    "col-md-10": !videoPromo?.coverImage,
  });

  const containerClass = classNames("container-fluid", [styles.pillarHero], {
    [styles.withBg]: backgroundImageUrl,
    [styles.lightFont]: fontColor === "light",
    [styles.darkFont]: fontColor === "dark",
  });

  const titleClassName = classNames(styles.title, fontType, {
    [styles.unformattedThemedFonts]:
      presentationType == NMAAHCPropTypes.EXHIBIT_TYPE_THEMED,
    [styles.themedFonts]:
      presentationType == NMAAHCPropTypes.EXHIBIT_TYPE_THEMED,
  });

  return (
    <div
      className={containerClass}
      data-testid="pillar-hero"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      {audioAsset?.title && audioAsset?.url && (
        <div className={`${styles.listen} col-md-offset-1`}>
          <Listen audioAsset={audioAsset} />
        </div>
      )}
      <div className="row">
        <div className={"col-xs-12 col-md-offset-1 col-md-10"}>
          <h1 className={styles.fullTitle}>
            {header && <span className={styles.header}>{header}</span>}
            {title && <span className={titleClassName}>{title}</span>}
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-1" />
        {videoPromo?.coverImage && (
          <div className={`${styles.videoPromo} col-md-4 col-xs-12`}>
            <VideoPromo
              caption={videoPromo.caption}
              coverImage={videoPromo.coverImage}
              headline={videoPromo.headline}
              screenReaderText={title}
              showMetadata={false}
              src={videoPromo.src}
              withComponentSpacing={false}
            />
          </div>
        )}
        <div className={textClass}>
          <FormattedText
            className={`${styles.text}`}
            text={longDescription}
            theme={fontColor === "light" ? "dark" : theme}
          />
        </div>
      </div>
    </div>
  );
};

PillarHero.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  fontType: NMAAHCPropTypes.FontType,
  header: PropTypes.string,
  longDescription: PropTypes.string.isRequired,
  presentationType: NMAAHCPropTypes.ExhibitType,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  videoPromo: PropTypes.object,
};

export default PillarHero;

import queryString from "query-string";

/**
 * Produces the relative URL for the search page given a provided search term.
 * Importantly, the trailing slash on /search/ is required to avoid unwanted
 * redirects performed by AWS.
 *
 * @param searchTerm  the user's search term
 * @returns {string}  the relative URL for the search page
 */
const stringifySearch = (searchTerm) =>
  queryString.stringifyUrl({ url: "/search/", query: { term: searchTerm } });

export default stringifySearch;

import { NMAAHCPropTypes, ThemeContext, useScrollObserver } from "assets";
import { FocusableIframe, FormattedText, Listen, Metadata } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { ConstrainedSizeImage, ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./simple-image-promo.module.scss";

const SimpleImagePromo = ({
  backgroundCover,
  backgroundImageUrl,
  title,
  imageArray,
  metadata,
  placeImageLeft,
  smallTitle,
  text,
  audioDescription,
  audioAsset,
  fontColor,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);

  const { fontType, theme, pillarTitleColor } = useContext(ThemeContext);

  const className = classNames(
    "container-fluid",
    styles.simpleImagePromo,
    "col-lg-10",
    scrolledClasses,
    {
      [styles.lightFont]: fontColor == "light",
      [styles.darkFont]: fontColor == "dark",
      [styles.leftImage]: !placeImageLeft,
    }
  );

  const backgroundClass = classNames({
    [styles.backgroundImage]: backgroundImageUrl,
    [styles.backgroundCover]: backgroundCover,
  });

  // Classes for standard text container
  const textClassName = classNames("col-xs col-lg-6", styles.textContainer, {
    "last-sm": placeImageLeft,
  });

  // Classes for standard image container
  const imagesClassName = classNames("col-lg-6", styles.imageContainer, {
    "last-sm": !placeImageLeft,
  });

  const image = (imageData, i) => {
    return (
      <ExpandableImage
        galleryMedia={
          imageArray.length > 1
            ? imageArray.map((img) => ({
              ...img,
              imageDescription: img.modalImageText,
            }))
            : undefined
        }
        galleryStartIndex={imageArray.length > 1 ? i : undefined}
        image={imageData.image?.[0]}
        imageCaption={imageData.imageCaption}
        imageLayout="constrained"
        imageObjectFit={imageData.imageCropStyle}
        imageObjectPosition={imageData.imagePositionOverride}
        modalText={imageData.modalImageText}
      />
    );
  };

  const checkConstrainImageSize = (is3dEmbed, imageContainerStyle) => {
    return (
      !is3dEmbed &&
      imageArray.length === 1 &&
      imageContainerStyle.includes("hidden-mobile")
    );
  };

  const getExpandableImage = (imageContainerStyle) => {
    return (
      <>
        {imageArray.map((imageData, i) => {
          const is3dEmbed = typeof imageData.image === "string";
          return (
            <div
              className={classNames(imageContainerStyle, "col-xs-12", {
                "col-lg-6": imageArray?.length === 2,
                "col-lg-4": imageArray?.length === 3,
              })}
              key={i}
            >
              {is3dEmbed && (
                <>
                  <FocusableIframe
                    caption={imageData.imageCaption}
                    height="450px"
                    src={imageData.image}
                    title={imageData.altText}
                  />
                  {metadata && (
                    <>
                      <Metadata {...metadata} type="component" />
                    </>
                  )}
                </>
              )}
              {!is3dEmbed &&
                !checkConstrainImageSize(is3dEmbed, imageContainerStyle) &&
                image(imageData, i)}
              {checkConstrainImageSize(is3dEmbed, imageContainerStyle) && (
                <ConstrainedSizeImage image={imageData.image?.[0]}>
                  {image(imageData, i)}
                </ConstrainedSizeImage>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const content = (
    <>
      <div className={textClassName}>
        <FormattedText
          className={classNames(styles.title, fontType)}
          outerElement={<h2 />}
          style={{ color: pillarTitleColor }}
          text={title}
          deepLink
        />
        <FormattedText className={styles.smallTitle} text={smallTitle} />

        {getExpandableImage("hidden-large-desktop")}

        <FormattedText
          className={styles.text}
          text={text}
          theme={fontColor === "light" ? "dark" : theme}
        />
        <FormattedText
          className={classNames(styles.text, styles.audioDescription)}
          text={audioDescription}
          theme={fontColor === "light" ? "dark" : theme}
        />
        {audioAsset?.title && audioAsset?.url && (
          <div className={styles.listenContainer}>
            <Listen audioAsset={audioAsset} />
          </div>
        )}
      </div>
      <div className={imagesClassName}>
        <div className="row">
          {getExpandableImage(
            "hidden-mobile hidden-tablet hidden-small-desktop"
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={backgroundClass}
      data-testid="simple-image-promo"
      ref={ref}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={classNames(styles.content)}>
        <div className={className}>
          <div className={classNames("row")}>{content}</div>
        </div>
      </div>
    </div>
  );
};

SimpleImagePromo.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  audioDescription: PropTypes.string,
  audioTranscription: PropTypes.string,
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  imageArray: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([
        PropTypes.arrayOf(NMAAHCPropTypes.Image),
        PropTypes.string, // voyager URL
      ]),
    })
  ),
  placeImageLeft: PropTypes.bool,
  smallTitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  ...Metadata.PropType,
};

SimpleImagePromo.defaultProps = {
  placeImageLeft: false,
  imageArray: [],
};

const SimpleImagePromoFragment = graphql`
  fragment SimpleImagePromoFragment on CraftAPI_componentList_simpleImagePromo_BlockType {
    id
    audioAsset {
      ...AudioAssetFragment
    }
    audioDescription
    alignment
    backgroundCover
    backgroundImage {
      url
    }
    simpleImagePromoImages {
      ... on CraftAPI_simpleImagePromoImages_BlockType {
        imageCaption
        imageCropStyle
        imagePositionOverride
        modalImageText
        image {
          ...ImageMetadataFragment
        }
      }
    }
    subtitle
    text
    fontColor
    title1
  }
`;

const SimpleVoyagerPromoFragment = graphql`
  fragment SimpleVoyagerPromoFragment on CraftAPI_componentList_simpleVoyagerPromo_BlockType {
    id
    voyagerUrl
    caption
    a11yTitle
    alignment
    text
    caption
    copyright
    creditLine
    title2
  }
`;

const convert = (simpleImagePromoData) => (
  <SimpleImagePromo
    audioAsset={simpleImagePromoData.audioAsset?.[0]}
    audioDescription={simpleImagePromoData.audioDescription}
    backgroundCover={simpleImagePromoData.backgroundCover}
    backgroundImageUrl={simpleImagePromoData.backgroundImage?.[0]?.url}
    fontColor={simpleImagePromoData.fontColor}
    imageArray={simpleImagePromoData.simpleImagePromoImages}
    placeImageLeft={simpleImagePromoData?.alignment === "rightImage"}
    key={simpleImagePromoData.id}
    // The value is mismatched due to a longstanding naming error
    // The Right Image label in Craft places image on the left side and Left Image places image on the right side
    smallTitle={simpleImagePromoData.subtitle}
    text={simpleImagePromoData.text}
    title={simpleImagePromoData.title1}
  />
);

const convertVoyager = (simpleVoyagerPromoData) => {
  const mappedEmbed = [
    {
      image: simpleVoyagerPromoData.voyagerUrl,
      altText: simpleVoyagerPromoData.a11yTitle,
      imageCaption: simpleVoyagerPromoData.caption,
    },
  ];

  const metadata = {
    caption: simpleVoyagerPromoData.caption,
    copyright: simpleVoyagerPromoData.copyright,
    creditLine: simpleVoyagerPromoData.creditLine,
  };

  return (
    <SimpleImagePromo
      imageArray={mappedEmbed}
      metadata={metadata}
      key={simpleVoyagerPromoData.id}
      // The value is mismatched due to a longstanding naming error
      // The Right Image label in Craft places image on the left side and Left Image Right Image places image on the right side
      placeImageLeft={simpleVoyagerPromoData?.alignment === "rightImage"}
      smallTitle={simpleVoyagerPromoData.subtitle}
      text={simpleVoyagerPromoData.text}
      title={simpleVoyagerPromoData.title2}
    />
  );
};

export {
  convert,
  convertVoyager,
  SimpleImagePromo as default,
  SimpleImagePromoFragment,
  SimpleVoyagerPromoFragment,
};

import {
  getCharacterCount,
  NMAAHCPropTypes,
  ThemeContext,
  useWindowDimensions,
  VideoSourceParser,
} from "assets";
import { FormattedText, Scroller, VideoPlayer } from "atoms";
import classNames from "classnames";
import { ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef } from "react";

import * as styles from "./timeline-event-card.module.scss";

const TimelineEventCard = ({
  date,
  description,
  getDynamicFontSize,
  hasTwoEvents,
  imageData,
  imageCropStyling,
  imagePositionOverride,
  overrideDynamicFontSize,
  theme,
  video,
  title,
}) => {
  const descriptionRef = useRef();
  const { fontType } = useContext(ThemeContext);
  const imageCard = Boolean(!video?.length && imageData?.[0]?.imageFile);
  const videoCard = Boolean(video?.length);
  const textOnlyCard = Boolean(!video?.length && !imageData?.[0]?.imageFile);
  const cardClass = classNames(
    styles.eventCard,
    { [styles.eventCardVideo]: videoCard },
    { [styles.eventCardImage]: imageCard }
  );
  const scrollerClassName = classNames(styles.scrollContainer, "hidden-mobile");
  const titleClass = classNames(styles.cardTitle);

  const descriptionCharCount = getCharacterCount(description);

  function dynamicFontResize(
    charCount,
    baseFontSize = 20,
    maxDesktopFontSize = 24,
    maxMobileFontSize = 24,
    baseSizeCharCount = 300
  ) {
    const width = useWindowDimensions().width;
    const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);
    const maxFontSize = isMobile ? maxMobileFontSize : maxDesktopFontSize;
    const modifier =
      (maxFontSize - baseFontSize) / (charCount / baseSizeCharCount);
    const modifiedFontSize =
      modifier - (maxFontSize - baseFontSize) + baseFontSize;

    if (modifiedFontSize > maxFontSize) {
      return maxFontSize;
    } else if (modifiedFontSize < baseFontSize) {
      return baseFontSize;
    } else {
      return Math.round(modifiedFontSize);
    }
  }

  let dynamicFontSize = textOnlyCard
    ? dynamicFontResize(descriptionCharCount)
    : null;

  useEffect(() => {
    if (!hasTwoEvents || getDynamicFontSize !== undefined) {
      getDynamicFontSize && getDynamicFontSize(dynamicFontSize);
    }
  }, []);

  return (
    <div className={cardClass} data-testid="timeline-event-card">
      {(title || date) && (
        <div className={titleClass}>
          <h3 className={fontType} data-testid="event-card-title">
            {title}
          </h3>
          <span data-testid="event-card-date">{date}</span>
        </div>
      )}
      {imageCard && (
        <div className={styles.innerContainer} data-testid="event-card-image">
          <ExpandableImage
            image={imageData?.[0]}
            imageLayout="constrained"
            imageObjectFit={imageCropStyling}
            imageObjectPosition={imagePositionOverride}
            modalCaptionOnly
          />
        </div>
      )}
      {videoCard && (
        <div className={styles.innerContainer} data-testid="event-card-video">
          <VideoPlayer
            src={VideoSourceParser.getValidSource(video[0])}
            title={title}
            lazyload
            ui
          />
        </div>
      )}
      <div className={styles.textAndScroll}>
        <div
          className={styles.description}
          data-testid="event-card-description"
          ref={descriptionRef}
          style={
            dynamicFontSize
              ? { fontSize: `${overrideDynamicFontSize || dynamicFontSize}px` }
              : {}
          }
        >
          <FormattedText text={description} theme={theme} />
        </div>
        <div className={scrollerClassName}>
          <Scroller
            direction="vertical"
            ref={descriptionRef}
            scrollBarColor={theme === "dark" ? "light" : "dark"}
          />
        </div>
      </div>
    </div>
  );
};

TimelineEventCard.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  getDynamicFontSize: PropTypes.func,
  hasTwoEvents: PropTypes.bool,
  imageCropStyling: NMAAHCPropTypes.ImageObjectFit,
  imageData: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  imagePositionOverride: PropTypes.string,
  overrideDynamicFontSize: PropTypes.number,
  theme: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.arrayOf(NMAAHCPropTypes.EmbeddedVideo),
};

TimelineEventCard.defaultProps = {};

export default TimelineEventCard;

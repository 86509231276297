// Strings for main nav labels
// NOTE: if we change the title of any of our main navs in Craft, we must also change the title here as well
const mainNavItem1 = "constellations";
const mainNavItem2 = "exhibitions";
const mainNavItem3 = "learn more";
const mainNavItem4 = "stories";

// Page type string constants that match up with main nav label text to keep main nav icon active when on child pages
const CONSTELLATION = mainNavItem1;

const EXHIBITION = mainNavItem2;

const RESOURCES = mainNavItem3;
const HOW_WE_KNOW = mainNavItem3;

const DETAIL = "";

const STORY = mainNavItem4;

export { CONSTELLATION, DETAIL, EXHIBITION, HOW_WE_KNOW, RESOURCES, STORY };

import classNames from "classnames";
import { getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import * as styles from "./elevator.module.scss";

const yearStringExp = /^[\d]+$/;

// When the label prop isn't going to be displayed it comes in as undefined and consequently doesn't show
const ElevatorItem = ({ alt, className, image, label, smallText }) => {
  // We encountered a rare bug in OS 15.1 Safari related to our usage of the
  // GatsbyImage component. Because of this we are falling back to extracting
  // the image locations and using a standard <img> element.
  const imageFiles = getImage(image.imageFile)?.images;

  const [textLabel, labelClass] = useMemo(() => {
    const isYearItem = yearStringExp.test(label);
    return [
      isYearItem ? label.slice(-2) : label,
      isYearItem ? styles.labelTextDecade : "",
    ];
  }, [label]);

  const labelClassname = classNames(
    styles.labelText,
    labelClass,
    styles.centerWrapper,
    {
      [styles.labelTextSmaller]: smallText,
    }
  );

  return (
    <li className={className}>
      <div className={styles.absoluteContainer}>
        <div aria-label={label} className={styles.centerWrapper}>
          <span aria-hidden="true" className={labelClassname}>
            {textLabel}
          </span>
        </div>
      </div>
      <div className={styles.absoluteContainer}>
        <div className={styles.imageWrapper}>
          <picture>
            <source
              srcSet={imageFiles?.sources?.[0]?.srcSet}
              type={imageFiles?.sources?.[0]?.type}
            />
            <img
              alt={alt}
              className={styles.elevatorImage}
              src={imageFiles?.fallback?.src}
            />
          </picture>
          <div className={styles.imageOverlay} />
        </div>
      </div>
    </li>
  );
};

ElevatorItem.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.object,
  label: PropTypes.string.isRequired,
  smallText: PropTypes.bool,
};

export default ElevatorItem;

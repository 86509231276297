import React from "react";

/**
 * The AudioContext value is a callback function to be used by consumers whenever they wish to play an audio file.
 * The callback function expects an object argument that contains:
 *
 *   - audioAsset: an object containing audio data, as shown in the Audio prop type or audio fragment
 *   - audioClosedCaption: the closed caption
 */
const AudioContext = React.createContext(() => {});
AudioContext.displayName = "Audio Context";

export default AudioContext;

// extracted by mini-css-extract-plugin
export var active = "subnav-item-module--active--uSoF5";
export var alignLeft = "subnav-item-module--alignLeft--i47s0";
export var alignWithImg = "subnav-item-module--alignWithImg--9kKiz";
export var byline = "subnav-item-module--byline--TlfAs";
export var colA = "subnav-item-module--colA--aynmF";
export var colB = "subnav-item-module--colB--VF5sg";
export var darker = "subnav-item-module--darker--gveEc";
export var divider = "subnav-item-module--divider--EvO1H";
export var firstRow = "subnav-item-module--firstRow--rZo2s";
export var fullWidth = "subnav-item-module--fullWidth--OG9SS";
export var icon = "subnav-item-module--icon--Qjdry";
export var imgWrapper = "subnav-item-module--imgWrapper--ilUWV";
export var isInactive = "subnav-item-module--isInactive--UsvuV";
export var mainNav = "subnav-item-module--mainNav--yBcVf";
export var pageInfo = "subnav-item-module--pageInfo--dhqjO";
export var pageTitle = "subnav-item-module--pageTitle--1GV7p";
export var realLink = "subnav-item-module--realLink--ICDxQ";
export var rightArrow = "subnav-item-module--right-arrow--1U73m";
export var searchResult = "subnav-item-module--searchResult--NY3IM";
export var secondRow = "subnav-item-module--secondRow--+bLLV";
export var subNavItem = "subnav-item-module--subNavItem--CdAEr";
export var subtitle = "subnav-item-module--subtitle--i8h2J";
export var tagDescription = "subnav-item-module--tagDescription--2epsg";
export var title = "subnav-item-module--title--h55ve";
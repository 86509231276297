import "./autocomplete.scss";

import { stringifySearch } from "assets";
import { SearcHighlight, SearchSuggestionsList } from "atoms";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import AutoSuggest from "react-autosuggest";
import { connectAutoComplete } from "react-instantsearch-dom";

import * as styles from "./autocomplete.module.scss";

const InternalAutocomplete = ({
  commonSearches,
  currentRefinement,
  inputRef,
  hits,
  onValueChange,
  onSearch,
  onSuggestionSelected,
  placeholder,
  refine,
  shouldShowHeading,
}) => {
  const [value, setValue] = useState(currentRefinement);
  const [suggestionHighlighted, setSuggestionHighlighted] = useState(undefined);

  const onChange = (_, { newValue }) => {
    const value = newValue.replace(/(<([^>]+)>)/gi, "");
    setValue(() => value);
    onValueChange(value);
  };

  const onKeyDown = (e) => {
    if (e.which === 13) {
      onSearch(value.trim());
      inputRef?.current?.input?.blur();
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    refine(value);
  };

  const getSuggestionValue = (hit) => {
    return hit.title;
  };

  const renderInputComponent = (inputProps) => {
    return (
      <div className={styles.inputContainer}>
        <i className={"icon-search"} />
        <input {...inputProps} />
      </div>
    );
  };

  const shouldRenderSuggestions = () => {
    // only render suggestions when input is focused and wrap in check to avoid build errors
    if (typeof document !== "undefined") {
      return document.activeElement === inputRef?.current?.input;
    }
  };

  const onSuggestionHighlighted = (suggestion) => {
    // only bother to set state to show the light typing experience when not on the search page
    if (!shouldShowHeading) {
      setSuggestionHighlighted(() =>
        suggestion.suggestion
          ? getSuggestionValue(suggestion.suggestion)
          : undefined
      );
    }
  };

  const renderSuggestion = (hit) => {
    return (
      <SearcHighlight
        attribute="title"
        hit={hit}
        href={stringifySearch(hit.title)}
      />
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <>
        <SearchSuggestionsList
          containerProps={containerProps}
          suggestionHighlighted={suggestionHighlighted}
          suggestionsTitle={
            query?.trim().length === 0
              ? "suggested searches"
              : "suggested results"
          }
        >
          {children}
        </SearchSuggestionsList>
      </>
    );
  };

  const inputProps = {
    placeholder,
    onChange: onChange,
    onKeyDown: onKeyDown,
    "aria-label": "Search",
    value,
  };

  return (
    <div className="autocomplete">
      {shouldShowHeading && <h1 className="autocomplete__label">Search</h1>}
      <div className="autocomplete__input-container">
        <AutoSuggest
          alwaysRenderSuggestions={!shouldShowHeading}
          focusInputOnSuggestionClick={false}
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          onSuggestionHighlighted={onSuggestionHighlighted}
          onSuggestionsClearRequested={() => {}}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          ref={inputRef}
          renderInputComponent={renderInputComponent}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          shouldRenderSuggestions={shouldRenderSuggestions}
          suggestions={
            currentRefinement
              ? hits
              : commonSearches.map((search) => ({
                title: search.searchTerm,
                _highlightResult: {
                  title: {
                    value: search.searchTerm,
                  },
                },
              }))
          }
        />
      </div>
    </div>
  );
};

function Autocomplete(props) {
  let data;

  if (process.env.STORYBOOK) {
    data = [
      { searchTerm: "Common Search #1" },
      { searchTerm: "Common Search #2" },
    ];
  } else {
    const query = useStaticQuery(graphql`
      query {
        craftAPI {
          globalSet(handle: "search") {
            ... on CraftAPI_search_GlobalSet {
              commonSearches {
                searchTerm
              }
            }
          }
        }
      }
    `);

    data = query?.craftAPI?.globalSet?.commonSearches || [];
  }

  return <InternalAutocomplete commonSearches={data || []} {...props} />;
}

InternalAutocomplete.propTypes = {
  commonSearches: PropTypes.array,
  currentRefinement: PropTypes.string.isRequired,
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputRef: PropTypes.object.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  onSuggestionSelected: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  refine: PropTypes.func.isRequired,
  shouldShowHeading: PropTypes.bool.isRequired,
};

InternalAutocomplete.defaultProps = {
  inputRef: {
    current: {},
  },
  onKeyDown: () => {},
  placeholder: "Search",
  shouldShowHeading: true,
};

export default connectAutoComplete(Autocomplete);

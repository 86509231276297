import { NMAAHCPropTypes, useWindowDimensions } from "assets";
import { getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

const ConstrainedSizeImage = ({ centered, image, children }) => {
  const { height } = useWindowDimensions();

  const constrainedImageHeight = height > 450 ? (0.55 * height) / 16 : 28.125; // in rem

  const getFixedWidth = (image) => {
    const imgWidth = getImage(image.imageFile)?.width;
    const imgHeight = getImage(image.imageFile)?.height;
    const aspectRatio = imgWidth / imgHeight;

    return constrainedImageHeight * aspectRatio;
  };

  const style = {
    width: `min(90%, ${getFixedWidth(image).toFixed(3)}rem)`,
  };

  if (centered) {
    style.marginLeft = "auto";
    style.marginRight = "auto";
  }

  return <div style={style}>{children}</div>;
};

ConstrainedSizeImage.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  image: NMAAHCPropTypes.Image.isRequired,
};

ConstrainedSizeImage.defaultProps = {
  centered: true,
};

export default ConstrainedSizeImage;

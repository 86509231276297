const setTagList = (type, tags, content) => {
  let presentTagList = null;
  const presentTags = [];
  const tagList = [];
  tags?.map((tag) => {
    if (type === "exhibit") {
      tagList.push(tag);
    } else {
      if (tag.groupHandle === type) tagList.push(tag);
    }
  });
  if (type === "topic") {
    content?.children.map((story) => {
      story.tags.map((tag) => {
        presentTags.indexOf(tag) === -1 ? presentTags.push(tag) : null;
      });
    });
  }
  if (type === "timePeriods") {
    content?.children.map((story) => {
      presentTags.indexOf(story.timePeriod) === -1
        ? presentTags.push(story.timePeriod)
        : null;
    });
  }
  if (type === "exhibit") {
    content?.map((item) => {
      presentTags.indexOf(item.props?.exhibit?.title) === -1
        ? presentTags.push(item.props?.exhibit?.title)
        : null;
    });
  }
  presentTagList = tagList.filter((item) => presentTags.includes(item.title));
  presentTagList.sort();
  return presentTagList;
};

export default setTagList;

import { useLocation } from "@reach/router";
import { RomanNumerals } from "assets";
import { Scroller, SectionNavBlock } from "atoms";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as styles from "./cluster-navigation.module.scss";

const ClusterNavigation = ({ activePageId, pages }) => {
  const blocksRef = useRef();
  const keepFocusOnClusterNav = useLocation()?.href?.includes("#section-start");

  return (
    <nav className={`${styles.navigation}`} data-testid="cluster-navigation">
      <div className={styles.blockContainer}>
        <div className={styles.blocks} ref={blocksRef}>
          {pages?.map((cluster, i) => (
            <SectionNavBlock
              active={cluster.id === activePageId}
              keepFocusOnClusterNav={keepFocusOnClusterNav}
              key={cluster.id}
              subtitle={`Section ${RomanNumerals[i]}`}
              title={cluster.title}
              to={`/${cluster.uri}`}
            />
          ))}
        </div>
      </div>
      <div className={styles.scrollContainer}>
        <Scroller ref={blocksRef} scrollBarColor="light" />
      </div>
    </nav>
  );
};

ClusterNavigation.displayName = "ClusterNavigation";

ClusterNavigation.propTypes = {
  activePageId: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ClusterNavigationFragment = graphql`
  fragment ClusterNavigationFragment on CraftAPI_exhibit_cluster_Entry {
    id
    title
    uri
  }
`;

/**
 * Converts the provided cluster navigation data into a cluster navigation
 *
 * @param clusterNavData  the GraphQL response data
 * @returns               the cluster navigation
 */
const convert = (clusterNavData) => {
  return (
    <ClusterNavigation
      {...clusterNavData}
      key={
        "cluster-navigation-" +
        clusterNavData?.pages?.map((p) => p.id)?.join("-")
      }
    />
  );
};

export { ClusterNavigationFragment, convert, ClusterNavigation as default };

import { graphql } from "gatsby";
import React from "react";

import { default as Gallery } from "../gallery/gallery.jsx";

/** Craft CMS type name for this component **/
const TYPE_NAME = "passiveGallery";

/**
 * The regex used to extract a component name from their GraphQL __typename
 * (e.g. CraftAPI_componentList_statsPromo_BlockType becomes statsPromo)
 */
const BLOCK_REGEX = /.*_(.*)_BlockType/m;

/**
 * The GraphQL fragment for retrieving Passive Gallery data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const PassiveGalleryFragment = graphql`
  fragment PassiveGalleryFragment on CraftAPI_componentList_passiveGallery_BlockType {
    id
    includeComponentImages
    maxImages
  }
`;

/**
 * The GraphQL fragment for pulling components that contain images
 */
const ComponentImageFragment = graphql`
  fragment ComponentImageFragment on CraftAPI_componentList_MatrixField {
    __typename
    ...FullWidthSingleImageFragment
    ...SimpleImagePromoFragment
    ...TwoColImagePromoFragment
  }
`;

/**
 * The GraphQL fragment for pulling hero and component image data on a exhibit
 */
const ExhibitImagesFragment = graphql`
  fragment ExhibitImagesFragment on CraftAPI_exhibit_exhibit_Entry {
    children {
      ...PillarImagesFragment
    }
  }
`;

/**
 * The GraphQL fragment for pulling hero and component image data on a pillar
 */
const PillarImagesFragment = graphql`
  fragment PillarImagesFragment on CraftAPI_exhibit_pillar_Entry {
    children {
      ...ChapterImagesFragment
    }
    componentList {
      ...ComponentImageFragment
    }
    coverImage {
      ...ImageMetadataFragment
    }
  }
`;

/**
 * The GraphQL fragment for pulling hero and component image data on a chapter
 */
const ChapterImagesFragment = graphql`
  fragment ChapterImagesFragment on CraftAPI_exhibit_chapter_Entry {
    children {
      ...ClusterImagesFragment
    }
    componentList {
      ...ComponentImageFragment
    }
    coverImage {
      ...ImageMetadataFragment
    }
  }
`;

/**
 * The GraphQL fragment for pulling hero and component image data on a cluster
 */
const ClusterImagesFragment = graphql`
  fragment ClusterImagesFragment on CraftAPI_exhibit_cluster_Entry {
    componentList {
      ...ComponentImageFragment
    }
    coverImage {
      ...ImageMetadataFragment
    }
  }
`;

/**
 * Recursively extracts hero images from the provided page and its child pages
 *
 * @param pageData  the page data
 * @param isRoot    whether the provided page is considered the root page
 * @returns {[]}    an array of hero images
 */
const extractHeroImages = (pageData, isRoot) => {
  // 1. Include the current page's hero image, unless its the root page
  return (
    (isRoot ? [] : pageData.coverImage ?? [])
      // 2. Add the child pages' hero images to the list
      .concat(pageData.children?.flatMap((c) => extractHeroImages(c, false)))
      // 3. Filter out any undefined images
      .filter(Boolean)
  );
};

/**
 * Small functions which extract images from their corresponding components
 */
const componentExtractors = {
  fullWidthSingleImage: (component) => component.image,
  simpleImagePromo: (component) =>
    component.simpleImagePromoImages.map((image) => image.image).flat(),
  twoColImagePromo: (component) =>
    [component.column1?.[0]?.image, component.column2?.[0]?.image].flat(),
};

/**
 * Recursively extracts images from components starting with the provided page
 * then its child pages
 *
 * @param pageData  the page data
 * @param isRoot    whether the provided page is considered the root page
 * @returns {[]}    an array of hero images
 */
const extractComponentImages = (pageData, isRoot) => {
  const images = [];

  if (!isRoot) {
    pageData?.componentList.map((component) => {
      const componentName = (component.__typename || "").match(
        BLOCK_REGEX
      )?.[1];
      const componentImages = componentExtractors[componentName]?.(component);

      if (componentImages) {
        images.push(...componentImages);
      }
    });
  }

  return images
    .concat(pageData.children?.flatMap((c) => extractComponentImages(c, false)))
    .filter(Boolean);
};

/**
 * Builds the gallery data for any passive gallery components detected within
 * the provided page. This will recursively pull hero images and component
 * images from child pages.
 *
 * @param pageData the current page
 */
const buildGalleryData = (pageData) => {
  pageData?.componentList
    // Find the passive gallery component if it exists on the page
    ?.filter((component) => component.__typename?.includes(TYPE_NAME))
    .forEach((gallery) => {
      // Update the passive gallery component data with hero images and component images
      // from its children
      gallery.heroImages = extractHeroImages(pageData, true);
      gallery.componentImages = extractComponentImages(pageData, true);
    });
};

const convert = (passiveGalleryData) => {
  const {
    componentImages = [],
    heroImages = [],
    id,
    includeComponentImages = true,
    maxImages = 30,
  } = passiveGalleryData;

  let counter = 0;

  // 1. Start with hero images
  let images = heroImages
    // 2. Potentially add component images
    .concat(includeComponentImages ? componentImages : [])
    // 3. Filter out images which should not be included
    .filter((img) => img.includeInPassiveGallery !== false);

  let everyXImages = Math.max(1, Math.floor(images.length / maxImages));

  // 4. grab every x image based on the number of images we have and the number we want
  images = images
    .filter((img, i) => i % everyXImages === 0 && counter++ < maxImages)
    // 5. Map it to the required format for the Gallery component
    .map((image) => ({ image: [image] }));

  return (
    <Gallery galleryId={id} key={id} media={images} passiveGallery twoRows />
  );
};

export {
  buildGalleryData,
  ChapterImagesFragment,
  ClusterImagesFragment,
  ComponentImageFragment,
  convert,
  ExhibitImagesFragment,
  extractComponentImages,
  extractHeroImages,
  PassiveGalleryFragment,
  PillarImagesFragment,
};

import { NMAAHCPropTypes } from "assets";
import { graphql } from "gatsby";
import { Quote } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const ConstellationQuote = ({
  audioAsset,
  author,
  image,
  imagePositionOverride,
  date,
  quote,
}) => {
  return (
    <div data-testid="constellation-quote">
      <Quote
        audioAsset={audioAsset}
        author={author}
        date={date}
        imageObjectPosition={imagePositionOverride || undefined}
        quote={quote}
        quoteImage={image}
        withComponentSpacing={false}
        fullWidth
      />
    </div>
  );
};

ConstellationQuote.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  author: PropTypes.string,
  date: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imagePositionOverride: PropTypes.string,
  quote: PropTypes.string.isRequired,
};

/**
 * The GraphQL fragment for retrieving Constellation Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ConstellationQuoteFragment = graphql`
  fragment ConstellationQuoteFragment on CraftAPI_constellationComponentList_quotePromo_BlockType {
    referenceQuote {
      ... on CraftAPI_object_quote_Entry {
        audioAsset {
          ...AudioAssetFragment
        }
        quoteAuthor
        quoteYear
        transcription
      }
    }
    imagePositionOverride
  }
`;

const convert = (quoteData) => {
  const { [0]: quote } = quoteData.referenceQuote;

  return (
    <ConstellationQuote
      audioAsset={quote.audioAsset?.[0]}
      author={quote.quoteAuthor}
      date={quote.quoteYear}
      image={quoteData.coverImage?.[0]}
      imagePositionOverride={quoteData.imagePositionOverride}
      key="constellation-component"
      quote={quote.transcription}
    />
  );
};

export { ConstellationQuoteFragment, convert, ConstellationQuote as default };

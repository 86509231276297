import { NMAAHCPropTypes, ThemeContext, useScrollObserver } from "assets";
import { FormattedText, Listen } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./text-promo.module.scss";

const TextPromo = ({
  audioAsset,
  backgroundCover,
  backgroundImageUrl,
  placeImage,
  summary,
  summaryTextAlign,
  fontColor,
  title,
  fullWidth,
  withComponentSpacing,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);
  const { fontType, theme } = useContext(ThemeContext);

  let backgroundPosition;
  if (placeImage === "leftImage") {
    backgroundPosition = "left";
  } else if (placeImage === "rightImage") {
    backgroundPosition = "right";
  }

  const textPromoClassName = classNames(styles.textPromo, scrolledClasses, {
    "container-fluid": withComponentSpacing,
    [styles.withComponentSpacing]: withComponentSpacing,
    [styles.lightFont]: fontColor === "light",
    [styles.darkFont]: fontColor === "dark",
    [styles.backgroundImage]: backgroundImageUrl,
    [styles.backgroundCover]: backgroundCover,
  });

  const summaryClass = classNames({
    [styles.leftAlign]: summaryTextAlign === "left",
  });

  return (
    <section
      className={textPromoClassName}
      data-testid="text-promo"
      ref={ref}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundPosition,
      }}
    >
      <div className={"row center-xs"}>
        <div
          className={classNames(styles.textContainer, "col-xs", {
            ["col-sm-10 col-md-8"]: !fullWidth,
          })}
        >
          <FormattedText
            className={fontType}
            outerElement={<h2 />}
            text={title}
            deepLink
          />
          {audioAsset?.title && audioAsset?.url && (
            <Listen audioAsset={audioAsset} />
          )}
          <FormattedText
            className={summaryClass}
            text={summary}
            theme={fontColor === "light" ? "dark" : theme}
          />
        </div>
      </div>
    </section>
  );
};

TextPromo.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeImage: PropTypes.string,
  summary: PropTypes.string.isRequired,
  summaryTextAlign: PropTypes.string,
  title: PropTypes.string,
  withComponentSpacing: PropTypes.bool,
};

TextPromo.defaultProps = {
  fullWidth: false,
  withComponentSpacing: true,
};

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const TextPromoFragment = graphql`
  fragment TextPromoFragment on CraftAPI_componentList_textPromo_BlockType {
    id
    promoTitle
    summary
    summaryTextAlignment
    audio {
      ...AudioAssetFragment
    }
    backgroundImage {
      url
    }
    backgroundCover
    backgroundAlignment
    fontColor
  }
`;

const convert = (textData) => {
  return (
    <TextPromo
      audioAsset={textData.audio?.[0]}
      backgroundCover={textData.backgroundCover}
      backgroundImageUrl={textData.backgroundImage?.[0]?.url}
      fontColor={textData.fontColor}
      key={textData.id}
      placeImage={textData?.backgroundAlignment}
      summary={textData.summary}
      summaryTextAlign={textData?.summaryTextAlignment}
      title={textData.promoTitle}
    />
  );
};

export { convert, TextPromo as default, TextPromoFragment };

import { NMAAHCPropTypes } from "assets";
import classNames from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import ConstellationButtonGraphic from "../constellation-button-graphic/constellation-button-graphic";
import * as styles from "./constellation-button.module.scss";

const typeMap = {
  entry: <i className={`${styles.whiteIcon} icon-stacked-box`} />,
  externalLink: <i className="icon-external-link" />,
  image: <i className="icon-eye" />,
  quotePromo: <i className="icon-quote" />,
  video: <i className="icon-play" />,
};

const ConstellationButton = ({
  buttonImagePosition,
  image,
  isTimeline,
  small,
  title,
  to,
  type,
  fromClick,
  tag,
  showIcon,
}) => {
  return (
    <Link
      className={classNames(styles.constellationButton, {
        [styles.small]: small,
        [styles.timelineItem]: isTimeline,
      })}
      data-testid="constellation-button"
      state={fromClick}
      to={to}
    >
      <ConstellationButtonGraphic
        className={styles.constellationButtonGraphic}
        isTimeline={isTimeline}
      />
      <figure>
        <div className={styles.imgWrapper}>
          <GatsbyImage
            alt=""
            image={getImage(image?.imageFile)}
            objectPosition={buttonImagePosition}
            style={{ maxWidth: "280px" }}
          />
        </div>
        <h2 className={styles.title} data-testid="constellation-button-title">
          {title}
        </h2>
        <p className={styles.tag} data-testid="constellation-object-tag">
          {tag}
        </p>
        {showIcon && type && (
          <span
            className={styles.type}
            data-testid={`constellation-button-type-${type}`}
          >
            {typeMap[type]}
          </span>
        )}
      </figure>
    </Link>
  );
};

ConstellationButton.propTypes = {
  buttonImagePosition: PropTypes.string,
  fromClick: PropTypes.bool,
  image: NMAAHCPropTypes.Image,
  isTimeline: PropTypes.bool,
  showIcon: PropTypes.bool,
  small: PropTypes.bool,
  tag: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "entry",
    "externalLink",
    "image",
    "quotePromo",
    "video",
  ]),
};

ConstellationButton.defaultProps = {
  fromClick: false,
  isTimeline: false,
};

export default ConstellationButton;

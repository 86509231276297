// extracted by mini-css-extract-plugin
export var bookWrapper = "dissection-module--bookWrapper--Z-AJh";
export var columnContainer = "dissection-module--columnContainer--WVLI+";
export var darkFont = "dissection-module--darkFont--PwEKQ";
export var dissectionContainer = "dissection-module--dissectionContainer--d3yDM";
export var endPage = "dissection-module--endPage--E2+NY";
export var exploreButtonContainer = "dissection-module--exploreButtonContainer--Tfijj";
export var imageClass = "dissection-module--imageClass--KYt4D";
export var imageContainer = "dissection-module--imageContainer--XGcph";
export var instructionText = "dissection-module--instructionText--wQ2oe";
export var introtext = "dissection-module--introtext--yvkjC";
export var mobileWidth = "767px";
export var multiplePages = "dissection-module--multiplePages--iEH2H";
export var paddingLeft = "dissection-module--paddingLeft--GgJUi";
export var paddingRight = "dissection-module--paddingRight--zcekR";
export var paddingWithoutIntroText = "dissection-module--paddingWithoutIntroText--7gbFT";
export var page = "dissection-module--page--waSAy";
export var page1 = "dissection-module--page1--+94Ew";
export var pageBack = "dissection-module--pageBack---9iox";
export var pageCountBarText = "dissection-module--pageCountBarText--1YI6l";
export var pageFront = "dissection-module--pageFront--4oI9C";
export var pageMiddle = "dissection-module--pageMiddle--wyf33";
export var pointsWrapper = "dissection-module--pointsWrapper--BLcaz";
export var title = "dissection-module--title--x7YlD";
export var turnPage = "dissection-module--turnPage--IBlyc";
import PropTypes from "prop-types";
import React from "react";

const circleCoords = [
  [5, 45],
  [95, 95],
  [5, 215],
  [95, 265],
  [5, 385],
  [95, 435],
];

// The height of the loop for each pair of circles
const heightPerPair = 153;

const ConstellationLoop = ({ className, numCircles }) => (
  <svg
    className={className}
    data-testid="constellation-loop"
    viewBox="0 0 100 500"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="loop-gradient" x1="0%" x2="0%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFAE18" stopOpacity="0.4" />
        <stop offset="50%" stopColor="#FFAE18" stopOpacity="0.6" />
        <stop offset="100%" stopColor="#FFAE18" stopOpacity="0.4" />
      </linearGradient>
    </defs>
    <rect
      fill="none"
      height={(heightPerPair * numCircles) / 2}
      rx="20"
      ry="20"
      stroke="url(#loop-gradient)"
      strokeDasharray="1 12"
      strokeWidth="4"
      width="60"
      x="20"
      y="20"
    />
    {[...Array(Math.min(numCircles, 6)).keys()].map((i) => (
      <g
        key={i}
        transform={`translate(${circleCoords[i][0]}, ${
          circleCoords[i][1]
        }) scale(${i % 2 === 0 ? 0.75 : -0.75}, ${0.75})`}
      >
        <circle cx="19" cy="19" fill="#090307" r="24" />
        <g>
          <animate
            attributeName="opacity"
            begin={`${i * 0.35}s`}
            dur="2s"
            repeatCount="indefinite"
            values="1.0;0.4;1.0"
          />
          <path
            d="M10 3.26475L11.749 8.70026L11.8889 9.1352L12.3093 9.31411L17.4461 11.5L12.3093 13.6859L11.8889 13.8648L11.749 14.2997L10 19.7352L8.25103 14.2997L8.11108 13.8648L7.69065 13.6859L2.55389 11.5L7.69065 9.31411L8.11108 9.1352L8.25103 8.70026L10 3.26475Z"
            fill="#FFAE18"
            stroke="#FFAE18"
            strokeWidth="2"
          />
        </g>
        <g>
          <animate
            attributeName="opacity"
            begin={`${i * 0.35 + 0.75}s`}
            dur="2s"
            repeatCount="indefinite"
            values="1.0;0.4;1.0"
          />
          <path
            d="M22.5 26.4732L23.2979 29.1272L23.4285 29.5614L23.8411 29.7493L26.5873 31L23.8411 32.2507L23.4285 32.4386L23.2979 32.8728L22.5 35.5268L21.7021 32.8728L21.5715 32.4386L21.1589 32.2507L18.4127 31L21.1589 29.7493L21.5715 29.5614L21.7021 29.1272L22.5 26.4732Z"
            fill="#FFAE18"
            stroke="#FFAE18"
            strokeWidth="2"
          />
        </g>
        <g>
          <animate
            attributeName="opacity"
            begin={`${i * 0.35 + 1.5}s`}
            dur="2s"
            repeatCount="indefinite"
            values="1.0;0.4;1.0"
          />
          <path
            d="M30.5 12.1649L30.7666 12.9655L30.9114 13.4002L31.3351 13.5744L32.3702 14L31.3351 14.4256L30.9114 14.5998L30.7666 15.0345L30.5 15.8351L30.2334 15.0345L30.0886 14.5998L29.6649 14.4256L28.6298 14L29.6649 13.5744L30.0886 13.4002L30.2334 12.9655L30.5 12.1649Z"
            fill="#FFAE18"
            stroke="#FFAE18"
            strokeWidth="2"
          />
        </g>
      </g>
    ))}
  </svg>
);

ConstellationLoop.propTypes = {
  className: PropTypes.string,
  numCircles: PropTypes.number,
};

ConstellationLoop.defaultProps = {
  numCircles: 6,
};

export default ConstellationLoop;

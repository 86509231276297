import { Theme } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./expand-button.module.scss";

const ExpandButton = ({
  onExpand,
  iconName,
  screenReaderText,
  theme,
  hidden,
  buttonPositionClassName,
}) => {
  const buttonClassName = classNames({
    [styles.expandButton]: true,
    [styles.expandButtonLight]: theme === Theme.Light,
    [buttonPositionClassName]: true,
    ...Theme.addClass(theme, true),
  });

  return (
    <i className={buttonClassName} data-testid="expand-btn" hidden={hidden}>
      <i className={`icon-${iconName}`}></i>
    </i>
  );
};

ExpandButton.propTypes = {
  buttonPositionClassName: PropTypes.string,
  hidden: PropTypes.bool,
  iconName: PropTypes.string,
  onExpand: PropTypes.func,
  screenReaderText: PropTypes.string,
  ...Theme.PropType,
};

ExpandButton.defaultProps = {
  onExpand: () => {},
  theme: Theme.Dark,
  iconName: "expand",
  buttonPositionClassName: styles.expandButtonPosition,
};

export default ExpandButton;

import PropTypes from "prop-types";

const DissectionDocument = "document";
const DissectionObject = "object";
const DissectionSubject = PropTypes.oneOf([
  DissectionDocument,
  DissectionObject,
]);

const DissectionAlignImageToLeft = "leftImage";
const DissectionAlignImageToRight = "rightImage";
const DissectionImageAlignmentOptions = PropTypes.oneOf([
  DissectionAlignImageToLeft,
  DissectionAlignImageToRight,
]);

const TurningThePagePage = PropTypes.shape({
  hotspots: PropTypes.shape({
    canvas: PropTypes.object,
    pins: PropTypes.array,
  }).isRequired,
  transcript: PropTypes.string,
}).isRequired;

const TurningThePagePin = PropTypes.shape({
  index: PropTypes.number,
  pin: PropTypes.any,
});

const WhatsNewOpenByDefault = "whatsNewOpenByDefault";
const WhatsNewClosedByDefault = "whatsNewClosedByDefault";
const WhatsNewNormalCards = "normalCards";

const Audio = PropTypes.shape({
  copyright: PropTypes.string,
  creditLine: PropTypes.string,
  linkField: PropTypes.string,
  title: PropTypes.string,
  transcription: PropTypes.string,
  url: PropTypes.string.isRequired,
});

const EmbeddedVideo = PropTypes.shape({
  embeddedAsset: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
});

const Image = PropTypes.shape({
  altText: PropTypes.string.isRequired,
  copyright: PropTypes.string,
  creditLine: PropTypes.string,
  displayTitle: PropTypes.string,
  height: PropTypes.string,
  // imageFile is generally required and present, however sometimes multiple image sizes are requested
  // with multiple aliases and in those instances this field will not be present
  imageFile: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
  }),
  includeInPassiveGallery: PropTypes.bool,
  linkField: PropTypes.string,
  nmaahcObject: PropTypes.bool,
  objectName: PropTypes.string,
  objectNumber: PropTypes.string,
  objectTitle: PropTypes.string,
  subjectMedium: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
});

const Quote = PropTypes.shape({
  audioAsset: PropTypes.arrayOf(Audio),
  quoteAuthor: PropTypes.string,
  quoteYear: PropTypes.string,
  transcription: PropTypes.string.isRequired,
});

const SizeLarge = "large";
const SizeSmall = "small";

const Size = PropTypes.oneOf([SizeSmall, SizeLarge]);

const EXHIBIT_TYPE_THEMED = "themed";
const EXHIBIT_TYPE_CHRONOLOGICAL = "chronological";

const ExhibitType = PropTypes.oneOf([
  EXHIBIT_TYPE_THEMED,
  EXHIBIT_TYPE_CHRONOLOGICAL,
]);

const ImageObjectFit = PropTypes.oneOf([
  "contain",
  "cover",
  "scale-down",
  "none",
]);

const ARROW_OVERLAY_ORANGE_BUTTON = "ARROW_OVERLAY_ORANGE_BUTTON";
const ARROW_OVERLAY_BLACK_BUTTON = "ARROW_OVERLAY_BLACK_BUTTON";
const ArrowOverlayType = PropTypes.oneOf([
  ARROW_OVERLAY_ORANGE_BUTTON,
  ARROW_OVERLAY_BLACK_BUTTON,
]);

const EXTRA_SMALL_TITLE_SIZE = "extraSmall";
const SMALL_TITLE_SIZE = "small";
const MEDIUM_TITLE_SIZE = "medium";
const LARGE_TITLE_SIZE = "large";
const EXTRA_LARGE_TITLE_SIZE = "extraLarge";

const TitleSize = PropTypes.oneOf([
  EXTRA_SMALL_TITLE_SIZE,
  SMALL_TITLE_SIZE,
  MEDIUM_TITLE_SIZE,
  LARGE_TITLE_SIZE,
  EXTRA_LARGE_TITLE_SIZE,
]);

//the string representation here matches what we get in craft which is why it breaks the all caps convention
const FONT_DIN = "din";
const FONT_FREIGHT_SERIF = "freightSerif";
const FONT_FREIGHT_SAN_SERIF = "freightSanSerif";
const FONT_HELVETICA_NEUE = "helveticaNeue";
const FONT_NUBA = "nuba";
const FONT_VTC_CARRIE = "vtcCarrie";

const FontType = PropTypes.oneOf([
  FONT_DIN,
  FONT_FREIGHT_SERIF,
  FONT_FREIGHT_SAN_SERIF,
  FONT_HELVETICA_NEUE,
  FONT_NUBA,
  FONT_VTC_CARRIE,
]);

const EXHIBIT_HANDLE = "exhibit";
const PILLAR_HANDLE = "pillar";
const CHAPTER_HANDLE = "chapter";
const CLUSTER_HANDLE = "cluster";
const DETAIL_HANDLE = "detail";

const TypeHandle = PropTypes.oneOf([
  DETAIL_HANDLE,
  EXHIBIT_HANDLE,
  PILLAR_HANDLE,
  CHAPTER_HANDLE,
  CLUSTER_HANDLE,
]);

export {
  ARROW_OVERLAY_BLACK_BUTTON,
  ARROW_OVERLAY_ORANGE_BUTTON,
  ArrowOverlayType,
  Audio,
  CHAPTER_HANDLE,
  CLUSTER_HANDLE,
  DETAIL_HANDLE,
  DissectionAlignImageToLeft,
  DissectionAlignImageToRight,
  DissectionDocument,
  DissectionImageAlignmentOptions,
  DissectionObject,
  DissectionSubject,
  EmbeddedVideo,
  EXHIBIT_HANDLE,
  EXHIBIT_TYPE_CHRONOLOGICAL,
  EXHIBIT_TYPE_THEMED,
  ExhibitType,
  EXTRA_LARGE_TITLE_SIZE,
  EXTRA_SMALL_TITLE_SIZE,
  FONT_DIN,
  FONT_FREIGHT_SAN_SERIF,
  FONT_FREIGHT_SERIF,
  FONT_NUBA,
  FontType,
  Image,
  ImageObjectFit,
  LARGE_TITLE_SIZE,
  MEDIUM_TITLE_SIZE,
  PILLAR_HANDLE,
  Quote,
  Size,
  SizeLarge,
  SizeSmall,
  SMALL_TITLE_SIZE,
  TitleSize,
  TurningThePagePage,
  TurningThePagePin,
  TypeHandle,
  WhatsNewClosedByDefault,
  WhatsNewNormalCards,
  WhatsNewOpenByDefault,
};

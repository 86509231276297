const NEXT = "next";
const PREV = "prev";
const GOTO = "goto";

export const next = () => ({ type: NEXT });
export const prev = () => ({ type: PREV });
export const goto = (index) => ({ type: GOTO, payload: index });

const DEFAULT_STATE = { index: -1, length: 0 };

const reducer = (state = DEFAULT_STATE, { type, payload } = {}) => {
  const { index, length } = state;
  switch (type) {
    case NEXT:
      return { ...state, index: Math.min(index + 1, length) };
    case PREV:
      return { ...state, index: Math.max(index - 1, -1) };
    case GOTO:
      return { ...state, index: Math.min(Math.max(payload, -1), length) };
    default:
      return state;
  }
};

export default reducer;

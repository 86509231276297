import { Display, NMAAHCPropTypes, Theme, useScrollObserver } from "assets";
import { ChronoBullet, ChronoTrack, SubNavItem } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";

import * as styles from "./subnav-list.module.scss";

const SubNavList = ({
  chronoPresentation,
  closeSubNav,
  data,
  fullWidth,
  isMainNav,
  isMainNavSubNavList,
  mode,
  showBylines,
  showDividers,
  theme,
  willFocus,
}) => {
  useEffect(() => {
    if (willFocus) {
      // The first subnav link only gets focus on its initial render
      ref.current?.childNodes[0].childNodes[0].focus();
    }
  }, []);

  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref, { startThreshold: 0.1 });
  const className = classNames(
    styles.subNavList,
    scrolledClasses,
    theme ? Theme.addClass(theme, true) : undefined,
    {
      [styles.fullWidth]: fullWidth,
      [styles.mainNav]: isMainNav,
      [styles.verticalScroll]: mode !== "sibling",
      [styles.siblingNavigation]: mode === "sibling",
      [styles.addTopPadding]: isMainNavSubNavList || mode === "sibling",
    }
  );

  const containerClass = classNames(
    styles.subnavContainer,
    theme ? Theme.addClass(theme, true) : undefined,
    {
      [styles.fullWidth]: fullWidth,
      [styles.mainNav]: isMainNav,
    }
  );

  const [highlightedSubNavItem, setHighlightedSubNavItem] = useState(undefined);

  return (
    <div className={containerClass}>
      <ul className={className} data-testid="subnav-list" ref={ref}>
        {data.map(
          (
            {
              activePage,
              byline,
              icon,
              img,
              imgAlt,
              isPlaceholder,
              isNew,
              onClick,
              subtitle,
              title,
              to,
            },
            i
          ) => {
            const onHighlightedSubNavItem = () => {
              setHighlightedSubNavItem(i);
            };

            return (
              <li
                className={classNames({
                  [styles.chronoNavigation]: mode === "timeline",
                })}
                key={i}
              >
                {mode === "timeline" && (
                  <Fragment>
                    <ChronoBullet
                      isCurrent={activePage}
                      isHighlighted={highlightedSubNavItem === i}
                      orientation="vertical"
                      disablePointerEvents
                    />
                    {i !== data.length - 1 && (
                      <ChronoTrack orientation="vertical" />
                    )}
                  </Fragment>
                )}
                <SubNavItem
                  activePage={mode === "timeline" ? false : activePage}
                  byline={byline}
                  chronoPresentation={chronoPresentation}
                  closeSubNav={closeSubNav}
                  fullWidth={fullWidth}
                  icon={icon}
                  img={img}
                  imgAlt={imgAlt}
                  isMainNav={isMainNav}
                  isNew={isNew}
                  isPlaceholder={isPlaceholder}
                  onBlur={() => setHighlightedSubNavItem(undefined)}
                  onClick={onClick}
                  onFocus={onHighlightedSubNavItem}
                  onMouseOut={() => setHighlightedSubNavItem(undefined)}
                  onMouseOver={onHighlightedSubNavItem}
                  ref={i === 0 ? ref : undefined}
                  showByline={showBylines}
                  showDivider={i === 0 ? Display.Hidden : showDividers}
                  subtitle={subtitle}
                  theme={theme}
                  title={title}
                  to={to}
                />
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

SubNavList.displayName = "SubNavList";

SubNavList.propTypes = {
  chronoPresentation: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      activePage: PropTypes.bool,
      byline: PropTypes.string,
      closeSubNav: PropTypes.func,
      icon: PropTypes.string,
      img: NMAAHCPropTypes.Image,
      imgAlt: PropTypes.string,
      isExternal: PropTypes.bool,
      isNew: PropTypes.bool,
      onClick: PropTypes.func,
      subtitle: PropTypes.string,
      title: PropTypes.string.isRequired,
      to: PropTypes.string,
    })
  ).isRequired,
  fullWidth: PropTypes.bool,
  isMainNav: PropTypes.bool,
  isMainNavSubNavList: PropTypes.bool,
  mode: PropTypes.oneOf(["default", "sibling", "timeline"]),
  showBylines: Display.PropType,
  showDividers: Display.PropType,
  willFocus: PropTypes.bool,
  ...Theme.PropType,
};

SubNavList.defaultProps = {
  data: [],
  willFocus: false,
  fullWidth: false,
  isMainNav: false,
  isMainNavSubNavList: false,
  showBylines: Display.Show,
  showDividers: Display.Show,
  mode: "default",
};
export default SubNavList;

import { NMAAHCPropTypes } from "assets";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./resource-card.module.scss";

const ResourceCard = ({
  backgroundImage,
  buttonColor,
  buttonText,
  description,
  fontType,
  icon,
  title,
  uri,
}) => {
  return (
    <div
      className={styles.resourceCard}
      data-testid="resource-card"
      style={{
        backgroundImage: `url(${backgroundImage?.url})`,
      }}
    >
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        {icon && <i className={classNames(icon)} />}
        <FormattedText
          className={classNames(fontType)}
          outerElement={<h2 />}
          text={title}
        />
        <FormattedText text={description} />
        <ActionButton
          color={buttonColor}
          screenReaderText={buttonText}
          text={buttonText}
          to={`/${uri}`}
        />
      </div>
    </div>
  );
};

ResourceCard.propTypes = {
  backgroundImage: NMAAHCPropTypes.Image,
  buttonColor: PropTypes.oneOf(ActionButton.Colors),
  buttonText: PropTypes.string,
  description: PropTypes.string,
  fontType: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  uri: PropTypes.string,
};

ResourceCard.defaultProps = {};

export default ResourceCard;

import { NMAAHCPropTypes, Theme } from "assets";
import {
  ActionButton,
  ExpandButton,
  FormattedText,
  Listen,
  Metadata,
} from "atoms";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./image-with-caption.module.scss";

// Includes magnification capabilities based on the react-magnifier library, adjusted for Gatsby Image
// https://github.com/samuelmeuli/react-magnifier/
const ImageWithCaption = ({
  audioAsset,
  caption,
  centeredCaption,
  fullImgClickable,
  iconName,
  image,
  imageClassName,
  imageWrapperClassName,
  isConstellation,
  layout,
  withIcon,
  objectFit,
  objectPosition,
  onExpand,
  theme,
  title,
  video,
}) => {
  const metadata = {
    copyright: image.copyright,
    creditLine: image.creditLine,
    displayTitle: image.displayTitle,
    linkField: image.linkField,
    nmaahcObject: image.nmaahcObject,
    objectName: image.objectName,
    objectNumber: image.objectNumber,
    objectTitle: image.objectTitle,
    subjectMedium: image.subjectMedium,
  };

  const imageButtonClass = classNames(styles.imageButton, {
    [styles.videoCtaPadding]: video,
    [styles.constellationMaxHeight]: isConstellation,
    [styles.clickable]: fullImgClickable,
  });

  const ContainerTag = fullImgClickable ? "button" : "div";

  return (
    <>
      <div className={styles.imageContainer} data-testid="image-with-caption">
        {title && video && (
          <FormattedText
            className={classNames(
              styles.headline,
              Theme.addClass(Theme.Subtle, false)
            )}
            outerElement={<h3 />}
            text={title}
            deepLink
          />
        )}
        <div
          className={classNames(styles.captionImageWrapper, {
            [styles.noCaption]: !caption,
          })}
        >
          <ContainerTag
            aria-label={image?.altText}
            className={imageButtonClass}
            data-testid="image-button"
            disabled={!fullImgClickable}
            onClick={onExpand}
          >
            <GatsbyImage
              alt={image?.altText}
              className={imageWrapperClassName}
              formats={["auto", "webp", "avif"]}
              image={getImage(image?.imageFile)}
              imgClassName={imageClassName}
              layout={layout}
              objectFit={objectFit}
              objectPosition={objectPosition}
            />
            {onExpand && withIcon && (
              <ExpandButton
                className="iwc-expand"
                iconName={iconName}
                onExpand={onExpand}
                screenReaderText={caption || image?.altText}
                theme={theme}
              />
            )}
            {onExpand && video && (
              <div className={styles.watchButton}>
                <ActionButton
                  icon="play"
                  screenReaderText={title || video.embeddedAsset?.title}
                  text={"Watch Video"}
                />
              </div>
            )}
          </ContainerTag>
          {caption && (
            <div className={styles.captionContainer}>
              <FormattedText
                className={classNames(
                  styles.imageCaption,
                  Theme.addClass(Theme.Subtle, false),
                  {
                    [styles.centeredCaption]: centeredCaption,
                  },
                  "caption"
                )}
                text={caption}
              />
            </div>
          )}
        </div>
        {title && !video && (
          <FormattedText
            className={classNames(styles.imageTitle)}
            text={title}
            deepLink
          />
        )}
        {isConstellation && metadata && (
          <Metadata {...metadata} theme={theme} />
        )}
        {audioAsset?.title && audioAsset?.url && (
          <div className={styles.listen}>
            <Listen audioAsset={audioAsset} />
          </div>
        )}
      </div>
    </>
  );
};

ImageWithCaption.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  caption: PropTypes.string,
  centeredCaption: PropTypes.bool,
  iconName: PropTypes.oneOf(["expand", "plus"]),
  image: NMAAHCPropTypes.Image.isRequired,
  imageClassName: PropTypes.string,
  imageWrapperClassName: PropTypes.string,
  isConstellation: PropTypes.bool,
  layout: PropTypes.oneOf(["constrained", "fixed", "fullWidth"]),
  // the default objectFit setting for GatsbyImage is "cover", so you technically don't need to define it explicitly, but including for completeness
  objectFit: PropTypes.oneOf(["contain", "cover", "none", "scale-down"]),
  objectPosition: PropTypes.string,
  onExpand: PropTypes.func,
  withIcon: PropTypes.bool,
  ...Theme.PropType,
  title: PropTypes.string,
  video: PropTypes.object,
};

ImageWithCaption.defaultProps = {
  centeredCaption: false,
  iconName: "expand",
  isConstellation: false,
  layout: "fixed",
  withIcon: true,
  theme: Theme.Dark,
};

export default ImageWithCaption;

import { Theme, ThemeContext, useScrollObserver } from "assets";
import classNames from "classnames";
import { graphql } from "gatsby";
import { TextPromo } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./text-comparison.module.scss";

const TextComparison = ({
  mainTitle,
  subtitle,
  leftColumnTitle,
  rightColumnTitle,
  rows,
}) => {
  const ref = useRef();
  const { theme } = useContext(ThemeContext);
  const scrolledClasses = useScrollObserver(ref, { startThreshold: 0.005 });
  // column heading text/backgrounds and the arrow png rely on this for theme-related customization
  const invertFix = Theme.DarkBackgrounds.includes(theme);

  const tableClasses = classNames(scrolledClasses, styles.textComparison);

  const headingRowClasses = classNames(
    "col-md-offset-1 col-xs-12 col-md-10",
    styles.split,
    styles.columnHeader,
    invertFix && styles.headerInvertFix // handles custom inversion
  );

  const iconClasses = classNames(
    "icon-next-circle-outline",
    Theme.addClass(Theme.Subtle, false, false),
    styles.icon
  );

  return (
    <div className={"container-fluid"}>
      {(mainTitle || subtitle) && (
        <div className="col-md-offset-1 col-md-10">
          <TextPromo
            summary={subtitle}
            title={mainTitle}
            withComponentSpacing={false}
            fullWidth
          />
        </div>
      )}
      <table className={tableClasses} data-testid="text-comparison" ref={ref}>
        <thead className="row">
          <tr className={headingRowClasses}>
            <th className={classNames(Theme.addClass(Theme.Subtle))}>
              {leftColumnTitle}:
            </th>
            <th>{/* no heading needed; only icons in column */}</th>
            <th>{rightColumnTitle}:</th>
          </tr>
        </thead>
        <tbody className={`row ${styles.body}`}>
          {rows.map((row, i) => (
            <tr
              className={`col-md-offset-1 col-xs-12 col-md-10 ${styles.split}`}
              data-testid="row"
              key={i}
            >
              <td className={classNames(Theme.addClass(Theme.Subtle))}>
                <h4>{row.leftValue}</h4>
              </td>
              <td>
                <i className={iconClasses} />
              </td>
              <td>
                <h4>{row.rightValue}</h4>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TextComparison.propTypes = {
  leftColumnTitle: PropTypes.string.isRequired,
  mainTitle: PropTypes.string,
  rightColumnTitle: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      leftValue: PropTypes.string.isRequired,
      rightValue: PropTypes.string.isRequired,
    })
  ).isRequired,
  subtitle: PropTypes.string,
};

/**
 * The GraphQL fragment for retrieving Text Comparison data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const TextComparisonFragment = graphql`
  fragment TextComparisonFragment on CraftAPI_componentList_textComparison_BlockType {
    id
    mainTitle
    subtitle
    leftColumnTitle
    rightColumnTitle
    rows {
      leftValue
      rightValue
    }
  }
`;

/**
 * Converts the provided text comparison data into a text comparison component
 *
 * @param textComparisonData  the GraphQL response data
 * @returns                   the text comparison component
 */
const convert = (textComparisonData) => {
  return <TextComparison key={textComparisonData.id} {...textComparisonData} />;
};

export { convert, TextComparison as default, TextComparisonFragment };

import { NMAAHCPropTypes, Theme } from "assets";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./thematic-pillar-card.module.scss";

const ThematicPillarCard = ({
  coverImage,
  buttonText,
  formattedTitleOverride,
  pageLink,
  title,
}) => {
  const className = classNames(
    styles.thematicCard,
    Theme.addClass(Theme.Light, true)
  );

  const coverImageCtn = coverImage ? (
    <GatsbyImage
      alt={coverImage?.altText}
      image={getImage(coverImage?.imageFile)}
    />
  ) : (
    <p>Missing Cover Image</p>
  );

  const cardTitle = (
    <FormattedText
      className={styles.title}
      outerElement={<h3 />}
      text={formattedTitleOverride || title}
    />
  );

  return (
    <div className={className} data-testid="thematic-pillar-card">
      <div className={styles.content}>
        <i className={`${styles.icon} icon-stacked-box`} />
        <div className={styles.textCtn}>{cardTitle}</div>
        <div className={styles.ctnBtn}>
          <ActionButton
            screenReaderText={`. Go to ${title}`}
            text={buttonText || "Explore Theme"}
            textPlacement="left"
            to={`/${pageLink}`}
            secondaryCta
          />{" "}
          <div className={styles.triangle}></div>
        </div>
      </div>
      {coverImageCtn}
    </div>
  );
};

ThematicPillarCard.propTypes = {
  buttonText: PropTypes.string,
  coverImage: NMAAHCPropTypes.Image,
  formattedTitleOverride: PropTypes.string,
  pageLink: PropTypes.string,
  title: PropTypes.string,
};

export default ThematicPillarCard;

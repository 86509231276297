import { setTagList } from "assets";
import { FilteredContent } from "organisms";
import PropTypes from "prop-types";
import React, { useState } from "react";

const ResourceTopicContent = ({ content, tagData }) => {
  const [exhibitTag, setExhibitTag] = useState(null);

  const exhibitTagList = setTagList("exhibit", tagData, content);

  let activeSection = true;
  let itemCount = 0;

  const filteredComponents = content?.map((item) => {
    if (item.props.exhibit?.title) {
      // Exhibit Section
      activeSection = !exhibitTag || exhibitTag === item.props.exhibit.title;
    } else if (activeSection) {
      // Cards Scroll Wrapper
      item.props.children?.length
        ? (itemCount += item.props.children.length)
        : itemCount++;
    }
    if (activeSection) return item;
  });

  return (
    <div data-testid="resource-topic-content">
      <FilteredContent
        contentLength={itemCount}
        contentType={"resources"}
        filterTagList1={["Exhibits", exhibitTagList]}
        setParentTag1={setExhibitTag}
      >
        {filteredComponents}
      </FilteredContent>
    </div>
  );
};

ResourceTopicContent.propTypes = {
  content: PropTypes.object,
  tagData: PropTypes.array,
};

export default ResourceTopicContent;

import "./layout.scss";

import { Theme } from "assets";
import classNames from "classnames";
import { SiteFooter } from "molecules";
import { MainNavBar } from "organisms";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./layout.module.scss";

const Layout = ({ children, showSiteFooter, className, pageType }) => {
  return (
    <div className={styles.layout} data-testid="layout">
      <a className={styles.skipLinks} href="#main">
        Skip to Content
      </a>
      <MainNavBar pageType={pageType} />
      <section
        className={classNames(styles.container, className)}
        data-testid="container"
        id="main"
      >
        {children}
      </section>
      {showSiteFooter && <SiteFooter theme={Theme.Black} />}
    </div>
  );
};
Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  pageType: PropTypes.string,
  showSiteFooter: PropTypes.bool,
  ...Theme.PropType,
};

Layout.defaultProps = {
  showSiteFooter: true,
  theme: Theme.Dark,
};

export default Layout;

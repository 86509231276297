import { useScrollObserver } from "assets";
import classNames from "classnames";
import { graphql } from "gatsby";
import { TextPromo } from "molecules";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as styles from "./stats-module.module.scss";

const StatsModule = ({
  numericFormat,
  stats,
  subtitle,
  swapColumns,
  title,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref, { startThreshold: 0.005 });

  const className = classNames(
    "container-fluid",
    styles.statsSection,
    scrolledClasses
  );

  const containerClassName = classNames({
    [styles.narrowContainer]: !swapColumns,
    [styles.wideContainer]: swapColumns,
  });

  return (
    <section className={className} data-testid="stats-module" ref={ref}>
      <div className={classNames("center-xs", "row", containerClassName)}>
        {(title || subtitle) && (
          <TextPromo
            summary={subtitle}
            title={title}
            withComponentSpacing={false}
            fullWidth
          />
        )}
        <table
          className={classNames(styles.tableFill, {
            [styles.tableReverse]: swapColumns,
          })}
        >
          <colgroup>
            <col className={styles.labelCol} span={1} />
            <col className={styles.statCol} span={1} />
          </colgroup>
          <thead>
            <tr>
              <th scope={"col"} />
              <th scope={"col"} />
            </tr>
          </thead>
          <tbody>
            {stats.map((stat, i) => {
              return (
                <tr
                  className={classNames(
                    "middle-xs",
                    "left-xs",
                    styles.tableRowContainer
                  )}
                  key={i}
                >
                  <td
                    className={styles.label}
                    colSpan={1}
                    key={`label-row-${i}`}
                  >
                    <p data-testid="label">{stat.label}</p>
                  </td>
                  <td className={styles.stat} colSpan={1} key={`stat-row-${i}`}>
                    <p data-testid="stat">
                      {numericFormat && parseInt(stat.stat)
                        ? parseInt(stat.stat).toLocaleString()
                        : stat.stat}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

StatsModule.propTypes = {
  numericFormat: PropTypes.bool,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  subtitle: PropTypes.string,
  swapColumns: PropTypes.bool,
  title: PropTypes.string,
};

StatsModule.defaultProps = {
  numericFormat: true,
  rightAlignedLabels: true,
};

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const StatsFragment = graphql`
  fragment StatsFragment on CraftAPI_componentList_statsModule_BlockType {
    id
    numericFormat
    stats {
      stat
      label
    }
    statsModuleSubtitle
    statsModuleTitle
    swapColumns
  }
`;

const convert = (statsData) => {
  return (
    <StatsModule
      key={statsData.id}
      numericFormat={statsData.numericFormat}
      stats={statsData.stats}
      subtitle={statsData.statsModuleSubtitle}
      swapColumns={statsData.swapColumns}
      title={statsData.statsModuleTitle}
    />
  );
};

export { convert, StatsModule as default, StatsFragment };

import { detect } from "detect-browser";

/**
 * Determines if a feature is supported in a browser using the caniuse API
 *
 * Currently being used to determine if Lazy loading via attribute for iframes is supported.
 * May consider removal once iOS deviced have wide support of this featred
 *
 * @param {String} feature Browser feature that needs
 * @returns {Boolean} if the browser supports the feature
 */
const BrowserSupports = (feature) => {
  const caniuse = require("caniuse-api");
  const browserSupportList = caniuse.getSupport(feature, true);
  const browser = detect();

  let caniuseBrowserName;

  switch (browser.name) {
    //Currently these register as different browsers but they all user safari to render,
    //will probably need to be updated when/if iOS allows third party rendering engines.
    case "ios":
    case "crios":
    case "fxios":
    case "ios-webview":
      caniuseBrowserName = "ios_saf";
      break;
    case "opera-mini":
      caniuseBrowserName = "op_mini";
      break;
    default:
      caniuseBrowserName = browser.name;
      break;
  }

  return (
    browserSupportList[caniuseBrowserName]?.y <= parseFloat(browser.version)
  );
};

export default BrowserSupports;

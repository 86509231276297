import { Theme } from "assets";
import logo from "assets/images/logo-nmaahc.svg";
import logoSideText from "assets/images/logo-nmaahc-side-text.svg";
import logoInnerText from "assets/images/logo-nmaahc-text.png";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./logo.module.scss";

const PLACEMENT_INNER = "inner";
const PLACEMENT_SIDE = "side";

const Logo = ({ className, textPlacement, theme }) => {
  let image = [];
  const alt = "National Museum of African American History and Culture - Home";

  if (textPlacement === PLACEMENT_INNER) {
    // Logo with text inside building
    image.push(
      <img
        alt={alt}
        className={classNames({ [styles.logoBlack]: theme === Theme.Light })}
        key="logo-image"
        src={logoInnerText}
      />
    );
  } else if (textPlacement === PLACEMENT_SIDE) {
    // Logo with text to side of building (additional no text logo for mobile)
    image.push(
      <img
        alt={alt}
        className={classNames(
          "hidden-mobile",
          "hidden-tablet",
          styles.sideText,
          {
            [styles.logoBlack]: theme === Theme.Light,
          }
        )}
        key="logo-image"
        src={logoSideText}
      />
    );
    image.push(
      <img
        alt={alt}
        className={classNames("hidden-desktop", {
          [styles.logoBlack]: theme === Theme.Light,
        })}
        key="logo-mobile-image"
        src={logo}
      />
    );
  } else {
    // Plain logo without text
    image.push(
      <img
        alt={alt}
        className={classNames({ [styles.logoBlack]: theme === Theme.Light })}
        key="logo-image"
        src={logo}
      />
    );
  }

  return (
    <div className={`${className} ${styles.logo}`} data-testid="logo">
      <Link to="/">{image}</Link>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  textPlacement: PropTypes.oneOf([PLACEMENT_INNER, PLACEMENT_SIDE]),
  ...Theme.PropType,
};

Logo.defaultProps = {
  className: "",
  textPlacement: null,
  theme: Theme.Dark,
};

export default Logo;

import { ResourceCard } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./resource-cards.module.scss";

const ResourceCards = ({ data }) => {
  const cards = data?.map((resource) => {
    return (
      <ResourceCard
        backgroundImage={resource.coverImage?.[0]}
        buttonColor={resource.buttonColor}
        buttonText={resource.buttonText}
        description={resource.longDescription}
        fontType={resource.fontType}
        icon={resource.heroIcon}
        key={resource.id}
        title={resource.title}
        uri={resource.uri}
      />
    );
  });

  return (
    <div
      className={classNames("container-fluid", styles.resourceCards)}
      data-testid="resource-cards"
    >
      <div className={styles.wrapper}>{cards}</div>
    </div>
  );
};

ResourceCards.propTypes = {
  data: PropTypes.array,
};

export default ResourceCards;

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./chrono-bullet.module.scss";

const ChronoBullet = ({
  disablePointerEvents,
  isCurrent,
  isHighlighted,
  label,
  onClick,
  onKeyDown,
  orientation,
}) => {
  const linkClasses = classNames({
    [styles.current]: isCurrent,
    [styles.highlight]: isHighlighted,
    [styles.disablePointerEvents]: disablePointerEvents,
  });
  const bulletClasses = classNames({
    [styles.bullet]: true,
    [styles.horizontalBullet]: orientation === "horizontal",
    [styles.verticalBullet]: orientation === "vertical",
    [styles.current]: isCurrent,
    [styles.highlight]: isHighlighted,
    [styles.disablePointerEvents]: disablePointerEvents,
  });
  return (
    <a
      aria-label={label}
      className={linkClasses}
      href="#"
      onClick={(event) => {
        event.preventDefault();
        onClick(event);
      }}
      onKeyDown={onKeyDown}
      title={label}
    >
      <span className={styles.label}>{label}</span>
      <span className={bulletClasses}></span>
    </a>
  );
};
ChronoBullet.propTypes = {
  disablePointerEvents: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
};
ChronoBullet.defaultProps = {
  label: "my label",
  onClick: () => {
    return false;
  },
  orientation: "horizontal",
};
export default ChronoBullet;

import { Display, NMAAHCPropTypes, Theme } from "assets";
import { CloseButton, FormattedText } from "atoms";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./dissection-detail-modal.module.scss";

const DissectionDetailModal = ({
  activePin,
  currentPageIndex,
  leftSide,
  pages,
  pins,
  setActivePin,
  setIsAggPageTranscript,
  setShowDocumentTranscript,
  setShowDetailModal,
  showDocumentTranscript,
  showDetailModal,
  title,
  transcript,
}) => {
  const getPinKey = (id) => `pinKey-${id}`;
  const nextPin = () => {
    const newPinIndex = pins?.indexOf(activePin.pin) + 1;
    setActivePin({
      pin: pins?.[newPinIndex],
      index: newPinIndex,
    });
    localStorage.setItem(getPinKey(activePin.pin?.entry?.id), "true");
  };

  const prevPin = () => {
    const newPinIndex = pins?.indexOf(activePin.pin) - 1;
    setActivePin({
      pin: pins?.[newPinIndex],
      index: newPinIndex,
    });
    localStorage.setItem(getPinKey(activePin.pin?.entry?.id), "true");
  };

  return (
    <div
      className={classNames(styles.pinModal, {
        [styles.left]: leftSide,
        [styles.hideModal]: !showDetailModal,
      })}
      data-testid={"dissection-detail-modal"}
    >
      <div
        className={classNames(
          styles.closeButton,
          { [styles.hideCloseMobile]: !showDocumentTranscript },
          "container-fluid"
        )}
      >
        <CloseButton
          labelDisplay={Display.Show}
          onClick={() => {
            setShowDetailModal(false);
            setShowDocumentTranscript(false);
            setIsAggPageTranscript(false);
          }}
          theme={Theme.Light}
        />
      </div>

      <div className={classNames(styles.contentWrapper, "container-fluid")}>
        <h2>{title}</h2>
        {showDocumentTranscript && <FormattedText text={transcript} />}
        {!showDocumentTranscript && (
          <>
            <div className={classNames("large-font", styles.pinTitle)}>
              <i
                aria-label={activePin?.pin?.entry?.title}
                className="icon-plus"
              />{" "}
              {activePin.pin?.entry?.title}
            </div>

            {activePin.pin?.entry?.coverImage?.[0] && (
              <GatsbyImage
                alt={activePin.pin?.entry?.coverImage?.[0].altText}
                className={styles.pinImage}
                image={getImage(
                  activePin.pin?.entry?.coverImage?.[0].imageFile
                )}
              />
            )}
            {activePin.pin?.entry?.transcription && (
              <FormattedText
                className={styles.transcriptQuote}
                outerElement={<q />}
                text={`${activePin.pin?.entry?.transcription}`}
              />
            )}
            <FormattedText text={activePin.pin?.entry?.longDescription} />
            {pages?.[currentPageIndex]?.documentTranscript && (
              <button
                className={styles.openTranscriptButton}
                onClick={() => {
                  setShowDocumentTranscript(true);
                }}
              >
                Open Transcript
              </button>
            )}

            <div className={classNames("large-font", styles.pinCount)}>
              {pins?.indexOf(activePin.pin) + 1}/{pins.length}
            </div>

            <div className={styles.pinNavigation}>
              <button
                aria-label="Previous Pin"
                className={styles.navButton}
                disabled={pins?.indexOf(activePin.pin) === 0}
                onClick={prevPin}
              >
                <i aria-hidden="true" className="icon-arrow-back-bold" />
              </button>
              <button
                aria-label="Next Pin"
                className={styles.navButton}
                disabled={pins?.indexOf(activePin.pin) + 1 === pins.length}
                onClick={nextPin}
              >
                <i aria-hidden="true" className="icon-arrow-forward-bold" />
              </button>
            </div>

            {activePin.pin?.entry?.coverImage?.[0].creditLine && (
              <p className={styles.creditLine}>
                {activePin.pin?.entry?.coverImage?.[0].creditLine}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

DissectionDetailModal.propTypes = {
  activePin: NMAAHCPropTypes.TurningThePagePin,
  currentPageIndex: PropTypes.number,
  leftSide: PropTypes.bool,
  pages: PropTypes.arrayOf(NMAAHCPropTypes.TurningThePagePage),
  pins: PropTypes.array,
  setActivePin: PropTypes.func,
  setIsAggPageTranscript: PropTypes.func,
  setShowDetailModal: PropTypes.func,
  setShowDocumentTranscript: PropTypes.func,
  showDetailModal: PropTypes.bool,
  showDocumentTranscript: PropTypes.bool,
  title: PropTypes.string,
  transcript: PropTypes.string,
};

DissectionDetailModal.defaultProps = {};

export default DissectionDetailModal;

import { Theme } from "assets";
import { ActionButton, Logo } from "atoms";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./site-footer.module.scss";

export const InternalSiteFooter = ({ theme, socialMediaLinks }) => {
  const footerClassName = classNames(
    styles.siteFooter,
    Theme.addClass(theme, true)
  );

  const iconClassName = classNames(styles.icon, Theme.addClass(theme, false));

  return (
    <footer
      className={`container-fluid ${footerClassName}`}
      data-testid="site-footer"
    >
      <div className="row middle-xs">
        <div className={`col-xs-12 col-md-2 ${styles.logo} center-xs end-md`}>
          <Logo textPlacement="inner" theme={theme} />
        </div>
        <address
          className={`col-xs-offset-2 col-xs-8 col-md-offset-0 col-md-3 ${styles.address} center-xs start-md`}
        >
          1400 Constitution Ave, NW <br />
          Washington, DC 20560
        </address>
        <div
          className={`col-xs-12 col-md-3  ${styles.socialIcons} center-xs end-md`}
        >
          {socialMediaLinks.map((iconLink, i) => {
            const [iconName, link, spokenLabel] = iconLink
              .split(",")
              .map((str) => str.trim());
            return (
              <a
                className={iconClassName}
                href={link}
                key={iconName}
                rel="noreferrer"
                target="_blank"
              >
                <i className={`icon-${iconName}`} aria-hidden />
                <span className="visually-hidden">
                  {spokenLabel || `to NMAAHC'S ${iconName} page`}
                </span>
              </a>
            );
          })}
        </div>
        <div
          className={`col-xs-12 col-md-4 col-lg-3 ${styles.visitButton} row center-xs end-md`}
        >
          <ActionButton
            icon="leave-arrow"
            onClick={() => {
              window.open("https://nmaahc.si.edu/", "_blank");
            }}
            text="VISIT NMAAHC.SI.EDU"
            textPlacement={"left"}
          />
        </div>
      </div>
      <div className={`row center-xs ${styles.legalFooter}`}>
        <div className="col-xs">
          <a href="https://www.si.edu/privacy" rel="noreferrer" target="_blank">
            Privacy
          </a>
          <span className={styles.privacyTermsDivider}>|</span>
          <a
            href="https://www.si.edu/Termsofuse"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
        </div>
      </div>
      <span className="hidden">
        Searchable Museum code version: {process.env.GATSBY_SM_VERSION}
      </span>
    </footer>
  );
};

InternalSiteFooter.propTypes = {
  socialMediaLinks: PropTypes.array,
  ...Theme.PropType,
};

InternalSiteFooter.defaultProps = {
  socialMediaLinks: [],
  theme: Theme.Dark,
};

export default function SiteFooter(props) {
  let data;
  if (process.env.STORYBOOK) {
    data = [];
  } else {
    data = useStaticQuery(graphql`
      query {
        craftAPI {
          globalSet(handle: "footer") {
            ... on CraftAPI_footer_GlobalSet {
              id
              name
              socialMediaLinks
            }
          }
        }
      }
    `);
  }

  return (
    <InternalSiteFooter
      {...props}
      socialMediaLinks={data?.craftAPI?.globalSet?.socialMediaLinks}
    />
  );
}

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./whats-new.module.scss";

const WhatsNew = ({ numberOfNewItems, openByDefault, children }) => {
  const [open, setOpen] = useState(openByDefault);

  function getTitleText() {
    let titleText = "Show What's New";
    if (open) {
      titleText = "Hide What's New";
    }
    return titleText;
  }

  const arrowIconClassName = classNames(
    {
      "icon-down-carrot": open,
      "icon-up-carrot": !open,
    },
    [styles.carrotIconClass]
  );

  const childernContainerClassName = classNames(styles.childrenContainer, {
    [styles.open]: open,
    [styles.closed]: !open,
  });

  const whatsNewContainerClass = classNames({
    [styles.componentSpacing]: !open,
  });

  return (
    <div className={whatsNewContainerClass} data-testid="whats-new">
      <div
        className={styles.whatsNewBar}
        onClick={() => setOpen((prevOpenState) => !prevOpenState)}
      >
        <div className={styles.newText}>NEW</div>
        <div className={styles.stateOfHidingText}>{getTitleText()}</div>
        <div className={styles.numberOfNewItems}>{numberOfNewItems}</div>
        <div data-testid="whats-new-arrow">
          <i className={arrowIconClassName} />
        </div>
      </div>
      <div
        className={childernContainerClassName}
        data-testid="whats-new-children"
      >
        {children}
      </div>
    </div>
  );
};

WhatsNew.propTypes = {
  children: PropTypes.node,
  numberOfNewItems: PropTypes.number,
  openByDefault: PropTypes.bool,
};

WhatsNew.defaultProps = {
  openByDefault: true,
};

export default WhatsNew;

// extracted by mini-css-extract-plugin
export var childSubnavContainer = "timeline-navigation-module--child-subnav-container--wGEwy";
export var clearPill = "timeline-navigation-module--clear-pill--Y5Mmi";
export var constellation = "timeline-navigation-module--constellation--f48xU";
export var dark = "timeline-navigation-module--dark---Vrcr";
export var hidden = "timeline-navigation-module--hidden--jTijU";
export var highlitGreyPill = "timeline-navigation-module--highlit-grey-pill--YfT32";
export var highlitOrangePill = "timeline-navigation-module--highlit-orange-pill--shAZk";
export var innerNumberStyling = "timeline-navigation-module--innerNumberStyling--d+tRV";
export var listElementLi = "timeline-navigation-module--list-element-li--t8wA+";
export var listUl = "timeline-navigation-module--list-ul--LaoeR";
export var listUlWrapper = "timeline-navigation-module--list-ul-wrapper---13sR";
export var rightArrow = "timeline-navigation-module--right-arrow--NMLMN";
export var scrollWrapperChildren = "timeline-navigation-module--scrollWrapperChildren--VN7o2";
export var subnavHidden = "timeline-navigation-module--subnav-hidden--PR64j";
export var timelineNavigationContainer = "timeline-navigation-module--timeline-navigation-container---lXsX";
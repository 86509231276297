import {
  Afrofuturism,
  Black,
  Dark,
  Light,
  Subtle,
  White,
} from "./../theme/theme.js";
import * as styles from "./glow-border.module.scss";

/**
 * Apply the CSS class(es) based on the provided theme and background option
 *
 * @param {String} theme the theme to apply
 * @param {Boolean} showBorder the theme to apply
 * @returns {{}}  a CSS class object to be used with classnames
 */
const addBorder = (showBorder, theme) => ({
  [styles.glowBorder]: showBorder,
  [styles.black]: theme === Black || theme === Afrofuturism,
  [styles.afrofuturism]: theme === Afrofuturism,
  [styles.dark]: theme === Dark,
  [styles.light]: theme === Light,
  [styles.white]: theme === White,
  [styles.subtle]: theme === Subtle,
});

export { addBorder };

import { SubNavItem } from "atoms";
import PropTypes from "prop-types";
import React from "react";

const SearchResult = ({ hit }) => (
  <SubNavItem
    byline={hit.shortDescription?.split(/\r?\n/g)[0]}
    img={hit.thumbnailLocal}
    imgAlt={hit.altText}
    pageTitle={hit.title}
    pageType={hit.pageType}
    tags={hit.mediaTags}
    title={hit.title}
    to={hit.path}
    fullWidth
    isSearchResult
  />
);

SearchResult.propTypes = {
  hit: PropTypes.shape({
    altText: PropTypes.string,
    mediaTags: PropTypes.arrayOf(PropTypes.string),
    pageTitle: PropTypes.string,
    pageType: PropTypes.string,
    path: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    thumbnailLocal: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
  isSearchResult: PropTypes.bool,
};

export default SearchResult;

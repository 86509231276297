import { navigate } from "gatsby";
import queryString from "query-string";

/* The hash string for scrolling to an active component */
const GOTO_HASH = "goto";

/**
 * Returns whether or not the provided component is the active component by checking the URL
 *
 * @param componentId the id of the component
 * @param location    the reach-router location object
 * @returns {boolean} true if the provided component is active or false if not
 */
const isGoToComponent = (componentId, location) => {
  // Example hash #goto=4811 -> { goto: 4811 }
  const hash = queryString.parse(location.hash);

  return hash?.[GOTO_HASH] === componentId;
};

/**
 * Navigates the browser to the active component
 *
 * @param id          the id of the component
 * @param queryParams optional query parameters in object form
 */
const setGoToComponent = (id, queryParams) => {
  navigate(
    `${window.location.pathname}?${queryString.stringify(
      queryParams
    )}#${GOTO_HASH}=${id}`,
    {
      replace: true,
    }
  );
};

/**
 * Checks the URL and scrolls to the active component
 */
const scrollToComponent = () => {
  // Example hash #goto=4811 -> { goto: 4811 }
  const hash = queryString.parse(window.location.hash);

  // If the hash starts with "#goto
  if (hash[GOTO_HASH]) {
    document.getElementById(`component-${hash.goto}`)?.scrollIntoView();
  }
};

export { isGoToComponent, scrollToComponent, setGoToComponent };

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./drag-handle.module.scss";

const DragHandle = ({ onMouseDown, onMouseUp }) => {
  const [isDragging, setIsDragging] = useState(false);
  const setDragState = (mouseDown) => {
    setIsDragging(mouseDown);
    if (mouseDown) {
      onMouseDown();
    } else {
      onMouseUp();
    }
  };

  const imageCompareClass = classNames("icon-image-compare", {
    [styles.active]: isDragging,
  });

  return (
    <i
      aria-label="Draggable handle"
      className={imageCompareClass}
      onMouseDown={() => setDragState(true)}
      onMouseUp={() => setDragState(false)}
    />
  );
};

DragHandle.propTypes = {
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
};

DragHandle.defaultProps = {
  onMouseDown: () => {},
  onMouseUp: () => {},
};

export default DragHandle;

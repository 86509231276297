import { NMAAHCPropTypes, ThemeContext } from "assets";
import classNames from "classnames";
import { TimelineEventCard } from "molecules";
import moment from "moment";
import { Quote } from "organisms";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import * as styles from "./timeline-slide.module.scss";

const dateFormats = {
  day: (date) => moment(date).format("MMMM D, YYYY"),
  month: (date) => moment(date).format("MMMM YYYY"),
  year: (date) => moment(date).format("YYYY"),
};

// Bare minimum fields to render an event card
const eventExists = ({ description, eventTitle }) => description || eventTitle;

const TimelineSlide = ({
  callout,
  date,
  description,
  event2Date,
  event2Description,
  event2Image,
  event2ImageCropStylingTimeline,
  event2ImagePositionOverride,
  event2TimeGranularity,
  event2Title,
  event2Video,
  eventTitle,
  image,
  imageCropStylingTimeline,
  imagePositionOverride,
  referenceQuote,
  theme,
  timeGranularity,
  video,
}) => {
  const hasTwoEvents = eventExists({
    description: event2Description,
    eventTitle: event2Title,
  });

  const slideClass = classNames("embla__slide container", styles.slide, {
    "duel-events": hasTwoEvents,
  });

  const { fontType } = useContext(ThemeContext);

  const cardColumnClass = classNames(
    "col-xs-12",
    {
      "col-sm-5": !referenceQuote?.[0],
      "col-sm-7": referenceQuote?.[0] || !hasTwoEvents,
    },
    styles.cardColumn,
    fontType
  );

  let columnOneContent;
  let columnTwoContent;

  const [dynamicFontSize, setDynamicFontSize] = useState(undefined);

  // First event card
  let eventCard;
  if (
    eventExists({ description, eventTitle }) &&
    ((referenceQuote?.[0] && (description || image.length)) ||
      !referenceQuote?.[0])
  ) {
    // Show title and date within the card if there are two events or callout text is present
    const showCardHeader = hasTwoEvents || callout;
    eventCard = (
      <div className={cardColumnClass} data-testid="event-card">
        <TimelineEventCard
          date={
            showCardHeader ? dateFormats[timeGranularity]?.(date) : undefined
          }
          description={description}
          getDynamicFontSize={(e) => setDynamicFontSize(e)}
          hasTwoEvents={hasTwoEvents}
          imageCropStyling={imageCropStylingTimeline}
          imageData={image}
          imagePositionOverride={imagePositionOverride}
          theme={theme}
          title={showCardHeader ? eventTitle : undefined}
          video={video}
        />
      </div>
    );
  }

  // Second event card
  let eventCard2;
  if (hasTwoEvents) {
    eventCard2 = (
      <div className={cardColumnClass} data-testid="event-card">
        <TimelineEventCard
          date={dateFormats[event2TimeGranularity]?.(event2Date)}
          description={event2Description}
          hasTwoEvents={hasTwoEvents}
          imageCropStyling={event2ImageCropStylingTimeline}
          imageData={event2Image}
          imagePositionOverride={event2ImagePositionOverride}
          overrideDynamicFontSize={dynamicFontSize}
          theme={theme}
          title={event2Title}
          video={event2Video}
        />
      </div>
    );
  }

  // Quote component
  let quote;
  if (referenceQuote?.[0]) {
    quote = (
      <div className={styles.quoteContainer}>
        <Quote
          audioAsset={referenceQuote[0].audioAsset?.[0]}
          author={referenceQuote[0].quoteAuthor}
          date={referenceQuote[0].quoteYear}
          quote={referenceQuote[0].transcription}
          withComponentSpacing={false}
          fullWidth
          small
        />
      </div>
    );
  }

  if (eventCard2) {
    // Two events
    columnOneContent = eventCard;
    columnTwoContent = eventCard2;
  } else if (quote) {
    // Quote w/ optional event
    columnOneContent = (
      <div
        className={classNames("col-xs-12", "center-sm", {
          "col-sm-5": eventCard,
          "col-sm-12": !eventCard,
        })}
      >
        <div>
          <div className={styles.header}>
            <h3 data-testid="slide-title">{callout || eventTitle}</h3>
          </div>
          {quote}
        </div>
      </div>
    );
    columnTwoContent = eventCard;
  } else {
    // Single event w/ optional callout
    columnOneContent = (
      <div className="start-sm col-sm-4 col-xs-12">
        <div className={styles.header}>
          <h3 className={classNames(fontType)} data-testid="slide-title">
            {callout || eventTitle}
          </h3>
          <span data-testid="slide-date">
            {timeGranularity === "year"
              ? ""
              : dateFormats[timeGranularity]?.(date)}
          </span>
        </div>
      </div>
    );
    columnTwoContent = eventCard;
  }

  return (
    <div className={slideClass} data-testid="timeline-slide">
      <div className={styles.year}>
        <span data-testid="slide-year">{dateFormats.year(date)}</span>
      </div>
      <div className={`row center-lg ${styles.leftIndent}`}>
        {columnOneContent}
        {columnTwoContent}
      </div>
    </div>
  );
};

TimelineSlide.propTypes = {
  callout: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  event2Date: PropTypes.string,
  event2Description: PropTypes.string,
  event2Image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  event2ImageCropStylingTimeline: NMAAHCPropTypes.ImageObjectFit,
  event2ImagePositionOverride: PropTypes.string,
  event2TimeGranularity: PropTypes.string,
  event2Title: PropTypes.string,
  event2Video: PropTypes.arrayOf(NMAAHCPropTypes.EmbeddedVideo),
  eventTitle: PropTypes.string,
  image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  imageCropStylingTimeline: NMAAHCPropTypes.ImageObjectFit,
  imagePositionOverride: PropTypes.string,
  referenceQuote: PropTypes.arrayOf(NMAAHCPropTypes.Quote),
  theme: PropTypes.string,
  timeGranularity: PropTypes.string,
  video: PropTypes.arrayOf(NMAAHCPropTypes.EmbeddedVideo),
};

TimelineSlide.defaultProps = {};

export default TimelineSlide;

import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactCompareImage from "react-compare-image";

import DragHandle from "../drag-handle/drag-handle";

const NMAAHCBlack = "#312d30";
const NMAAHCOrange = "#fe5a31";

const ImageComparison = ({
  leftImage,
  rightImage,
  leftImageAlt,
  rightImageAlt,
}) => {
  const [sliderLineColor, setSliderLineColor] = useState(NMAAHCBlack);

  return (
    <div className={"row"}>
      <div
        className={
          "col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6"
        }
      >
        <ReactCompareImage
          aspectRatio={"wider"}
          leftImage={leftImage}
          leftImageAlt={leftImageAlt}
          rightImage={rightImage}
          sliderLineColor={sliderLineColor}
          rightImageAlt={rightImageAlt}
          // This is as close as we're gonna get to changing the drag color. It's a little wonky when you move your mouse off the drag handle.
          handle={
            <DragHandle
              onMouseDown={() => setSliderLineColor(NMAAHCOrange)}
              onMouseUp={() => setSliderLineColor(NMAAHCBlack)}
            />
          }
        />
      </div>
    </div>
  );
};

ImageComparison.propTypes = {
  leftImage: PropTypes.string.isRequired,
  leftImageAlt: PropTypes.string.isRequired,
  rightImage: PropTypes.string.isRequired,
  rightImageAlt: PropTypes.string.isRequired,
};

export default ImageComparison;

// extracted by mini-css-extract-plugin
export var closeButton = "dissection-detail-modal-module--closeButton--K-2jW";
export var contentWrapper = "dissection-detail-modal-module--contentWrapper--BTIuN";
export var creditLine = "dissection-detail-modal-module--creditLine--tJsyU";
export var hideCloseMobile = "dissection-detail-modal-module--hideCloseMobile--4ZoqE";
export var hideModal = "dissection-detail-modal-module--hideModal--hIWjT";
export var left = "dissection-detail-modal-module--left--L06do";
export var navButton = "dissection-detail-modal-module--navButton--XYuzt";
export var openTranscriptButton = "dissection-detail-modal-module--openTranscriptButton--bXjA8";
export var pinCount = "dissection-detail-modal-module--pinCount--rEETD";
export var pinImage = "dissection-detail-modal-module--pinImage--oV2q0";
export var pinModal = "dissection-detail-modal-module--pinModal--fDPZm";
export var pinNavigation = "dissection-detail-modal-module--pinNavigation--1mQLT";
export var pinTitle = "dissection-detail-modal-module--pinTitle--TxV3H";
export var transcriptQuote = "dissection-detail-modal-module--transcriptQuote--IANM9";
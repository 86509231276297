// extracted by mini-css-extract-plugin
export var closeBar = "nmaahc-modal-window-module--closeBar--4Ihy9";
export var dissectionShift = "nmaahc-modal-window-module--dissectionShift--m1Raj";
export var heightAllScreen = "nmaahc-modal-window-module--heightAllScreen--ZOyz0";
export var listen = "nmaahc-modal-window-module--listen--VH8xG";
export var maxHeight = "nmaahc-modal-window-module--maxHeight--xFjj-";
export var modalContentContainer = "nmaahc-modal-window-module--modalContentContainer--XUjwZ";
export var modalHeader = "nmaahc-modal-window-module--modalHeader--3Q-G-";
export var modalText = "nmaahc-modal-window-module--modalText--3KWqN";
export var modalTextDark = "nmaahc-modal-window-module--modalTextDark--YLjqN";
export var modalTextLight = "nmaahc-modal-window-module--modalTextLight--7eF1G";
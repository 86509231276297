import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

function Seo({
  description,
  keywords,
  lang,
  meta,
  title,
  coverImage,
  shortDescription,
}) {
  let query;

  if (process.env.STORYBOOK) {
    query = {};
  } else {
    query = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              siteUrl
              seoTitle
              title
              description
              author
            }
          }
          defaultOpenGraphImg: imageSharp(
            fluid: { originalName: { eq: "seo-nmaahc.jpeg" } }
          ) {
            id
            fluid {
              src
            }
          }
        }
      `
    );
  }

  let metaDescription = shortDescription;
  if (!shortDescription) {
    metaDescription = description || query?.site?.siteMetadata?.description;
  }

  let metaOpenGraphImgSrc =
    coverImage?.[0]?.imageFile?.childImageSharp?.gatsbyImageData?.images
      ?.fallback?.src;
  if (!metaOpenGraphImgSrc) {
    metaOpenGraphImgSrc = query?.defaultOpenGraphImg?.fluid?.src;
  }

  const siteUrl = process.env.URL || query?.site?.siteMetadata?.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: "title",
          content: query?.site?.siteMetadata?.seoTitle,
        },
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: query?.site?.siteMetadata?.author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "image",
          property: "og:image",
          content: [siteUrl, metaOpenGraphImgSrc].join(""),
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: "keywords",
              content: keywords.join(", "),
            }
            : []
        )
        .concat(meta)}
      title={title}
      titleTemplate={`%s | ${query?.site?.siteMetadata?.title}`}
    />
  );
}

Seo.defaultProps = {
  keywords: [],
  lang: "en",
  meta: [],
  description: "",
};

Seo.propTypes = {
  coverImage: PropTypes.array,
  description: PropTypes.string,
  keywords: PropTypes.array,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  shortDescription: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;

import { ImageComparison, MapLegendItem } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./image-compare-with-legend.module.scss";

const ImageCompareWithLegend = ({
  leftImage,
  leftImageAlt,
  rightImage,
  rightImageAlt,
  imageLabels,
  legendItems,
}) => {
  const marginedRowClasses = classNames("row");

  return (
    <div className={styles.outerMargin} data-testid={"main-element"}>
      <ImageComparison
        leftImage={leftImage}
        leftImageAlt={leftImageAlt}
        rightImage={rightImage}
        rightImageAlt={rightImageAlt}
      />
      {imageLabels && (
        <div
          className={classNames(marginedRowClasses, styles.yearLabelContainer)}
          data-testid="years"
        >
          <div className="col-xs-6 col-sm-offset-2 col-sm-4 col-md-offset-3 col-md-3">
            {imageLabels.leftImageLabel}
          </div>
          <div
            className={classNames(
              styles.rightAlign,
              "col-xs-6 col-sm-4 col-md-3"
            )}
          >
            {imageLabels.rightImageLabel}
          </div>
        </div>
      )}
      {legendItems && (
        <div className={classNames(marginedRowClasses, styles.imageLegend)}>
          <div
            className={
              "col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6"
            }
          >
            <div className="row">
              {legendItems.map((legendItem) => (
                <div
                  className="col-xs-6 col-md-4"
                  data-testid="legendItem"
                  key={legendItem.color}
                >
                  <MapLegendItem
                    color={legendItem.color}
                    itemText={legendItem.itemDescription}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ImageCompareWithLegend.propTypes = {
  imageLabels: PropTypes.shape({
    leftImageLabel: PropTypes.string,
    rightImageLabel: PropTypes.string,
  }),
  leftImage: PropTypes.string.isRequired,
  leftImageAlt: PropTypes.string.isRequired,
  legendItems: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      itemDescription: PropTypes.string,
    })
  ),
  rightImage: PropTypes.string.isRequired,
  rightImageAlt: PropTypes.string.isRequired,
};

/** The GraphQL fragment containing the fields required for an image comparison component. */
const ImageComparisonFragment = graphql`
  fragment ImageComparisonFragment on CraftAPI_componentList_imageComparison_BlockType {
    leftImage {
      url
    }
    leftImageAlt
    rightImage {
      url
    }
    rightImageAlt
    leftImageLabel
    rightImageLabel
    imageLegend {
      color
      colorDescription
    }
  }
`;

/**
 * Converts GraphQL data into an ImageCompareWithLegend component.
 *
 * @param componentData
 */
const convert = (componentData) => {
  let imageLabels = null;
  if (componentData.leftImageLabel && componentData.rightImageLabel) {
    imageLabels = {
      leftImageLabel: componentData.leftImageLabel,
      rightImageLabel: componentData.rightImageLabel,
    };
  }
  const legendItems = componentData.imageLegend?.map((legendItem) => ({
    color: legendItem.color,
    itemDescription: legendItem.colorDescription,
  }));

  return (
    <ImageCompareWithLegend
      imageLabels={imageLabels}
      leftImage={componentData.leftImage?.[0]?.url}
      leftImageAlt={componentData.leftImageAlt}
      legendItems={legendItems}
      rightImage={componentData.rightImage?.[0]?.url}
      rightImageAlt={componentData.rightImageAlt}
    />
  );
};

export { convert, ImageCompareWithLegend as default, ImageComparisonFragment };

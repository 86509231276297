import { NMAAHCPropTypes } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./pin-navigation-buttons.module.scss";

const PinNavigationButtons = ({
  extraClass,
  ref,
  mode,
  pagePinRefsIndex,
  pagePinRefs,
  activePin,
  updatePinTo,
  pins,
}) => {
  return (
    <div className={classNames(styles.pinContentNav, extraClass)}>
      <button
        className={styles.pinContentNavButton}
        disabled={pagePinRefsIndex === 0 && activePin.index === 0}
        onClick={(e) => {
          // if the index is equal to zero
          if (activePin.index === 0) {
            // go to the previous dissection points desktop pin ref
            // and select the last pin
            let prevIndex;
            // if there is a blank end page AND a back cover page
            const hasBackCover =
              pagePinRefs[pagePinRefsIndex - 1].mode[mode]?.refs?.length ===
                0 &&
              pagePinRefs[pagePinRefsIndex - 2].mode[mode]?.refs?.length > 0;
            if (hasBackCover) {
              // set the prev index two indexes ahead
              prevIndex = pagePinRefsIndex - 2;
            } else {
              // set the prev index one index ahead
              prevIndex = pagePinRefsIndex - 1;
            }

            const refs = pagePinRefs[prevIndex].mode[mode].refs;
            refs[refs?.length - 1].current.click();
            // if prev ref pageNum is less than current pageNum
            // turn page backward
            if (
              hasBackCover ||
              pagePinRefs[prevIndex].pageNum ===
                pagePinRefs[pagePinRefsIndex].pageNum
            ) {
              pagePinRefs[
                prevIndex
              ].arrowOverlayRefs.buttons.prev.ref.current.click();
            }
          } else {
            // stay in this current dissection point ul ref
            // and go to the previous pin
            updatePinTo(e, activePin.index - 1, ref, mode);
          }
        }}
      >
        <span className="visually-hidden">
          {activePin.index === 0
            ? "You are at the first Point"
            : `Back to Point ${activePin.index} of ${pins?.length}`}
        </span>
        <i aria-hidden="true" className="icon-arrow-back" />
      </button>

      <button
        className={styles.pinContentNavButton}
        // if there is no next pagePinRefs object (that means there is an even amount of content pages)
        // OR if the next pagePinRefs object has an empty refs array (that means that it is the blank end page) AND there is no next pagePinRefs object after the empty page (that means there is an odd amount of content pages)
        // this is the final DissectionPoints instance
        disabled={
          (pagePinRefs[pagePinRefsIndex + 1] === undefined ||
            (pagePinRefs[pagePinRefsIndex + 1]?.mode[mode]?.refs?.length ===
              0 &&
              pagePinRefs[pagePinRefsIndex + 2] === undefined)) &&
          activePin.index === pins?.length - 1
        }
        onClick={(e) => {
          // if the index is equal to the pins length
          if (activePin.index === pins?.length - 1) {
            // go to the next dissection points desktop pin ref
            // and select the first pin
            let nextIndex;
            // if there is a blank end page AND a back cover page
            const hasBackCover =
              pagePinRefs[pagePinRefsIndex + 1].mode[mode]?.refs?.length ===
                0 &&
              pagePinRefs[pagePinRefsIndex + 2].mode[mode]?.refs?.length > 0;
            if (hasBackCover) {
              // set the next index two indexes ahead
              nextIndex = pagePinRefsIndex + 2;
            } else {
              // set the next index one index ahead
              nextIndex = pagePinRefsIndex + 1;
            }
            const refs = pagePinRefs[nextIndex].mode[mode].refs;
            refs[0].current.click();
            // if next ref pageNum is greater than current pageNum
            // turn page forward

            if (
              hasBackCover ||
              pagePinRefs[nextIndex].pageNum ===
                pagePinRefs[pagePinRefsIndex].pageNum
            ) {
              pagePinRefs[
                nextIndex
              ].arrowOverlayRefs.buttons.next.ref.current.click();
            }
          } else {
            // stay in this current dissection point ul ref
            // and go to the next pin
            updatePinTo(e, activePin.index + 1, ref, mode);
          }
        }}
      >
        <span className="visually-hidden">
          {activePin.index === pins?.length - 1
            ? "You are at the last Point"
            : `Next to Point ${activePin.index + 2} of ${pins?.length}`}
        </span>
        <i aria-hidden="true" className="icon-arrow-forward" />
      </button>
    </div>
  );
};

PinNavigationButtons.propTypes = {
  activePin: NMAAHCPropTypes.TurningThePagePin,
  extraClass: PropTypes.string,
  mode: PropTypes.oneOf(["deskptop", "mobile"]),
  pagePinRefs: PropTypes.shape({}),
  pagePinRefsIndex: PropTypes.number,
  pins: PropTypes.array,
  ref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({}),
  ]),
  updatePinTo: PropTypes.func,
};

PinNavigationButtons.defaultProps = {};

export default PinNavigationButtons;

import { useLocation } from "@reach/router";
import { Display, isExternalLink, NMAAHCPropTypes, Theme } from "assets";
import { FormattedText, TextPill } from "atoms";
import classNames from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import * as styles from "./subnav-item.module.scss";

const SubNavItem = forwardRef(
  (
    {
      activePage,
      byline,
      closeSubNav,
      fullWidth,
      icon,
      img,
      imgAlt,
      isMainNav,
      isSearchResult,
      onBlur,
      onClick,
      onFocus,
      onMouseOut,
      onMouseOver,
      pageTitle,
      pageType,
      showByline,
      showDivider,
      subtitle,
      tags,
      theme,
      title,
      to,
      isNew,
      isPlaceholder,
      chronoPresentation,
    },
    ref
  ) => {
    const firstSectionClassName = classNames(styles.firstRow, {
      row: !fullWidth,
    });

    const secondSectionClassName = classNames(styles.secondRow, {
      row: !fullWidth,
      ...Display.addClass(showByline),
      [styles.alignWithImg]: isSearchResult && img,
    });

    const dividerClassName = classNames(
      "divider",
      styles.divider,
      Display.addClass(showDivider),
      {
        [styles.darker]: theme === Theme.Dark,
      }
    );

    const imageClassName = classNames(styles.imgWrapper, {
      [styles.active]: activePage,
    });

    const bylineClassName = classNames(
      styles.byline,
      theme ? Theme.addClass(theme, false) : undefined,
      {
        [styles.tagDescription]: isSearchResult,
      }
    );

    const colBClassName = classNames(styles.colB, {
      [styles.alignLeft]: isSearchResult,
    });

    let Item;
    let itemProps = { onBlur, onClick, onFocus, onMouseOut, onMouseOver };
    const isExternal = to && isExternalLink(to);

    if (useLocation().pathname === to) {
      // So if we have a subnav link that points to the page we're on, we give it an onClick That closes the subNav
      itemProps.onClick = () => closeSubNav();
    }

    if (to) {
      // Navigation item is a direct link to a page (possibly external)
      Item = isExternal ? "a" : Link;
      if (isExternal) itemProps.href = to;
      else itemProps.to = to;
    } else if (onClick) {
      // Navigation item opens up a sub-navigation menu
      Item = "button";
    } else {
      // Defaults to Div for placeholder component (future events)
      Item = "div";
    }

    const isInactive = Item === "div";

    const itemClassName = classNames(styles.subNavItem, {
      row: fullWidth,
      [styles.realLink]: !isPlaceholder,
      [styles.fullWidth]: fullWidth,
      [styles.mainNav]: isMainNav,
      [styles.isInactive]: isInactive,
      [styles.searchResult]: isSearchResult,
    });

    const subnavID = title.split(" ").join("-");
    const TitleTag = `${fullWidth ? "h2" : "span"}`;
    const convertStr = (str) =>
      str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

    return (
      <Item
        aria-labelledby={subnavID}
        className={itemClassName}
        data-testid="subnav-item"
        ref={ref}
        tabIndex={isInactive ? "-1" : "0"}
        {...itemProps}
      >
        {!isPlaceholder && (
          <i className={`icon-forward ${styles.rightArrow}`} />
        )}
        <div className={dividerClassName} />
        <div className={firstSectionClassName}>
          {img && (
            <div className={styles.colA}>
              <div className={imageClassName} data-testid="img-wrapper">
                {typeof img === "string" ? (
                  <img
                    alt={imgAlt}
                    onError={(e) =>
                      (e.target.parentNode.parentNode.style.display = "none")
                    }
                    src={img}
                  />
                ) : (
                  <GatsbyImage alt={imgAlt} image={getImage(img?.imageFile)} />
                )}
              </div>
            </div>
          )}
          <div className={colBClassName}>
            {icon && <img alt="" className={styles.icon} src={icon} />}
            {isNew && <TextPill size={NMAAHCPropTypes.SizeSmall} text="New" />}
            <TitleTag className={classNames(styles.title)} id={subnavID}>
              {activePage && (
                <div className={styles.subtitle}>Current Page</div>
              )}
              {subtitle && !activePage && chronoPresentation && (
                <div className={styles.subtitle}>{subtitle}</div>
              )}
              {title}
              {isExternal && <i className="icon-external-link" />}
            </TitleTag>
            {isSearchResult && (
              <FormattedText className={bylineClassName} text={byline} />
            )}
            {isSearchResult && tags && (
              <div data-testid="tags">
                {tags.map((tag) => (
                  <TextPill
                    key={tag}
                    size={NMAAHCPropTypes.SizeSmall}
                    style="tag"
                    text={convertStr(tag)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={secondSectionClassName}>
          <div className="col-xs-12">
            {!isSearchResult ? (
              <FormattedText className={bylineClassName} text={byline} />
            ) : (
              <div className={styles.pageInfo}>
                {pageType && (
                  <span data-testid="page-type">{convertStr(pageType)}</span>
                )}
                {pageTitle && (
                  <div className={styles.pageTitle} data-testid="page-title">
                    {pageTitle}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Item>
    );
  }
);

SubNavItem.displayName = "SubNavItem";

SubNavItem.propTypes = {
  activePage: PropTypes.bool,
  byline: PropTypes.string,
  chronoPresentation: PropTypes.bool,
  closeSubNav: PropTypes.func,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  img: PropTypes.oneOfType([
    // search results reference remote img urls
    PropTypes.string,
    // normal images are of the Gatsby variety
    NMAAHCPropTypes.Image,
  ]),
  imgAlt: PropTypes.string,
  isExternal: PropTypes.bool,
  isMainNav: PropTypes.bool,
  isNew: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  isSearchResult: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
  showByline: Display.PropType,
  shpwDivider: Display.PropType,
  subtitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  ...Theme.PropType,
};

SubNavItem.defaultProps = {
  fullWidth: false,
  showByline: Display.Show,
  showDivider: Display.Show,
  isMainNav: false,
  isPlaceholder: false,
  isSearchResult: false,
};

export default SubNavItem;

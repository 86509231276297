import { convertCards } from "molecules";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import * as styles from "./no-search-results.module.scss";

const NoSearchResults = ({ searchedTerm, searchPromos }) => {
  return (
    <div className={`row center-xs ${styles.noSearchResults}`}>
      <div className="col-xs">
        <h1 className="visually-hidden">Search Results</h1>
        <p className={styles.leadText}>
          {"Sorry, we were unable to find content that matches your search."}
        </p>
        {searchPromos.containedCards && (
          <Fragment>
            <p className={styles.text}>
              You can try searching
              <a
                className={styles.link}
                href={`https://nmaahc.si.edu/search?keyword=${searchedTerm}`}
                rel="noreferrer"
                target="_blank"
              >
                on NMAAHC.si.edu
                <i
                  aria-hidden="true"
                  className={`fa fa-external-link ${styles.linkIcon}`}
                />
              </a>
              to find information about your query, or explore more content from
              the Searchable Museum below.
            </p>
            {convertCards(searchPromos)}
          </Fragment>
        )}
      </div>
    </div>
  );
};

NoSearchResults.propTypes = {
  searchedTerm: PropTypes.string.isRequired,
  searchPromos: PropTypes.object.isRequired,
};

export default NoSearchResults;

import { flattenImageFields, NMAAHCPropTypes } from "assets";
import { StatsModule } from "atoms";
import { graphql } from "gatsby";
import { FullWidthSingleImage } from "molecules";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./ncnw-wheel.module.scss";

const NCNWWheel = ({
  componentTitle,
  image,
  organizationHeader,
  organizations,
}) => (
  <div
    className={`container-fluid ${styles.wheelComponent}`}
    data-testid="ncnw-wheel"
  >
    <div className="row center-xs">
      <h3 className="col-md-8 col-xs-12" data-testid="ncnw-title">
        {componentTitle}
      </h3>
    </div>
    {image && <FullWidthSingleImage image={image} />}
    <div className="row center-xs">
      <h3
        className={`col-md-8 col-xs-12 ${styles.organizationHeader}`}
        data-testid="org-header"
      >
        {organizationHeader}
      </h3>
    </div>
    <StatsModule
      stats={organizations?.map((org) => ({
        label: org.organizationDescription,
        subStat: org.organizationName,
        stat: `Est. ${org.organizationYear}`,
      }))}
    />
  </div>
);

NCNWWheel.propTypes = {
  componentTitle: PropTypes.string.isRequired,
  image: NMAAHCPropTypes.Image,
  organizationHeader: PropTypes.string,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      organizationDescription: PropTypes.string.isRequired,
      organizationName: PropTypes.string.isRequired,
      organizationYear: PropTypes.string.isRequired,
    })
  ),
};

const NCNWWheelFragment = graphql`
  fragment NCNWWheelFragment on CraftAPI_componentList_ncnwWheel_BlockType {
    id
    componentTitle
    image {
      ...ImageMetadataFragment
    }
    organizationHeader
    organizations {
      ... on CraftAPI_organizations_BlockType {
        organizationName
        organizationDescription
        organizationYear
      }
    }
  }
`;

const convert = (ncnwWheelData) => (
  <NCNWWheel {...flattenImageFields(ncnwWheelData)} key={ncnwWheelData.id} />
);

export { convert, NCNWWheel as default, NCNWWheelFragment };

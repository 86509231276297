import { graphql } from "gatsby";
import { convertConstellationImage, convertConstellationLink } from "molecules";
import {
  convertConstellationQuote,
  convertConstellationVideoPromo,
} from "organisms";

const subConverters = {
  constellationComponentList_entry: convertConstellationLink,
  constellationComponentList_externalLink: convertConstellationLink,
  constellationComponentList_image: convertConstellationImage,
  constellationComponentList_quotePromo: convertConstellationQuote,
  constellationComponentList_video: convertConstellationVideoPromo,
};

/**
 * The regex used to extract a component name from their GraphQL __typename
 * (e.g. CraftAPI_constellationComponentList_quotePromo_BlockType becomes constellationComponentList_quotePromo)
 */
const BLOCK_REGEX = /.*_(constellationComponentList_.*)_BlockType/m;

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ConstellationComponentListFragment = graphql`
  fragment ConstellationComponentListFragment on CraftAPI_constellationComponentList_MatrixField {
    __typename
    ...ConstellationEntryFragment
    ...ConstellationExternalLinkFragment
    ...ConstellationQuoteFragment
    ...ConstellationVideoPromoFragment
  }
`;

/**
 * Converts the provided constellation component list data into an array of components. Note: because the provided
 * data is an array, it's expected that the components in the array have a __typename field used to identify the type of
 * component.
 *
 * @param constellationComponentListData the GraphQL response data
 * @returns                              an array of components
 */
const convert = (constellationComponentListData) => {
  return constellationComponentListData?.map((componentData) => {
    const type = componentData?.__typename;
    const componentName = (type || "").match(BLOCK_REGEX)?.[1];

    if (!type) {
      console.warn(
        "Could not convert component. Be sure the GraphQL fragment includes the __typename field"
      );
    } else if (!componentName) {
      console.warn(
        "Could not extract the component name from __typename. Expected something like CraftAPI_constelationComponentList_componentName_BlockType"
      );
    } else {
      return subConverters[componentName]?.(componentData);
    }
  });
};

export { ConstellationComponentListFragment, convert };

import "./search-results-list.scss";

import { SearchResult } from "atoms";
import { NoSearchResults } from "organisms";
import React, { Component } from "react";
import { connectStateResults, Hits, Pagination } from "react-instantsearch-dom";

class SearchResultsListComponent extends Component {
  render() {
    return (
      <>
        <Hits hitComponent={SearchResult} />
        <Pagination />
      </>
    );
  }
}

const SearchResultsList = connectStateResults(({ searchResults, props }) => {
  const noResults = searchResults?.nbHits === 0;

  return noResults ? (
    <NoSearchResults
      searchedTerm={searchResults.query}
      searchPromos={props?.searchPromos?.[0]}
    />
  ) : (
    <SearchResultsListComponent />
  );
});

export default SearchResultsList;

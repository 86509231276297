import { Theme, useOnOutsideEvent } from "assets";
import { CloseButton, Logo, MainNavButton } from "atoms";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { MainNavList } from "molecules";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { SearchWindow } from "../index";
import * as styles from "./mainnav-bar.module.scss";

const MAIN_NAV_ID = "mainNav";

const InternalMainNavBar = ({
  navigationItems,
  isModal,
  modalOnCloseClick,
  theme,
  withSearch,
  pageType,
}) => {
  const mainNavRef = useRef();
  const [activeSubNav, setActiveSubNav] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  useOnOutsideEvent(mainNavRef, () => {
    setActiveSubNav(null);
    setActiveSearch(false);
  });

  const mainNavData = navigationItems?.map((navItem) => ({
    id: navItem.id,
    hasSubNav: Boolean(navItem.subnavigation?.length),
    subnavContent: navItem.subnavigation?.flatMap((subNavItem) => {
      const convertNavItem = (item) => {
        if (item.redirectEntry) {
          // Subnav item is an elevator page - grab the image and text
          // from the redirect page, but keep the elevator uri
          item = {
            ...item.redirectEntry?.[0],
            uri: item.uri,
          };
        }

        const generatedItem = {
          byline: item?.shortDescription,
          img: item?.coverImage?.[0],
          imgAlt: item?.coverImage?.[0]?.altText,
          title: item.title,
          isNew: subNavItem.isNew,
          isPlaceholder: !item?.uri,
        };

        if (item.uri) generatedItem.to = `/${item.uri}`;

        return generatedItem;
      };

      return [
        ...subNavItem.pageLink.map(convertNavItem),
        ...subNavItem.placeholder.map(convertNavItem),
      ];
    }),
    icon: navItem.icon?.[0]?.url,
    isExternal: Boolean(navItem.externalLink),
    isTabbed: navItem.id === activeSubNav?.id,
    label: navItem.linkText,
    pageType: pageType,
    onClick: navItem.subnavigation?.length
      ? () => {
        // Collapse subnav if it's already open
        if (navItem.id === activeSubNav?.id) {
          setActiveSubNav(mobileMainNavData);
        } else {
          // Open sub-navigation
          setActiveSubNav({
            id: navItem.id,
            label: navItem.linkText,
            subNavigationItems: true,
          });
        }
      }
      : undefined,
    to: navItem.subnavigation?.length
      ? undefined
      : navItem.externalLink
        ? navItem.externalLink
        : `/${navItem.linkedContent?.[0]?.uri}`,
  }));

  const mobileMainNavData = {
    id: MAIN_NAV_ID,
    subNavigationItems: mainNavData?.map((navItem) => ({
      icon: navItem.icon,
      title: navItem.label,
      ...navItem,
    })),
  };

  const middleColClassName = classNames("col-xs", styles.middleCol, {
    "hidden-mobile hidden-tablet": !activeSubNav,
  });

  // Show hamburger button for non-desktop and collapsed nav
  const hamburgerClassName = classNames("hidden-desktop", {
    hidden: activeSubNav,
  });

  // Show close button for non-desktop and expanded nav
  const closeClassName = classNames("hidden-desktop", {
    hidden: !activeSubNav,
  });

  const menuToggleOnClick = (e) => {
    setActiveSubNav(mobileMainNavData);
    // Do not close the menu via useOnOutsideEvent
    e.stopPropagation();

    // Focus on first menu item when expanding with keyboard
    if (e?.detail === 0) {
      setTimeout(() => {
        mainNavRef?.current.childNodes[0].childNodes[0].focus();
      }, 0);
    }
  };

  const navContainerClassName = classNames({
    "container-fluid": !isModal,
    [styles.mainNavBar]: true,
    ...Theme.addClass(theme, true),
  });

  function renderSearchAndControlButtons() {
    if (isModal) {
      return <CloseButton onClick={() => modalOnCloseClick()} theme={theme} />;
    } else {
      return (
        <>
          {withSearch && (
            <MainNavButton
              iconClass="icon-search"
              onClick={() => {
                setActiveSearch(true);
                setActiveSubNav(null);
              }}
              text="Search"
            />
          )}
          <MainNavButton
            ariaLabel="Menu Button"
            className={hamburgerClassName}
            iconClass="icon-menu"
            onClick={(e) => menuToggleOnClick(e)}
          />
          <MainNavButton
            ariaLabel="Close Navigation Button"
            className={closeClassName}
            iconClass="icon-close"
            onClick={() => setActiveSubNav(null)}
          />
        </>
      );
    }
  }

  const onCloseClick = () => {
    setActiveSearch(false);
    mainNavRef?.current.childNodes[0].childNodes[0].focus();
  };

  return (
    <nav
      className={navContainerClassName}
      data-testid="mainnav-bar"
      ref={mainNavRef}
    >
      <div className="row">
        <div className="col-xs" data-testid="first-column">
          <Logo className={styles.logo} textPlacement="side" theme={theme} />
        </div>
        <div className={middleColClassName}>
          {mainNavData && <MainNavList data={mainNavData} ref={mainNavRef} />}
        </div>
        <div className={`col-xs ${styles.rightCol}`}>
          {renderSearchAndControlButtons()}
        </div>
      </div>
      {activeSearch && <SearchWindow onCloseClick={onCloseClick} />}
    </nav>
  );
};

InternalMainNavBar.propTypes = {
  isModal: PropTypes.bool,
  modalOnCloseClick: PropTypes.func,
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      externalLink: PropTypes.string,
      id: PropTypes.string.isRequired,
      linkedContent: PropTypes.arrayOf(
        PropTypes.shape({
          uri: PropTypes.string.isRequired,
        })
      ),
      linkText: PropTypes.string.isRequired,
      subnavigation: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  ...Theme.PropType,
  withSearch: PropTypes.bool,
};

InternalMainNavBar.defaultProps = {
  isModal: false,
  theme: Theme.Black,
  withSearch: true,
};

export const SubnavImageFragment = graphql`
  fragment SubnavImageFragment on CraftAPI_image_Asset {
    altText
    url(transform: "subnav")
    imageFile {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`;

export default function MainNavBar(props) {
  let data;

  if (process.env.STORYBOOK) {
    data = [];
  } else {
    data = useStaticQuery(graphql`
      query {
        craftAPI {
          header: globalSet(handle: "header") {
            ... on CraftAPI_header_GlobalSet {
              navigation {
                ... on CraftAPI_navigation_topLevelNavigation_BlockType {
                  id
                  externalLink
                  icon {
                    url
                  }
                  linkedContent {
                    uri
                  }
                  linkText
                  subnavigation {
                    ... on CraftAPI_subnavigation_BlockType {
                      isNew
                      pageLink {
                        id
                        title
                        uri
                        ... on CraftAPI_exhibit_chapter_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_exhibit_cluster_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_exhibit_detail_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_exhibit_exhibit_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_exhibit_pillar_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_resources_resources_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_story_storiesLanding_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_howWeKnow_howWeKnow_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_about_about_Entry {
                          coverImage {
                            ...SubnavImageFragment
                          }
                          shortDescription
                        }
                        ... on CraftAPI_object_elevator_Entry {
                          redirectEntry {
                            id
                            title
                            ... on CraftAPI_exhibit_exhibit_Entry {
                              coverImage {
                                ...SubnavImageFragment
                              }
                              shortDescription
                            }
                          }
                        }
                      }
                      placeholder {
                        ... on CraftAPI_placeholder_placeholderContent_BlockType {
                          id
                          title: placeholderName
                          shortDescription: detailText
                          coverImage: image {
                            ...SubnavImageFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);
  }

  return (
    <InternalMainNavBar
      navigationItems={data?.craftAPI?.header?.navigation || []}
      {...props}
    />
  );
}

// extracted by mini-css-extract-plugin
export var blocks = "story-hero-module--blocks--3S1gm";
export var content = "story-hero-module--content--JZWde";
export var exhibitionsPageHeroWrapper = "story-hero-module--exhibitionsPageHeroWrapper--vF5dS";
export var heroContainer = "story-hero-module--heroContainer--kp52U";
export var icon = "story-hero-module--icon--9xRSV";
export var layer = "story-hero-module--layer--4ScYr";
export var navWrapper = "story-hero-module--navWrapper--MVctB";
export var overlay = "story-hero-module--overlay--RMV3f";
export var scrollContainer = "story-hero-module--scrollContainer--kTqaa";
export var storyHero = "story-hero-module--story-hero--5s-1C";
export var storyHeroWrapper = "story-hero-module--storyHeroWrapper--cSJdw";
export var storyType = "story-hero-module--storyType--Uxq-u";
export var tags = "story-hero-module--tags--yIlsI";
import algoliasearch from "algoliasearch/lite";
import { stringifySearch, Theme } from "assets";
import classNames from "classnames";
import { navigate } from "gatsby";
import { Autocomplete, SubNavModal } from "molecules";
import { MainNavBar } from "organisms";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import {
  Configure,
  connectStateResults,
  InstantSearch,
} from "react-instantsearch-dom";

import * as styles from "./search-form.module.scss";

export const SearchForm = ({
  client,
  isModal,
  hasResults,
  onCloseClick,
  onSuggestionSelected,
  searchTerm,
  onEnter,
}) => {
  const [theme, setTheme] = useState(Theme.Dark);

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (isModal) {
      inputRef.current.input.focus();
    }
  }, []);

  const onSearch = (value) => {
    navigate(stringifySearch(value));
  };

  const setSuggestionSelected = (onSuggestionSelected) => {
    return onSuggestionSelected
      ? onSuggestionSelected
      : (_, { suggestion }) => {
        navigate(stringifySearch(suggestion.title));
      };
  };

  const className = classNames({
    [styles.searchForm]: true,
    [styles.withResults]: hasResults,
  });

  const formVar = (
    <div>
      <div className="col-sm-offset-2 col-sm-8">
        <div className={styles.searchBox} data-testid="search-box">
          <InstantSearch
            indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
            searchClient={isModal ? searchClient : client}
          >
            <Configure hitsPerPage={5} />
            <Autocomplete
              currentRefinement=""
              defaultRefinement={searchTerm}
              hits={[]}
              inputRef={inputRef}
              onSearch={isModal ? onSearch : onEnter}
              onSuggestionSelected={setSuggestionSelected(onSuggestionSelected)}
              onValueChange={(value) => {
                setTheme(value ? Theme.Light : Theme.Dark);
              }}
              searchTerm={searchTerm}
              shouldShowHeading={isModal ? false : true}
            />
          </InstantSearch>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="search-form">
      {/* Modal version when search button is initially clicked */}
      {isModal && (
        <SubNavModal alignToTop={isModal} theme={theme}>
          <MainNavBar
            modalOnCloseClick={onCloseClick}
            navigationItems={[]}
            theme={theme}
            isModal
          />
          {formVar}
        </SubNavModal>
      )}

      {/* Version used on actual search page */}
      {!isModal && <div className={className}>{formVar}</div>}
    </div>
  );
};

SearchForm.propTypes = {
  client: PropTypes.object,
  hasResults: PropTypes.bool,
  isModal: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  searchTerm: PropTypes.string,
};

SearchForm.defaultProps = {
  onCloseClick: () => {},
  isModal: false,
};

const ConnectedSearchForm = connectStateResults(({ searchResults, props }) => {
  const hasResults = searchResults?.nbHits > 0;

  return (
    <SearchForm
      client={props.client}
      hasResults={hasResults}
      isModal={props.isModal}
      onCloseClick={props.onCloseClick}
      onEnter={props.onEnter}
      onSuggestionSelected={props.onSuggestionSelected}
      searchTerm={props.searchTerm}
    />
  );
});

export default ConnectedSearchForm;

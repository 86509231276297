import { isExternalLink } from "assets";
import classNames from "classnames";
import { Link } from "gatsby";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./mainnav-item.module.scss";

const MainNavItem = ({
  hasSubNav,
  icon,
  isTabbed,
  label,
  onClick,
  pageType,
  to,
}) => {
  const className = classNames({
    [styles.mainNavItem]: true,
    [styles.tab]: isTabbed,
  });

  const iconClass = classNames(styles.leftIcon, {
    [styles.active]: pageType === label.toLowerCase(),
  });

  let Item;
  let itemProps = { className };
  const isExternal = to && isExternalLink(to);
  if (to) {
    // Navigation item is a direct link to a page (possibly external)
    Item = isExternal ? "a" : Link;
    itemProps = isExternal ? { href: to, ...itemProps } : { to, ...itemProps };
  } else {
    // Navigation item opens up a sub-navigation menu
    Item = "button";
    itemProps = {
      onClick,
      ...itemProps,
    };
  }

  let rightIcon;
  if (hasSubNav)
    rightIcon = isTabbed ? (
      <i className={`icon-up-carrot ${styles.rightIcon}`} />
    ) : (
      <i className={`icon-down-carrot ${styles.rightIcon}`} />
    );
  else if (isExternal)
    rightIcon = <i className={`icon-external-link ${styles.rightIcon}`} />;

  return (
    <Item data-testid="mainnav-item" {...itemProps}>
      {!isEmpty(icon) && (
        <img alt="" className={iconClass} data-testid="left-icon" src={icon} />
      )}
      {label}
      {rightIcon}
    </Item>
  );
};

MainNavItem.propTypes = {
  hasSubNav: PropTypes.bool,
  icon: PropTypes.string,
  isTabbed: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  to: PropTypes.string,
};

MainNavItem.defaultProps = {
  label: "item label",
  onClick: () => {},
  pageType: "",
};

export default MainNavItem;

// Simple function that gets the strips HTML tags from a string and returns the number of characters
const getCharacterCount = (string) => {
  if (!string) return;
  const regex = "/(<([^>]+)>)/gi";
  const length = string.replace(regex, "").length;

  return length;
};

export default getCharacterCount;

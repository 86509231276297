import PropTypes from "prop-types";
import React from "react";

const rotations = [30, 150, 0, 180, -30, 210];

const ConstellationGraphic = ({ className, numArms }) => (
  <svg
    className={className}
    data-testid="constellation-graphic"
    viewBox="0 0 1500 1000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="circle-gradient" x1="0%" x2="0%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFAE18" stopOpacity="0.4" />
        <stop offset="50%" stopColor="#FFAE18" stopOpacity="0.7" />
        <stop offset="100%" stopColor="#FFAE18" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient id="fade-gradient" x2="1" y2="0">
        <stop offset="0.2" stopColor="white" stopOpacity="0" />
        <stop offset="0.4" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
      <mask id="fade-mask" maskContentUnits="objectBoundingBox">
        <rect fill="url(#fade-gradient)" height="1" width="1" />
      </mask>
    </defs>
    <circle
      cx="750"
      cy="500"
      fill="none"
      r="500"
      stroke="url(#circle-gradient)"
      strokeDasharray="1 12"
      strokeWidth="6"
    />
    {[...Array(Math.min(numArms, 6)).keys()].map((i) => (
      <g
        data-testid="constellation-arm"
        key={i}
        transform={`rotate(${rotations[i]}, 750, 500)`}
      >
        <rect
          height="6"
          mask="url(#fade-mask)"
          stroke="#FFAE18"
          strokeDasharray="1 12"
          strokeWidth="6"
          width="251"
          y="497"
        />
        <circle cx="250" cy="500" fill="#090307" r="24" />
        <g transform={"translate(231, 481)"}>
          <g>
            <animate
              attributeName="opacity"
              begin="0s"
              dur="2s"
              repeatCount="indefinite"
              values="1.0;0.4;1.0"
            />
            <path
              d="M10 3.26475L11.749 8.70026L11.8889 9.1352L12.3093 9.31411L17.4461 11.5L12.3093 13.6859L11.8889 13.8648L11.749 14.2997L10 19.7352L8.25103 14.2997L8.11108 13.8648L7.69065 13.6859L2.55389 11.5L7.69065 9.31411L8.11108 9.1352L8.25103 8.70026L10 3.26475Z"
              fill="#FFAE18"
              stroke="#FFAE18"
              strokeWidth="2"
            />
          </g>
          <g>
            <animate
              attributeName="opacity"
              begin=".75s"
              dur="2s"
              repeatCount="indefinite"
              values="1.0;0.4;1.0"
            />
            <path
              d="M22.5 26.4732L23.2979 29.1272L23.4285 29.5614L23.8411 29.7493L26.5873 31L23.8411 32.2507L23.4285 32.4386L23.2979 32.8728L22.5 35.5268L21.7021 32.8728L21.5715 32.4386L21.1589 32.2507L18.4127 31L21.1589 29.7493L21.5715 29.5614L21.7021 29.1272L22.5 26.4732Z"
              fill="#FFAE18"
              stroke="#FFAE18"
              strokeWidth="2"
            />
          </g>
          <g>
            <animate
              attributeName="opacity"
              begin="1.5s"
              dur="2s"
              repeatCount="indefinite"
              values="1.0;0.4;1.0"
            />
            <path
              d="M30.5 12.1649L30.7666 12.9655L30.9114 13.4002L31.3351 13.5744L32.3702 14L31.3351 14.4256L30.9114 14.5998L30.7666 15.0345L30.5 15.8351L30.2334 15.0345L30.0886 14.5998L29.6649 14.4256L28.6298 14L29.6649 13.5744L30.0886 13.4002L30.2334 12.9655L30.5 12.1649Z"
              fill="#FFAE18"
              stroke="#FFAE18"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    ))}
  </svg>
);

ConstellationGraphic.propTypes = {
  className: PropTypes.string,
  numArms: PropTypes.number,
};

ConstellationGraphic.defaultProps = {
  numArms: 6,
};

export default ConstellationGraphic;

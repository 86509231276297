import { Theme } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import * as styles from "./subnav-modal.module.scss";

const SubNavModal = forwardRef(
  ({ alignToTop, children, onBlur, theme }, ref) => {
    const className = classNames(
      styles.subNavModal,
      styles.subNavFadeIn,
      "container-fluid",
      {
        [styles.alignToTop]: alignToTop,
        ...Theme.addClass(theme, true),
      }
    );

    return (
      <div
        className={className}
        data-testid="subnav-modal"
        onBlur={onBlur}
        onMouseDown={(e) => {
          // Prevents the submodal from hiding when clicked (e.g. dragging the scrollbar)
          e.preventDefault();
        }}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

SubNavModal.displayName = "SubNavModal";

SubNavModal.propTypes = {
  alignToTop: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onBlur: PropTypes.func,
  ...Theme.PropType,
};

SubNavModal.defaultProps = {
  alignToTop: false,
  theme: Theme.Dark,
};

export default SubNavModal;

import { useLocation } from "@reach/router";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./deeplink-container.module.scss";

const DeeplinkContainer = ({ title, ariaLabel }) => {
  const url = useLocation();

  const copyUrl = () => {
    setTimeout(() => {
      // We need this check or gatsby build will fail with a navigator undefined error
      typeof window !== "undefined" &&
        navigator.clipboard
          .writeText(url.href)
          .then(() => {})
          .catch((error) => {
            console.error(url.href, error);
          });
    }, 100);
  };

  return (
    title &&
    url.pathname !== "/" && (
      <a
        aria-label={`Link to ${ariaLabel}`}
        className={styles.deepLinkCtn}
        href={`#${title}`}
      >
        <i
          aria-hidden="true"
          className={classNames(styles.linkIcon, "icon-link")}
          onClick={copyUrl()}
        />
      </a>
    )
  );
};

DeeplinkContainer.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DeeplinkContainer;

import {
  NMAAHCPropTypes,
  Theme,
  ThemeContext,
  useWindowDimensions,
} from "assets";
import { ArrowOverlay, FormattedText, NMAAHCModalWindow } from "atoms";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { graphql } from "gatsby";
import { TimelineNavigation, TimelinePromo } from "molecules";
import { TimelineIntro, TimelineSlide } from "organisms";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";

import * as styles from "./timeline.module.scss";

const carouselProps = (isMobile) => {
  return {
    draggable: isMobile,
    dragFree: true,
    align: "start",
    axis: isMobile ? "y" : "x",
    containScroll: "trimSnaps",
  };
};

const Timeline = ({
  description,
  events,
  image,
  intro,
  timelineTitle,
  timelineGranularity,
  promoBackgroundImage,
  promoBackgroundCover,
  promoFontColor,
  backgroundImage,
  backgroundCover,
  fontColor,
}) => {
  const { width } = useWindowDimensions();
  const [showModal, setShowModal] = useState(false);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);

  const [emblaRef, emblaApi] = useEmblaCarousel(carouselProps(isMobile));

  const themeType = {
    theme: fontColor === "light" ? Theme.Dark : Theme.Light,
  };
  const { fontType } = useContext(ThemeContext);

  const slides = intro
    .map((i) => (
      <TimelineIntro
        description={i.description}
        image={image?.[0]}
        key="intro"
        onNext={emblaApi?.scrollNext}
        title={timelineTitle}
      />
    ))
    .concat(
      events.map((e) => (
        <TimelineSlide key={e?.eventTitle} theme={themeType.theme} {...e} />
      ))
    );
  if (isMobile) {
    // Theres a bug where can't calculate where our slides end with the timeline content for some reason, such that the last slide gets cut off
    // Adding a div of this, lets it calculate correctly
    slides.push(<div className={styles.hidden}>placeholder</div>);
  }

  const onSelect = useCallback(() => {
    setNextBtnEnabled(
      emblaApi?.selectedScrollSnap() !== 0 && emblaApi?.canScrollNext()
    );
    setPrevBtnEnabled(emblaApi?.canScrollPrev());
    // Because we have a placeholder div at the end of our slides for mobile, we have an extra entry,
    // to make the navigation select the correct entry when we're on this placeholder entry I've added some defaulting
    if (emblaApi?.selectedScrollSnap() > events.length) {
      setSelectedIndex(events.length);
    } else {
      setSelectedIndex(emblaApi?.selectedScrollSnap());
    }
  }, [emblaApi]);

  useEffect(() => {
    onSelect();
    emblaApi?.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const setSelectedSlideByIndex = (index) => {
    emblaApi.scrollTo(index);
  };

  const navigationClass = classNames(
    styles.navigationContainer,
    Theme.addClass(themeType.theme, true),
    {
      [styles.transparentBg]: backgroundImage,
    }
  );
  const titleClass = classNames(
    styles.timelineTitleContainer,
    Theme.addClass(themeType.theme, true),
    {
      [styles.transparentBg]: backgroundImage,
    }
  );

  useEffect(() => {
    const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);

    if (showModal && !isMobile) {
      const twoEvents = Array.from(
        document.getElementsByClassName("duel-events")
      );

      twoEvents.forEach((el) => {
        const items = el.children[1].children;
        const child1Height = items[0].firstChild.clientHeight;
        const child2Height = items[1].firstChild.clientHeight;

        if (child1Height > child2Height) {
          items[1].firstChild.setAttribute(
            "style",
            "height:" + child1Height + "px;"
          );
        } else if (child1Height < child2Height) {
          items[0].firstChild.setAttribute(
            "style",
            "height:" + child2Height + "px;"
          );
        }
        el.classList.remove("duel-events");
      });
    }
  }, [slides]);

  return (
    <div data-testid="timeline">
      <TimelinePromo
        backgroundCover={promoBackgroundCover}
        backgroundImage={promoBackgroundImage?.[0]?.url}
        description={description}
        fontColor={promoFontColor}
        image={image?.[0]}
        onLaunch={() => setShowModal(true)}
        title={timelineTitle}
      />
      <NMAAHCModalWindow
        backgroundCover={backgroundCover}
        backgroundImage={backgroundImage}
        fontColor={fontColor}
        label={timelineTitle}
        onClose={() => setShowModal(false)}
        showModal={showModal}
        theme={themeType.theme}
      >
        <div className={styles.timelineContainer}>
          <div className={navigationClass}>
            <TimelineNavigation
              activeEvent={
                selectedIndex === 0 ? "intro" : events[selectedIndex - 1]
              }
              events={events}
              granularity={timelineGranularity}
              setSelectedSlideByIndex={setSelectedSlideByIndex}
              theme={themeType.theme}
            />
          </div>
          <div className={titleClass}>
            <FormattedText
              className={`large-font ${fontType} ${styles.timelineTitle}`}
              text={timelineTitle}
            />
          </div>
          <div
            className={classNames("embla", styles.outerSlideContainer)}
            ref={emblaRef}
          >
            <div
              className={classNames(
                "embla__container",
                styles.innerSlideContainer
              )}
              data-testid="slide-container"
            >
              {slides}
            </div>
            <ArrowOverlay
              buttonColor={NMAAHCPropTypes.ARROW_OVERLAY_ORANGE_BUTTON}
              hasNext={nextBtnEnabled}
              hasPrevious={prevBtnEnabled}
              onNextClick={() => emblaApi?.scrollNext()}
              onPreviousClick={() => emblaApi?.scrollPrev()}
            />
          </div>
        </div>
      </NMAAHCModalWindow>
    </div>
  );
};

Timeline.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImage: PropTypes.string,
  description: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      callout: PropTypes.string,
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
      event2Date: PropTypes.string,
      event2Description: PropTypes.string,
      event2Image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
      event2ImageCropStylingTimeline: NMAAHCPropTypes.ImageObjectFit,
      event2ImagePositionOverride: PropTypes.string,
      event2TimeGranularity: PropTypes.oneOf(["day", "month", "year"]),
      event2Title: PropTypes.string,
      event2Video: PropTypes.arrayOf(NMAAHCPropTypes.EmbeddedVideo),
      eventTitle: PropTypes.string.isRequired,
      image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
      imageCropStylingTimeline: NMAAHCPropTypes.ImageObjectFit,
      imagePositionOverride: PropTypes.string,
      referenceQuote: PropTypes.arrayOf(PropTypes.shape({})),
      timeGranularity: PropTypes.oneOf(["day", "month", "year"]).isRequired,
      video: PropTypes.arrayOf(NMAAHCPropTypes.EmbeddedVideo),
    })
  ),
  fontColor: PropTypes.string,
  image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  intro: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
    })
  ),
  promoBackgroundCover: PropTypes.bool,
  promoBackgroundImage: PropTypes.string,
  promoFontColor: PropTypes.string,
  timelineGranularity: PropTypes.oneOf(["decade", "year"]).isRequired,
  timelineTitle: PropTypes.string.isRequired,
};

Timeline.defaultProps = {
  events: [],
  intro: [],
};
const TimelineVideoUrlFragment = graphql`
  fragment TimelineVideoUrlFragment on CraftAPI_video_Asset {
    embeddedAsset {
      ... on CraftAPI_EmbeddedAsset {
        url
        code
      }
    }
  }
`;

const TimelineFragment = graphql`
  fragment TimelineFragment on CraftAPI_componentList_timeline_BlockType {
    id
    description
    events {
      ... on CraftAPI_events_BlockType {
        description
        eventTitle
        image {
          ... on CraftAPI_image_Asset {
            ...ImageMetadataFragment
          }
        }
        imageCropStylingTimeline
        imagePositionOverride
        video {
          ... on CraftAPI_video_Asset {
            ...TimelineVideoUrlFragment
          }
        }
        thumbnail: image {
          ... on CraftAPI_image_Asset {
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(height: 75)
              }
            }
            url
          }
        }
        callout
        date
        timeGranularity
        event2Title
        event2Date
        event2TimeGranularity
        event2Image {
          ... on CraftAPI_image_Asset {
            ...ImageMetadataFragment
          }
        }
        event2ImageCropStylingTimeline
        event2ImagePositionOverride
        event2Video {
          ... on CraftAPI_video_Asset {
            ...TimelineVideoUrlFragment
          }
        }
        event2Description
        referenceQuote {
          ... on CraftAPI_object_quote_Entry {
            title
            quoteAuthor
            quoteYear
            transcription
            audioAsset {
              ...AudioAssetFragment
            }
          }
        }
      }
    }
    image {
      ... on CraftAPI_image_Asset {
        ...ImageMetadataFragment
      }
    }
    intro {
      ... on CraftAPI_intro_BlockType {
        description
      }
    }
    timelineGranularity
    timelineTitle
    promoBackgroundImage {
      url
    }
    promoBackgroundCover
    promoFontColor
    backgroundImage {
      url
    }
    backgroundCover
    fontColor
  }
`;

const convert = (timelineData) => {
  // We want to slightly modify dates that are the same to be distinct so that timeline dates can matched for navigating highlighting individually.
  const processEvents = (events) => {
    let processedEvents = JSON.parse(JSON.stringify(events));
    events?.forEach((baseEvent, baseIndex) => {
      events?.forEach((targetEvent, targetIndex) => {
        if (baseIndex != targetIndex && baseEvent.date === targetEvent.date) {
          let newDate = new Date(events[baseIndex]?.date);
          newDate.setSeconds(newDate.getSeconds() + baseIndex);
          let newObject = Object.assign({}, events[baseIndex]);
          newObject.date = newDate.toISOString(); // This is a slightly different format than the ones exported from craft however this isn't an issue and quite complicated to solve at the moment
          processedEvents?.splice(baseIndex, 1, newObject);
        }
      });
    });
    return processedEvents;
  };

  let processedEvents = processEvents(timelineData.events);

  return (
    <Timeline
      key={timelineData.id}
      {...timelineData}
      backgroundImage={timelineData.backgroundImage?.[0]?.url}
      events={processedEvents}
    />
  );
};

export {
  carouselProps,
  convert,
  Timeline as default,
  TimelineFragment,
  TimelineVideoUrlFragment,
};

const FIELD_REGEX = /image/i;

/**
 * Craft CMS will always return assets as an array, even when the asset
 * selection is limited to a single asset. This helper function can collapse an
 * array of a single image field, i.e:
 *
 *    image: [imageData] becomes
 *    image: imageData
 *
 * NOTE: This function is not recursive/iterative, meaning it will only flatten
 * fields at the top level and will not dive deeper into the provided object
 *
 * @param object  the object to flatten
 * @returns       the modified object with image fields flattened
 */
const flattenImageFields = (object) => {
  // return bad input as-is
  if (!object || typeof object !== "object" || Array.isArray(object))
    return object;
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      // the field name contains the word "image" and the value is a single element array
      // flatten the array and return just the value
      if (key.match(FIELD_REGEX) && value?.length === 1) {
        return [key, value[0]];
      }
      // otherwise return key+value as is
      return [key, value];
    })
  );
};

export default flattenImageFields;

export const pillarListingAugmentor = (exhibitsData, pillarsData) => {
  exhibitsData.componentList
    .filter((c) => {
      return c.__typename === "CraftAPI_componentList_pillarListing_BlockType";
    })
    .map((c) => {
      Object.assign(c, {
        pillars: pillarsData,
      });
    });

  return exhibitsData;
};

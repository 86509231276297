import "moment-duration-format";

import { Theme, useFocus } from "assets";
import { CloseButton, FormattedText } from "atoms";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import * as styles from "./audio-player.module.scss";
import useAudioPlayer from "./useAudioPlayer";

function formatDuration(duration) {
  return moment.duration(duration, "seconds").format("mm:ss", { trim: false });
}

const AudioPlayer = ({
  autoPlay,
  title,
  onClose,
  source,
  visible,
  caption,
  credit,
  transcription,
  copyright,
  creditLink,
}) => {
  const [buttonRef, setFocus] = useFocus();
  const audioRef = useRef();

  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer(audioRef);

  const [disableTransition, setDisableTransition] = useState(false);
  // start/stop audio on visibility and source change
  useEffect(() => {
    setPlaying(visible && autoPlay);
    if (visible) {
      // set the focus on the play button, but wait until the animation has occurred to avoid scrolling
      setTimeout(setFocus, 500);
      // set delay to add class to disable transition animation. Animation will happen on player open but not on toggling captions
      setTimeout(() => {
        setDisableTransition(true);
      }, 500);
      if (audioRef?.current?.textTracks?.[0]) {
        audioRef.current.textTracks[0].mode =
          audioRef.current.textTracks[0].mode == "disabled"
            ? "hidden"
            : audioRef.current.textTracks[0].mode;
      }
    } else {
      setTimeout(setDisableTransition(false));
    }
  }, [visible, source]);

  const [closedCaptionHidden, setClosedCaptionHidden] = useState(true);
  const [transcriptionHidden, setTranscriptionHidden] = useState(true);
  const [cueText, setCueText] = useState("");
  useEffect(() => {
    if (audioRef?.current?.textTracks?.[0]) {
      const track = audioRef.current.textTracks[0];
      if (closedCaptionHidden) {
        track.mode = "hidden";
      } else {
        track.mode = "showing";
        track.oncuechange = (event) => {
          displayCaptions(track.activeCues);
        };
      }
    }
  }, [closedCaptionHidden]);

  // Sets text for the current captions cue
  const displayCaptions = (cue) => {
    const text = cue.length ? cue[0].text : "";
    setCueText(text.replace(/(<([^>]+)>)/gi, ""));
  };

  const [muted, setMuted] = useState(false);
  const muteClass = classNames({
    "fa icon-mute": !muted,
    "fa icon-unmute": muted,
  });

  const [showCopyright, setShowCopyright] = useState(false);
  const copyrightClass = classNames("row", styles.copyright);

  const copyrightBtnClass = classNames(styles.copyrightButton, {
    [styles.activeCopyrightBtn]: showCopyright,
  });

  const playerClassname = classNames(
    styles.player,
    { [styles.visible]: visible },
    { [styles.disableTransition]: disableTransition },
    { "cc-active": !closedCaptionHidden },
    { "copyright-active": showCopyright }
  );

  const nameboxClassname = classNames(styles.nameBox, { "has-credit": credit });

  const buttonClassname = classNames("fa", {
    "icon-pause": playing,
    "icon-play": !playing,
  });

  const captions = caption ? <track src={caption} /> : undefined;

  const currentPercentage = duration ? `${(curTime / duration) * 100}%` : "0%";
  const trackStyling = `
    linear-gradient(to right, #ffae18 0% ${currentPercentage}, #090307 ${currentPercentage})
    `; //brand yellow and black
  const trackBorderStyling = `
    linear-gradient(to right, #ffae18 0% ${currentPercentage}, #fff ${currentPercentage})
    `; //brand yellow and white

  return (
    <section
      aria-hidden={!visible}
      className={playerClassname}
      data-testid="audio-player"
    >
      <video
        crossOrigin={captions ? "anonymous" : undefined}
        muted={muted}
        ref={audioRef}
        src={source}
      >
        {captions}
      </video>
      {caption && (
        <div className={styles.captions}>
          <div className={styles.captionText}>{cueText}</div>
        </div>
      )}
      {!transcriptionHidden && (
        <div className={classNames("row", styles.transcription)}>
          <div className="col-xs-offset-1 col-xs-10">
            <div className={styles.contentContainer}>
              <FormattedText text={transcription} />
            </div>
          </div>
          <div className={classNames("col-xs-1", styles.closeButton)}>
            <CloseButton
              onClick={() => setTranscriptionHidden(true)}
              theme={Theme.Light}
            />
          </div>
        </div>
      )}
      <div className="row middle-xs">
        <div className={styles.wrapper}>
          <div className={styles.playButtonBox}>
            <button
              aria-label={playing ? `pause ${title}` : `play ${title}`}
              className={styles.button}
              data-testid="play-pause"
              onClick={() => setPlaying(!playing)}
              ref={buttonRef}
            >
              <i className={buttonClassname} />
            </button>
          </div>
          <div className={nameboxClassname} data-testid="name">
            <p>
              <span className={styles.trackTitle}>
                {title || "Untitled Track"}
              </span>
            </p>
          </div>
          <div className={styles.timeBox}>
            <div className={styles.timeDisplay}>
              <div className={styles.currentTime}>
                <span className={"visually-hidden"}>Current track time: </span>
                {formatDuration(curTime)}
              </div>
              <div className={styles.progressBar}>
                <input
                  className="progress"
                  max={duration ? duration : `${duration}`}
                  min="0"
                  onChange={(e) => setClickedTime(e.target.value)}
                  step="0.1"
                  style={{
                    background: trackStyling,
                    borderImageSource: trackBorderStyling,
                  }}
                  type="range"
                  value={curTime}
                />
              </div>
              <div className={styles.endTime}>
                <span className={"visually-hidden"}>Track end time: </span>
                {formatDuration(duration)}
              </div>
            </div>
          </div>
          <div
            className={classNames(styles.controlsBox, {
              [styles.controlsBoxWithCopyright]: showCopyright,
            })}
          >
            <div className={styles.rightControls}>
              <button
                className={styles.muteButton}
                onClick={() => setMuted((prev) => !prev)}
              >
                <i className={muteClass} />
              </button>
              <button
                className={styles.restartButton}
                onClick={() => {
                  setPlaying(false);
                  setClickedTime("0");
                }}
              >
                <i className="fa icon-restart" />
              </button>
            </div>
            <div className={styles.leftControls}>
              {caption && (
                <button
                  className={styles.ccButton}
                  onClick={() => {
                    setClosedCaptionHidden(!closedCaptionHidden);
                    setShowCopyright(() => false);
                  }}
                >
                  <i className="fa icon-cc-button" />
                </button>
              )}
              {copyright && (
                <button
                  className={copyrightBtnClass}
                  onClick={() => setShowCopyright(!showCopyright)}
                >
                  <i className="fa icon-copyright" />
                </button>
              )}
              {transcription && (
                <button
                  className={classNames(styles.transcriptButton, {
                    [styles.tabbed]: !transcriptionHidden,
                  })}
                  data-testid="transcript-button"
                  onClick={() => setTranscriptionHidden(!transcriptionHidden)}
                >
                  Transcript
                </button>
              )}
            </div>
          </div>
          <div className={styles.closeBox} data-testid="close">
            <CloseButton
              onClick={() => {
                setPlaying(false);
                onClose();
              }}
              theme={Theme.Dark}
            />
          </div>
        </div>
      </div>
      {showCopyright && (
        <div className={copyrightClass}>
          © {copyright}
          {credit && <a href={creditLink}>{credit}</a>}
          <button
            className={styles.copyCarrot}
            onClick={() => setShowCopyright(!showCopyright)}
          >
            <i className="icon-up-carrot" />
          </button>
        </div>
      )}
    </section>
  );
};

AudioPlayer.propTypes = {
  autoPlay: PropTypes.bool,
  caption: PropTypes.string,
  copyright: PropTypes.string,
  credit: PropTypes.string,
  creditLink: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  source: PropTypes.string,
  title: PropTypes.string,
  transcription: PropTypes.string,
  visible: PropTypes.bool,
};

AudioPlayer.defaultProps = {
  autoPlay: true,
  playOnOpen: false,
  visible: false,
};

export default AudioPlayer;

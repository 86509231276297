import {
  NMAAHCPropTypes,
  Theme,
  ThemeContext,
  useScrollObserver,
} from "assets";
import { FormattedText, Listen } from "atoms";
import classNames from "classnames";
import { ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./scattered-hero.module.scss";

const ScatteredHero = ({
  audioAsset,
  images,
  shortDescription,
  title,
  longDescription,
}) => {
  const className = classNames(styles.scatteredHero, "row center-xs");
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref, { endThreshold: 0.3 });
  const { theme } = useContext(ThemeContext);
  const scatteredClasses = classNames(styles.scatteredCtn, scrolledClasses);
  const fadeInTitleClasses = classNames(
    styles.heroTextContent,
    scatteredClasses
  );

  return (
    <section className={className} data-testid="scattered-hero">
      <div className={styles.outerCtn}>
        <div className={scatteredClasses} ref={ref}>
          <div className={styles.scatteredImgs}>
            {images.map((img, ind) => (
              <div className={styles.scatteredImg} key={ind}>
                <ExpandableImage
                  galleryStartIndex={ind}
                  image={img}
                  withIcon={false}
                  modalCaptionOnly
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.ctnText}>
        <div className={fadeInTitleClasses}>
          {audioAsset?.title && audioAsset?.url && (
            <Listen
              audioAsset={audioAsset}
              data-testid="listen"
              theme={Theme.Subtle}
            />
          )}
          <h1 data-testid="title">{title}</h1>
          {shortDescription && (
            <FormattedText
              className={classNames(styles.shortDescription, "large-font")}
              text={shortDescription}
              theme={theme}
            />
          )}
        </div>
        {longDescription && (
          <FormattedText
            className={"large-font"}
            text={longDescription}
            theme={theme}
          />
        )}
      </div>
    </section>
  );
};

ScatteredHero.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  images: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  longDescription: PropTypes.string,
  shortDescription: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ScatteredHero;

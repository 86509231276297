import { Theme } from "assets";
import { Scroller } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import * as styles from "./timeline-navigation.module.scss";

export const GRANULARITY_CENTURY = "century";
export const GRANULARITY_DECADE = "decade";
export const GRANULARITY_YEAR = "year";

const TimelineNavigation = ({
  activeEvent,
  events,
  granularity,
  isConstellation,
  setSelectedSlideByIndex,
  theme,
}) => {
  useEffect(() => {}, [activeEvent]);

  const timelineRef = useRef();

  let refinedMappleObjectArray = createMappableEventList(granularity, events);

  const getParentStylingBasedOnChildren = (mappableObject) =>
    classNames(styles.innerNumberStyling, {
      [styles.highlitGreyPill]:
        mappableObject.children.some(
          (child) => child?.date === activeEvent?.date
        ) &&
        mappableObject.children.length > 1 &&
        !isConstellation,
      [styles.highlitOrangePill]:
        (mappableObject.children.length <= 1 &&
          mappableObject.date === activeEvent?.date) ||
        (mappableObject.children.some(
          (child) => child?.date === activeEvent?.date
        ) &&
          mappableObject.children.length > 1 &&
          isConstellation),
      [styles.clearPill]: mappableObject.date !== activeEvent?.date,
      [styles.dark]: theme === Theme.Dark,
    });

  const getSubnavStyling = (children) =>
    classNames({
      [styles.childSubnavContainer]: true,
      [styles.subnavHidden]: !showSubnavChildren(children),
    });

  const showSubnavChildren = (children) => {
    if (children.length <= 1) {
      return false;
    }
    return children.some((childEvent) => {
      if (granularity === GRANULARITY_YEAR) {
        return (
          new Date(activeEvent?.date).toLocaleString("default", {
            month: "long",
          }) === childEvent.displayTitle &&
          new Date(activeEvent?.date).getFullYear() ===
            new Date(childEvent?.date).getFullYear()
        );
      } else {
        return (
          new Date(activeEvent?.date).getFullYear() === childEvent.displayTitle
        );
      }
    });
  };

  const activeNav = (mappedActiveEvent, child, index, array) => {
    if (mappedActiveEvent?.date === child.date) return true;
    for (let i = index + 1; i <= array.length; i++) {
      if (
        array[index]?.displayTitle === array[i]?.displayTitle &&
        activeEvent?.date == array[i]?.date
      )
        return true;
      else if (array[index]?.displayTitle !== array[i]?.displayTitle) break;
    }
    return false;
  };

  const timelineButtonRefs = [];
  const setAriaExpanded = (e) => {
    timelineButtonRefs.forEach((item) =>
      item.current.setAttribute("aria-expanded", false)
    );
    e?.target?.setAttribute("aria-expanded", true);
  };

  return (
    <div
      className={classNames(styles.timelineNavigationContainer, {
        [styles.constellation]: isConstellation,
      })}
      data-testid="timeline-navigation"
    >
      <div className={styles.listUlWrapper} ref={timelineRef}>
        <ul className={styles.listUl}>
          {!isConstellation && (
            <li className={styles.listElementLi} key={"intro"}>
              <div
                className={classNames({
                  [styles.highlitOrangePill]: activeEvent === "intro",
                  [styles.innerNumberStyling]: activeEvent !== "intro",
                  [styles.clearPill]: activeEvent !== "intro",
                  [styles.dark]: theme === Theme.Dark,
                })}
                onClick={() => {
                  setSelectedSlideByIndex(0);
                  setAriaExpanded(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSelectedSlideByIndex(0);
                    setAriaExpanded(false);
                  }
                }}
                role="button"
                tabIndex="0"
              >
                Overview
              </div>
              <ul
                className={`${styles.childSubnavContainer} ${styles.subnavHidden}`}
                data-testid="timeline-navigation-subnav"
              >
                {" "}
                {/* intro will never have children but for spacing purposes we need to pretend it does (we could also manually enter numbers but that would easily break if we ever change component spacing) */}
                <li className={styles.listElementLi}>
                  <i className={`icon-forward ${styles.rightArrow}`} />
                </li>
                <li className={styles.listElementLi}>
                  <div className={styles.innerNumberStyling}>
                    dont look at me
                  </div>
                </li>
              </ul>
            </li>
          )}
          {refinedMappleObjectArray.map((mappableObject, index) => {
            const timelineButtonRef = useRef();
            timelineButtonRefs.push(timelineButtonRef);
            return (
              <li
                className={styles.listElementLi}
                key={JSON.stringify(mappableObject)}
              >
                <div
                  className={getParentStylingBasedOnChildren(mappableObject)}
                  onClick={(e) => {
                    setSelectedSlideByIndex(mappableObject.slideIndex);
                    setAriaExpanded(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSelectedSlideByIndex(mappableObject.slideIndex);
                      setAriaExpanded(e);
                    }
                  }}
                  ref={timelineButtonRef}
                  role="button"
                  tabIndex="0"
                >
                  {mappableObject.displayTitle}
                </div>
                <ul
                  className={getSubnavStyling(mappableObject.children)}
                  data-testid="timeline-navigation-subnav"
                >
                  <li className={styles.listElementLi}>
                    <i className={`icon-forward ${styles.rightArrow}`} />
                  </li>
                  {mappableObject.children.map((child, index, array) => {
                    return (
                      <li
                        className={classNames(styles.listElementLi, {
                          [styles.hidden]:
                            child.displayTitle ===
                            array[index - 1]?.displayTitle,
                        })}
                        key={JSON.stringify(child)}
                      >
                        <div
                          className={classNames(
                            activeNav(activeEvent, child, index, array)
                              ? styles.highlitOrangePill
                              : styles.innerNumberStyling,
                            {
                              [styles.dark]: theme === Theme.Dark,
                            }
                          )}
                          onClick={() =>
                            setSelectedSlideByIndex(child.slideIndex)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setSelectedSlideByIndex(child.slideIndex);
                            }
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          {child.displayTitle}
                        </div>
                      </li>
                    );
                    // }
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.scrollWrapperChildren}>
        <Scroller
          ref={timelineRef}
          scrollBarColor={theme === Theme.Dark ? "light" : "dark"}
        />
      </div>
    </div>
  );
};

/* Make the data fit the following format
  [
    {
      displayTitle: "1775",
      date: "",
      children: [
        {
          displayTitle: "September",
          date: "",
        }
      ]
    }
  ]
*/
/* Make the data fit the following format
  [
    {
      displayTitle: "1770",
      date: "",
      children: [
        {
          displayTitle: "1771",
          date: "",
        }
      ]
    }
  ]
*/
const createMappableEventList = (granularity, allEvents) => {
  let refinedMappleObjectArray = [];
  const formatChildTitle = (date) =>
    granularity === GRANULARITY_YEAR
      ? new Date(date).toLocaleString("default", { month: "long" })
      : new Date(date).getFullYear();

  const formatParentTitle = (date) => {
    if (new Date(date) == "Invalid Date" && isNaN(new Date(date))) {
      return "Present Day";
    } else if (granularity === GRANULARITY_CENTURY) {
      return Math.floor(new Date(date).getUTCFullYear() / 100) * 100 + "s";
    } else if (granularity === GRANULARITY_YEAR) {
      return new Date(date).getUTCFullYear();
    }
    return Math.floor(new Date(date).getUTCFullYear() / 10) * 10;
  };

  allEvents.forEach((event, i) => {
    const child = {
      displayTitle: formatChildTitle(event.date),
      date: event.date,
      slideIndex: i + 1,
    };
    const parentTitle = formatParentTitle(event.date);

    let parent = refinedMappleObjectArray.find(
      (p) => p.displayTitle === parentTitle
    );
    if (!parent) {
      parent = {
        displayTitle: formatParentTitle(event.date),
        date: event.date,
        children: [],
        slideIndex: i + 1,
      };
      refinedMappleObjectArray.push(parent);
    }

    parent.children.push(child);
  });

  return refinedMappleObjectArray;
};

TimelineNavigation.propTypes = {
  activeEvent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      date: PropTypes.string.isRequired,
    }),
  ]),
  events: PropTypes.arrayOf(PropTypes.object),
  granularity: PropTypes.string,
  isConstellation: PropTypes.bool,
  setSelectedSlideByIndex: PropTypes.func,
  theme: PropTypes.string,
};

TimelineNavigation.defaultProps = {
  isConstellation: false,
};

export { createMappableEventList, TimelineNavigation as default };

import { FormattedText } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./focusable-iframe.module.scss";

const FocusableIframe = ({ caption, height, src, title }) => {
  return (
    <div className={styles.container} data-testid="focusable-iframe">
      <div className={styles.iframeContainer} style={{ height }}>
        <iframe
          allow="xr; xr-spatial-tracking; fullscreen"
          height="100%"
          src={src}
          title={title}
          width="100%"
        />
      </div>
      {caption && (
        <FormattedText
          className={classNames("caption", styles.caption)}
          text={caption}
        />
      )}
    </div>
  );
};

FocusableIframe.propTypes = {
  caption: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FocusableIframe;

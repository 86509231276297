import { Theme } from "assets";
import * as NMAAHCPropTypes from "assets/js/nmaahc-proptypes/nmaahc-proptypes";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./community-curation.module.scss";

const ACTION_TEXT = "Share Your Story";

const CommunityCuration = ({
  title,
  description,
  link,
  images,
  listOfSubmissions,
}) => {
  const componentClass = classNames(
    Theme.addClass(Theme.Dark, true, true),
    styles.container
  );

  return (
    <div className={componentClass} data-testid="community-curation">
      <h2 data-testid="community-curation-title">{title}</h2>
      <FormattedText text={description} theme={Theme.Dark} />
      <ActionButton
        data-testid="action-button"
        href={link}
        icon="leave-arrow"
        text={ACTION_TEXT}
        textPlacement="right"
      />
      {listOfSubmissions &&
        listOfSubmissions.map((sub) => {
          /* Submission Cards go here */
        })}
    </div>
  );
};

CommunityCuration.propTypes = {
  description: PropTypes.string,
  images: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  link: PropTypes.string,
  listOfSubmissions: PropTypes.arrayOf(
    PropTypes.shape({
      submissionText: PropTypes.string,
      submissionTitle: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

CommunityCuration.defaultProps = {
  link: "",
  images: [],
  listOfSubmissions: [],
};

const CommunityCurationFragment = graphql`
  fragment CommunityCurationFragment on CraftAPI_componentList_communityCuration_BlockType {
    id
    communityCurationTitle
    communityCurationDescription
    communityCurationLink
    images {
      ... on CraftAPI_image_Asset {
        altText
      }
    }
    listOfSubmissions {
      ... on CraftAPI_listOfSubmissions_BlockType {
        submissionTitle
        submissionText
      }
    }
  }
`;

const convert = (data) => {
  return (
    <CommunityCuration
      description={data?.communityCurationDescription}
      images={data?.images}
      link={data?.communityCurationLink}
      listOfSubmissions={data?.listOfSubmissions}
      title={data?.communityCurationTitle}
    />
  );
};

export { CommunityCurationFragment, convert, CommunityCuration as default };

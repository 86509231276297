import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import * as styles from "./cluster.module.scss";

const Cluster = forwardRef(({ children }, ref) => (
  <section
    className={styles.cluster}
    data-testid="cluster"
    id="section-start"
    ref={ref}
  >
    {children}
  </section>
));

Cluster.displayName = "Cluster";

Cluster.propTypes = {
  children: PropTypes.node,
};

export default Cluster;

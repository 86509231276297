import queryString from "query-string";

export const YOUTUBE_URL = /youtu(\.be)?(be\.com)?/gm;
export const VIMEO_URL = /.*vimeo\.com.*/gm;

export const isEmbedded = (src) =>
  src?.match(YOUTUBE_URL) || src?.match(VIMEO_URL);

export const getValidSource = (video) => {
  const src = video?.embeddedAsset?.url || video?.url;
  // Patch in a new source if youtube url is undefined
  if (src?.includes("undefined") && src.match(YOUTUBE_URL)) {
    return video?.embeddedAsset?.code;
  }
  return src;
};

export const getVideoId = (src) => {
  const isYouTubeUrl = src?.match(YOUTUBE_URL);
  const isShorthandUrl = src?.includes(".be");

  // Patch for Embedded Assets extension bug
  if (src.includes("iframe")) return _patchedVideoIdExtraction(src);

  // selects "vimeo.com/.../[id]
  if (src.match(VIMEO_URL)) {
    const split = src.split("/");
    return split[split.length - 1];
  }
  // selects "youtu.be/[id]"
  if (isYouTubeUrl && isShorthandUrl) return src?.split(".be/")[1];
  // selects "youtube.com/watch?v=[id]"
  if (isYouTubeUrl && !isShorthandUrl)
    return queryString.parse(src?.split("?")[1]).v;
  return src;
};

const _patchedVideoIdExtraction = (srcString) => {
  // gets value in <iframe src="..."/> from returned embed code (we use this
  // to build our own iframes)
  const srcInEmbedCode = /(?<=\/embed\/)[^/?]+/gm;
  return srcInEmbedCode.exec(srcString)[0];
};

export const getFrameParams = (link, params) => {
  const id = getVideoId(link);
  if (link.match(YOUTUBE_URL)) {
    return `https://www.youtube-nocookie.com/embed/${id}?${queryString.stringify(
      params
    )}`;
  }
  if (link.match(VIMEO_URL)) {
    return `https://player.vimeo.com/video/${id}?${queryString.stringify(
      params
    )}&app_id=122963`;
  }
  return undefined; // for any unsupported platforms
};

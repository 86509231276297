import "./search-suggestions-list.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./search-suggestions-list.module.scss";

const SearchSuggestionsList = ({
  suggestionHighlighted,
  children,
  containerProps,
  suggestionsTitle,
}) => {
  const searchSuggestionsListClassName = classNames({
    [containerProps?.className]: true,
    [styles.commonSearches]: true,
    [styles.darkHover]:
      suggestionsTitle === "suggested searches" && suggestionHighlighted,
  });

  return (
    <section
      data-testid="search-suggestions-list"
      {...containerProps}
      className={searchSuggestionsListClassName}
    >
      <h6 className={styles.title} data-testid="search-suggestions-title">
        {suggestionsTitle}
      </h6>
      {children}
    </section>
  );
};

SearchSuggestionsList.propTypes = {
  children: PropTypes.object,
  containerProps: PropTypes.object.isRequired,
  suggestionHighlighted: PropTypes.string,
  suggestionsTitle: PropTypes.string,
};

SearchSuggestionsList.defaultProps = {
  suggestionsTitle: "suggested searches",
};

export default SearchSuggestionsList;

import {
  convertDonorShoutout,
  convertExhibitSection,
  convertStatsModule,
  convertTextComparison,
  convertVideoPlayer,
} from "atoms";
import { graphql } from "gatsby";
import {
  convertCards,
  convertCheckpoint,
  convertCommunityCuration,
  convertFullWidthSingleImage,
  convertGallery,
  convertImageComparison,
  convertPanorama,
  convertPassiveGallery,
  convertShowstopper,
  convertSiblingNavigation,
  convertSimpleImagePromo,
  convertSimpleVoyagerPromo,
  convertTextPromo,
  convertThematicCardListing,
  convertTwoColImagePromo,
  convertVideoPromo,
} from "molecules";
import {
  convertDissection,
  convertNCNWWheel,
  convertQuote,
  convertQuoteGallery,
  convertTimeline,
} from "organisms";

const subConverters = {
  communityCuration: convertCommunityCuration,
  cards: convertCards,
  checkpoint: convertCheckpoint,
  chapterListing: convertSiblingNavigation,
  dissection: convertDissection,
  donorShoutout: convertDonorShoutout,
  exhibitSection: convertExhibitSection,
  fullWidthSingleImage: convertFullWidthSingleImage,
  gallery: convertGallery,
  imageComparison: convertImageComparison,
  ncnwWheel: convertNCNWWheel,
  panorama: convertPanorama,
  quoteGallery: convertQuoteGallery,
  quotePromo: convertQuote,
  passiveGallery: convertPassiveGallery,
  pillarListing: convertThematicCardListing,
  showstopper: convertShowstopper,
  simpleImagePromo: convertSimpleImagePromo,
  simpleVoyagerPromo: convertSimpleVoyagerPromo,
  statsModule: convertStatsModule,
  textComparison: convertTextComparison,
  textPromo: convertTextPromo,
  timeline: convertTimeline,
  twoColImagePromo: convertTwoColImagePromo,
  video: convertVideoPlayer,
  videoPromo: convertVideoPromo,
};

/**
 * The regex used to extract a component name from their GraphQL __typename
 * (e.g. CraftAPI_componentList_statsPromo_BlockType becomes statsPromo)
 */
const BLOCK_REGEX = /.*_(.*)_BlockType/m;

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ComponentListFragment = graphql`
  fragment ComponentListFragment on CraftAPI_componentList_MatrixField {
    __typename
    ...CardsFragment
    ...CheckpointFragment
    ...ChapterListingFragment
    ...CommunityCurationFragment
    ...DissectionFragment
    ...DonorShoutoutFragment
    ...ExhibitSectionFragment
    ...FullWidthSingleImageFragment
    ...GalleryFragment
    ...ImageComparisonFragment
    ...NCNWWheelFragment
    ...PanoramaFragment
    ...PassiveGalleryFragment
    ...PillarListingFragment
    ...QuoteFragment
    ...QuoteGalleryFragment
    ...ShowstopperFragment
    ...SimpleImagePromoFragment
    ...SimpleVoyagerPromoFragment
    ...StatsFragment
    ...TextComparisonFragment
    ...TextPromoFragment
    ...TimelineFragment
    ...TwoColImagePromoFragment
    ...VideoFragment
    ...VideoPromoFragment
  }
`;

/**
 * Converts the provided component list data into an array of components. Note: because the provided data is an
 * array, it's expected that the components in the array have a __typename field used to identify the type of
 * component.
 *
 * @param componentListData the GraphQL response data
 * @returns                 the an array of components
 */
const convert = (componentListData) => {
  return (
    componentListData?.map((componentData) => {
      const type = componentData?.__typename;
      const componentName = (type || "").match(BLOCK_REGEX)?.[1];

      if (!type) {
        console.warn(
          "Could not convert component. Be sure the GraphQL fragment includes the __typename field"
        );
      } else if (!componentName) {
        console.warn(
          "Could not extract the component name from __typename. Expected something like CraftAPI_componentList_componentName_BlockType"
        );
      } else {
        return subConverters[componentName]?.(componentData);
      }
    }) || []
  );
};

export { ComponentListFragment, convert };

import PropTypes from "prop-types";
import React from "react";

import * as styles from "./mainnav-button.module.scss";

const MainNavButton = ({ ariaLabel, className, iconClass, onClick, text }) => (
  <button
    className={`${className} ${styles.navButton}`}
    data-testid="nav-button"
    onClick={(e) => onClick(e)}
  >
    <i className={iconClass} data-testid="icon" />
    {text && <span>{text}</span>}
    {ariaLabel && <span className="visually-hidden">{ariaLabel}</span>}
  </button>
);

MainNavButton.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

MainNavButton.defaultProps = {
  className: "",
  onClick: () => {},
};

export default MainNavButton;

import { useEffect, useState } from "react";

/** The default threshold indicating that the element has been scrolled to (25% is visible) **/
const THRESHOLD_START = 0.25;
/** The default threshold indicating that the element is being scrolled away from (40% is visible) **/
const THRESHOLD_PAST = 0.4;
/** The default Stage 2 threshold for scattered hero indicating that the element is being down (85% is visible) **/
const THRESHOLD_STAGE2 = 0.85;

/** Determines if the element is above the viewport (element has a negative top value) **/
const isAboveViewport = (entry) => entry?.boundingClientRect?.top < 0;
/** Determines if the user scrolled past 15% of the element **/
const scrolledPastStage2 = (entry, stage2Threshold) =>
  isAboveViewport(entry) && entry?.intersectionRatio < stage2Threshold;
/** Determines if the user scrolled past (most of) the element **/
const scrolledPastElement = (entry, endThreshold) =>
  isAboveViewport(entry) && entry?.intersectionRatio < endThreshold;
/** Determines if the user has scrolled back to the element **/
const scrolledBackElement = (entry, endThreshold) =>
  isAboveViewport(entry) && entry?.intersectionRatio > endThreshold;

/**
 * This hook can be used to toggle CSS classes to an element based on how it is scrolled into the viewport:
 *
 *   - "unscrolled" class is true if the element has never been scrolled into the viewport
 *   - "scrolledPast" class is true if the element is above the viewport
 *   - "scrolledStage2" class is used inside Scattered Hero component only. This class gets applied at stage 2 when the user scrolls 15% of the element
 *
 * Once an element has been scrolled to, "unscrolled" will never be false (i.e. scrolling to an element is a one time
 * animation.) However, "scrolledPast" can toggle back and forth if the element comes back into view.
 *
 * NOTE: When applying CSS changes, it's a good idea to keep the overall height of the element "stable". Example: if
 * shrinking a top margin inside of the element, apply the same amount to a growing bottom margin. Regardless of the
 * state of the animation, the element will always be the same height which will eliminate scroll changes with the
 * overall page and prevent other elements from moving around.
 *
 * @param ref                                               the reference to the element to observe
 * @param options                                           overrides for thresholds
 * @returns {{scrolledPast: boolean, unscrolled: boolean, scrolledStage2: boolean}}  an object to be used with classnames for the purposes of
 *                                                          applying the unscrolled/scrolledPast CSS classes
 */
const useScrollObserver = (
  ref,
  {
    startThreshold = THRESHOLD_START,
    endThreshold = THRESHOLD_PAST,
    stage2Threshold = THRESHOLD_STAGE2,
  } = {}
) => {
  const [scrolledTo, setScrolledTo] = useState(false);
  const [scrolledPast, setScrolledPast] = useState(false);
  const [scrolledStage2, setScrolledStage2] = useState(false);

  useEffect(() => {
    const reference = ref?.current;

    const onIntersection = (entries) => {
      if (entries?.[0]?.isIntersecting) {
        // Some portion is visible - consider it scrolled to
        setScrolledTo(true);
        if (scrolledPastElement(entries[0], endThreshold)) {
          // Element is above the screen - consider it scrolled past
          setScrolledPast(true);
          setScrolledStage2(false);
        } else if (scrolledPastStage2(entries[0], stage2Threshold)) {
          // Element has been scrolled 15% - consider at stage 2
          setScrolledStage2(true);
          setScrolledPast(false);
        } else if (scrolledBackElement(entries[0], endThreshold)) {
          // Element is above the screen, but the user is scrolling up to it
          setScrolledPast(false);
          setScrolledStage2(false);
        }
      }
    };

    if (IntersectionObserver) {
      // On thinner viewports (e.g. mobile), elements are taller which means it takes
      // more scrolling to reveal 25% of the component, leading to delayed entrance animations.
      // To compensate, we'll pull back on the amount that needs to be visible based on
      // the browser width.
      const coefficient = Math.min(
        1.0,
        0.2 + 0.8 * ((Math.max(window.innerWidth, 320) - 320) / 680)
      );

      const observer = new IntersectionObserver(onIntersection, {
        root: null,
        threshold: [
          startThreshold * coefficient,
          stage2Threshold,
          1 - stage2Threshold,
          endThreshold,
        ],
      });

      observer.observe(reference);

      // Remove observer on element removal
      return () => observer.unobserve(reference);
    }
  });

  return {
    unscrolled: !scrolledTo,
    scrolledStage2,
    scrolledPast,
  };
};

export default useScrollObserver;

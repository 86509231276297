import { NMAAHCPropTypes, ThemeContext, useScrollObserver } from "assets";
import { FormattedText } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { ExpandableImage, TextPromo } from "molecules";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./two-col-image-promo.module.scss";

const TwoColImagePromo = ({
  backgroundImageUrl,
  backgroundCover,
  topAlignColumns,
  fontColor,
  imageOne,
  imageOneCaption,
  imageOneModalText,
  imageOneText,
  imageTwo,
  imageTwoCaption,
  imageTwoModalText,
  imageTwoText,
  promoTitle,
  promoSummary,
  smallSubtitleOne,
  smallSubtitleTwo,
  smallTitleOne,
  smallTitleTwo,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);
  const { theme } = useContext(ThemeContext);

  const getColumn = (image, caption, text, title, subtitle, startIndex) => {
    // Left-offset on the outer element for the first column
    const outerOffset = startIndex === 0;
    // Left-offset on the inner element for the second column
    const innerOffset = startIndex === 1;

    return (
      <div
        className={classNames("col-sm-5", "col-xs-12", {
          "col-sm-offset-1": outerOffset,
          [styles.staggeredColumnHeight]: innerOffset && !topAlignColumns,
        })}
        data-testid={"image-column"}
      >
        <div className="row">
          <div
            className={classNames("col-sm-11", "col-xs-12", {
              "col-sm-offset-1": innerOffset,
            })}
          >
            <ExpandableImage
              galleryMedia={[
                {
                  image: [imageOne],
                  imageDescription: imageOneModalText,
                },
                {
                  image: [imageTwo],
                  imageDescription: imageTwoModalText,
                },
              ]}
              galleryStartIndex={startIndex}
              image={image}
              imageCaption={caption}
              modalText={text}
            />
          </div>
        </div>
        <div className="row">
          <div
            className={classNames("col-sm-11", "col-xs-12", {
              "col-sm-offset-1": innerOffset,
            })}
          >
            <FormattedText
              className={styles.smallTitle}
              outerElement={<h3 />}
              text={title}
            />
            {subtitle && (
              <FormattedText
                className={"large-font"}
                outerElement={<p />}
                text={subtitle}
                theme={theme}
              />
            )}
            <FormattedText className={styles.text} text={text} theme={theme} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "container-fluid",
        styles.twoColImagePromo,
        scrolledClasses,
        {
          [styles.lightFont]: fontColor == "light",
          [styles.darkFont]: fontColor == "dark",
          [styles.backgroundImage]: backgroundImageUrl,
          [styles.backgroundCover]: backgroundCover,
        }
      )}
      data-testid="two-col-image-promo"
      ref={ref}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      {promoTitle && (
        <TextPromo
          summary={promoSummary}
          title={promoTitle}
          withComponentSpacing={false}
        />
      )}
      <div className="row">
        {getColumn(
          imageOne,
          imageOneCaption,
          imageOneText,
          smallTitleOne,
          smallSubtitleOne,
          0
        )}
        {getColumn(
          imageTwo,
          imageTwoCaption,
          imageTwoText,
          smallTitleTwo,
          smallSubtitleTwo,
          1
        )}
      </div>
    </div>
  );
};

TwoColImagePromo.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  imageOne: NMAAHCPropTypes.Image.isRequired,
  imageOneCaption: PropTypes.string,
  imageOneModalText: PropTypes.string,
  imageOneText: PropTypes.string,
  imageTwo: NMAAHCPropTypes.Image.isRequired,
  imageTwoCaption: PropTypes.string,
  imageTwoModalText: PropTypes.string,
  imageTwoText: PropTypes.string,
  promoSummary: PropTypes.string,
  promoTitle: PropTypes.string,
  smallSubtitleOne: PropTypes.string,
  smallSubtitleTwo: PropTypes.string,
  smallTitleOne: PropTypes.string,
  smallTitleTwo: PropTypes.string,
  topAlignColumns: PropTypes.bool,
};

const TwoColImagePromoFragment = graphql`
  fragment TwoColImagePromoFragment on CraftAPI_componentList_twoColImagePromo_BlockType {
    id
    promoTitle
    promoSummary
    topAlignColumns
    column1 {
      ... on CraftAPI_column1_BlockType {
        imageCaption
        modalImageText
        smallTitle
        smallSubtitle
        text
        image {
          ... on CraftAPI_image_Asset {
            ...ImageMetadataFragment
          }
        }
      }
    }
    column2 {
      ... on CraftAPI_column2_BlockType {
        imageCaption
        modalImageText
        smallTitle
        smallSubtitle
        text
        image {
          ... on CraftAPI_image_Asset {
            ...ImageMetadataFragment
          }
        }
      }
    }
    fontColor
    backgroundImage {
      url
    }
    backgroundCover
  }
`;

const convert = (twoColImagePromoData) => {
  return (
    <TwoColImagePromo
      backgroundCover={twoColImagePromoData.backgroundCover}
      backgroundImageUrl={twoColImagePromoData.backgroundImage?.[0]?.url}
      fontColor={twoColImagePromoData.fontColor}
      imageOne={twoColImagePromoData.column1?.[0]?.image?.[0]}
      imageOneCaption={twoColImagePromoData.column1?.[0]?.imageCaption}
      imageOneModalText={twoColImagePromoData.column1?.[0]?.modalImageText}
      imageOneText={twoColImagePromoData.column1?.[0]?.text}
      imageTwo={twoColImagePromoData.column2?.[0]?.image?.[0]}
      imageTwoCaption={twoColImagePromoData.column2?.[0]?.imageCaption}
      imageTwoModalText={twoColImagePromoData.column2?.[0]?.modalImageText}
      imageTwoText={twoColImagePromoData.column2?.[0]?.text}
      promoSummary={twoColImagePromoData.promoSummary}
      promoTitle={twoColImagePromoData.promoTitle}
      smallSubtitleOne={twoColImagePromoData.column1?.[0]?.smallSubtitle}
      smallSubtitleTwo={twoColImagePromoData.column2?.[0]?.smallSubtitle}
      smallTitleOne={twoColImagePromoData.column1?.[0]?.smallTitle}
      smallTitleTwo={twoColImagePromoData.column2?.[0]?.smallTitle}
      topAlignColumns={twoColImagePromoData.topAlignColumns}
    />
  );
};

export { convert, TwoColImagePromo as default, TwoColImagePromoFragment };

import { NMAAHCPropTypes } from "assets";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./text-pill.module.scss";

const TextPill = ({ withLightning, style, size, text }) => {
  const pillClass = classNames(styles.container, {
    [styles.tag]: style === "tag",
    [styles.blackPill]: style === "dark",
    [styles.whitePill]: style === "light",
    [styles.small]: size === NMAAHCPropTypes.SizeSmall,
  });

  return (
    <span aria-hidden="true" className={pillClass} data-testid="text-pill">
      {withLightning && <i className="icon-thunderbolt" />}
      {text}
    </span>
  );
};

TextPill.propTypes = {
  size: NMAAHCPropTypes.Size,
  style: PropTypes.oneOf(["default", "light", "dark", "tag"]),
  text: PropTypes.string.isRequired,
  withLightning: PropTypes.bool,
};

TextPill.defaultProps = {
  size: NMAAHCPropTypes.SizeLarge,
};

export default TextPill;

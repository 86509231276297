import { useOnOutsideEvent } from "assets";
import { Checkbox } from "atoms";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { connectRefinementList } from "react-instantsearch-dom";

import * as styles from "./refinement-filter.module.scss";

const RefinementFilter = connectRefinementList(
  ({ openButtonTitle, items, refine }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [highlitOptions, setHighlitOptions] = useState([]);
    const filterRef = useRef();

    useOnOutsideEvent(filterRef, () => setMenuOpen(false));

    const onApply = () => {
      refine(highlitOptions);
      setMenuOpen(false);
    };

    const onClear = () => {
      setHighlitOptions([]);
      refine([]);
    };

    const updateCheckedEntryHighlitTracking = (entryArray, e) => {
      e.preventDefault();
      setHighlitOptions((prevState) => {
        const flatState = prevState.flat();
        const entryText = entryArray[0];
        if (flatState.includes(entryText)) {
          let copiedArray = JSON.parse(JSON.stringify(flatState));
          let index = copiedArray.indexOf(entryText);
          if (index !== -1) {
            copiedArray.splice(index, 1);
          }
          return copiedArray.map((unit) => [unit]);
        } else {
          let copiedArray = JSON.parse(JSON.stringify(flatState));
          copiedArray?.push(entryText);
          return copiedArray.map((unit) => [unit]);
        }
      });
    };

    if (items.length === 0) return null;

    return (
      <div
        className={styles.refinementFilterContainer}
        data-testid="refinement-filter"
        ref={filterRef}
      >
        <button
          aria-expanded={menuOpen}
          aria-label={`${openButtonTitle} filter`}
          className={classNames(styles.openButton, {
            [styles.open]: menuOpen,
          })}
          data-testid="refinementfilter-open-button"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className={styles.flexSpan}>{openButtonTitle}</span>
          <span className={styles.flexSpan}>
            <i className={classNames("icon-down-carrot", styles.carrotIcon)} />
          </span>
        </button>

        {menuOpen && (
          <div className={classNames(styles.menu)}>
            {items?.map((item) => {
              const entryArray =
                item.value.length > 1
                  ? item.value.filter((i) => typeof i === "string")
                  : item.value;
              return (
                <button
                  className={styles.filterElementContainer}
                  key={item.label}
                  onClick={(e) => {
                    updateCheckedEntryHighlitTracking(entryArray, e);
                  }}
                >
                  {/* checkbox onclick is uneeded because wrapping div has onClick */}
                  <Checkbox
                    checked={highlitOptions.flat().includes(entryArray[0])}
                    onClick={() => {}}
                  />
                  <div className={styles.checkboxElementTextWrapper}>
                    {item.label
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </div>
                </button>
              );
            })}
            <div className={styles.controlsContainer}>
              <button
                className={styles.clearControlOption}
                onClick={() => onClear()}
              >
                Clear
              </button>
              <button
                className={styles.applyControlOption}
                onClick={() => onApply()}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

RefinementFilter.propTypes = {
  items: PropTypes.object,
  openButtonTitle: PropTypes.string,
};

export default RefinementFilter;

import { useEffect } from "react";

const useOnOutsideEvent = (ref, callback) => {
  useEffect(() => {
    const handleOutsideEvent = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleOutsideEvent);
    document.addEventListener("keyup", handleOutsideEvent);
    return () => {
      document.removeEventListener("click", handleOutsideEvent);
      document.removeEventListener("keyup", handleOutsideEvent);
    };
  });
};

export default useOnOutsideEvent;

import PropTypes from "prop-types";
import React from "react";

import * as styles from "./header.module.scss";

const Header = ({ className, subtitle, title }) => {
  return (
    <>
      {title && (
        <h2 className={`${styles.title} ${styles[className]}`}>
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          {title}
        </h2>
      )}
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Header;

import { useKeyDown } from "assets";
import { SearchForm } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const SearchWindow = ({ onCloseClick }) => {
  useKeyDown("Escape", () => onCloseClick());
  return (
    <div data-testid="search-window">
      <SearchForm onCloseClick={() => onCloseClick()} isModal />
    </div>
  );
};

SearchWindow.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
};
SearchWindow.defaultProps = {
  onCloseClick: () => {},
};

export default SearchWindow;

import PropTypes from "prop-types";
import React from "react";

const Heading = ({ level, ...props }) => {
  const H = `h${level}`;
  return <H {...props} />;
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};

Heading.defaultProps = {
  level: 1,
};

export default Heading;

import { useEffect, useState } from "react";

// Params are the source for the audio (file or url) and a ref for the audio tag
// Typically
//  const audioRef = useRef();
//  const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer(audioRef);
//
//  <audio ref={audioRef} src={targetsource}/>
//
function useAudioPlayer(audioRef) {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();

  useEffect(() => {
    // state setters wrappers
    const setAudioData = () => {
      setDuration(audioRef?.current.duration);
      setCurTime(audioRef?.current.currentTime);
    };

    const setAudioTime = () => setCurTime(audioRef?.current?.currentTime);

    // DOM listeners: update React state on DOM events
    audioRef?.current.addEventListener("loadeddata", setAudioData);

    audioRef?.current.addEventListener("timeupdate", setAudioTime);

    // React state listeners: update DOM on React state changes
    playing ? audioRef?.current.play() : audioRef?.current.pause();

    if (clickedTime && clickedTime !== curTime) {
      audioRef.current.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audioRef?.current?.removeEventListener("timeupdate", setAudioTime);
      audioRef?.current?.removeEventListener("loadeddata", setAudioData);
    };
  }, [playing, clickedTime, curTime, audioRef]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
  };
}

export default useAudioPlayer;

import { Display, Theme } from "assets";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./close-button.module.scss";

const CloseButton = ({ labelDisplay, onClick, theme, to, toState, show }) => {
  const buttonClassName = classNames(
    styles.closeButton,
    { hidden: !show },
    Theme.addClass(theme, false)
  );
  const spanClassName = classNames(Display.addClass(labelDisplay));

  let Button = "button";
  let linkProps = {};
  if (to) {
    Button = Link;
    linkProps = { to, state: toState };
  }

  return (
    <Button
      className={buttonClassName}
      data-testid="close-button"
      onClick={() => onClick()}
      {...linkProps}
    >
      <span className={spanClassName}>Close</span>
      <span className={styles.icon}>&times;</span>
    </Button>
  );
};

CloseButton.propTypes = {
  labelDisplay: Display.PropType,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  to: PropTypes.string,
  toState: PropTypes.shape({}),
  ...Theme.PropType,
};

CloseButton.defaultProps = {
  onClick: () => {},
  labelDisplay: Display.ShowDesktop,
  show: true,
  theme: Theme.Dark,
};

export default CloseButton;

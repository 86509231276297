import { Display, NMAAHCPropTypes, Theme } from "assets";
import classNames from "classnames";
import { graphql } from "gatsby";
import { SubNavList } from "molecules";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./sibling-navigation.module.scss";

const LABEL_MAP = {};
LABEL_MAP.exhibit = { label: "Exhibit", shortLabel: "Ex" };
LABEL_MAP.pillar = {
  label: "Part",
  shortLabel: "Part",
  parent: LABEL_MAP.exhibit,
};
LABEL_MAP.chapter = {
  label: "Chapter",
  shortLabel: "Ch.",
  parent: LABEL_MAP.pillar,
};
LABEL_MAP.cluster = {
  label: "Section",
  shortLabel: "Sec.",
  parent: LABEL_MAP.chapter,
};

const SiblingNavigation = ({
  activePageId,
  chronoPresentation,
  typeHandle,
  pages,
}) => {
  const navClass = classNames(
    "container-fluid",
    styles.siblingNavigation,
    Theme.addClass(Theme.Dark, true, true)
  );

  const subNavClass = classNames(
    styles.subnav,
    "col-xs-offset-0 col-xs-12 col-lg-offset-1 col-lg-10",
    {
      ...Theme.addClass(Theme.Dark, true),
    }
  );

  if (!chronoPresentation) {
    LABEL_MAP.pillar.label = "Theme";
    LABEL_MAP.pillar.shortLabel = "Theme";
  }

  const mobilePageLabel = `${pages?.length || 0} ${
    LABEL_MAP[typeHandle].label
  }${pages?.length === 1 ? "" : "s"}`;
  const desktopPageLabel = `${pages?.length} Total ${
    LABEL_MAP[typeHandle].label
  }${pages?.length === 1 ? "" : "s"}`;

  const subNavData = pages?.map((page, i) => ({
    activePage: page.id === activePageId,
    byline: page.shortDescription,
    img: page.largeImage?.[0],
    imgAlt: `${LABEL_MAP[typeHandle].label} ${i + 1} Thumbnail`,
    subtitle: `${LABEL_MAP[typeHandle].label} ${String(i + 1).padStart(
      2,
      "0"
    )}`,
    title: page.title,
    to: `/${page.uri}`,
  }));

  return (
    <nav
      aria-label="Sibling Navigation"
      className={navClass}
      data-testid="sibling-navigation"
      key="sibling-navigation"
    >
      <div className={"row middle-xs"}>
        <div className={"center col-xs-12 col-lg-offset-1 col-lg-10"}>
          <span className={styles.pageLabel}>
            <i className={`${styles.icon} icon-stacked-box`} />
            <strong
              className="hidden-mobile hidden-tablet"
              data-testid="page-label"
            >
              {desktopPageLabel}
            </strong>
            <strong className="hidden-desktop">{mobilePageLabel}</strong>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-offset-1 col-lg-10">
          <div className={styles.separator} />
        </div>
      </div>
      <div className="row">
        <div className={subNavClass}>
          <SubNavList
            chronoPresentation={chronoPresentation}
            data={subNavData}
            mode="sibling"
            showBylines={Display.ShowDesktop}
            showDividers={Display.ShowDesktop}
            theme={Theme.Dark}
            fullWidth
          />
        </div>
      </div>
    </nav>
  );
};

SiblingNavigation.propTypes = {
  activePageId: PropTypes.string,
  chronoPresentation: PropTypes.bool,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      largeImage: PropTypes.arrayOf(NMAAHCPropTypes.Image),
      shortDescription: PropTypes.string,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
  typeHandle: PropTypes.oneOf(["chapter", "cluster", "pillar"]),
};

const ChapterNavigationFragment = graphql`
  fragment ChapterNavigationFragment on CraftAPI_exhibit_chapter_Entry {
    id
    largeImage: coverImage {
      ... on CraftAPI_image_Asset {
        altText
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
        url(transform: "subnav")
      }
    }
    shortDescription
    title
    uri
  }
`;

const PillarNavigationFragment = graphql`
  fragment PillarNavigationFragment on CraftAPI_exhibit_pillar_Entry {
    id
    shortDescription
    uri
    title
  }
`;

/**
 * Converts the provided page navigation data into a sibling navigation
 *
 * @param navData  the GraphQL response data
 * @returns        the sibling navigation
 */
const convert = (navData) => {
  return (
    <SiblingNavigation {...navData} key={`${navData.typeHandle}-navigation`} />
  );
};

export {
  ChapterNavigationFragment,
  convert,
  SiblingNavigation as default,
  PillarNavigationFragment,
};

import { useScrollObserver } from "assets";
import building from "assets/images/logo-intro/building.png";
import buildingWebp from "assets/images/logo-intro/building.webp";
import noise from "assets/images/logo-intro/noise.png";
import reflection from "assets/images/logo-intro/reflection.png";
import reflectionWebp from "assets/images/logo-intro/reflection.webp";
import shadow from "assets/images/logo-intro/shadow.png";
import shadowWebp from "assets/images/logo-intro/shadow.webp";
import sheenLeft from "assets/images/logo-intro/sheenLeft.png";
import sheenLeftWebp from "assets/images/logo-intro/sheenLeft.webp";
import sheenRight from "assets/images/logo-intro/sheenRight.png";
import sheenRightWebp from "assets/images/logo-intro/sheenRight.webp";
import classNames from "classnames";
import React, { useRef } from "react";

import * as styles from "./logo-intro.module.scss";

const LogoIntro = () => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref, { endThreshold: 0.9 });

  const classes = classNames(styles.introContainer, scrolledClasses);

  return (
    <div className={classes} ref={ref}>
      <div
        className={styles.noise}
        style={{ backgroundImage: `url(${noise})` }}
      />
      <div className={styles.spotlight} />
      <div className={styles.eye} />

      <picture>
        <source srcSet={buildingWebp} type="image/webp" />
        <img alt="NMAAHC Building" src={building} />
      </picture>

      <picture>
        <source srcSet={sheenLeftWebp} type="image/webp" />
        <img alt="" className={styles.sheenLeft} src={sheenLeft} />
      </picture>

      <picture>
        <source srcSet={sheenRightWebp} type="image/webp" />
        <img alt="" className={styles.sheenRight} src={sheenRight} />
      </picture>

      <picture>
        <source srcSet={shadowWebp} type="image/webp" />
        <img alt="" className={styles.shadow} src={shadow} />
      </picture>

      <picture>
        <source srcSet={reflectionWebp} type="image/webp" />
        <img alt="" className={styles.reflection} src={reflection} />
      </picture>

      <div className={styles.textContainer}>
        <h1>Welcome to the Searchable Museum</h1>
        <p>
          <span className={styles.line1}>A place to explore</span>
          <span className={styles.line2}>
            <em>history</em> and <em>culture</em>
          </span>
          <span className={styles.line3}>through an</span>
          <span className={styles.line4}>
            <em>African American lens</em>
          </span>
        </p>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export default LogoIntro;

// extracted by mini-css-extract-plugin
export var hidden = "timeline-module--hidden--fkrMd";
export var innerSlideContainer = "timeline-module--innerSlideContainer--hV3tW";
export var mobileWidth = "767px";
export var navigationContainer = "timeline-module--navigationContainer--dmXub";
export var outerSlideContainer = "timeline-module--outerSlideContainer--JkxEp";
export var slideStyle = "timeline-module--slideStyle--lQmr-";
export var timelineContainer = "timeline-module--timelineContainer--gVtWT";
export var timelineTitle = "timeline-module--timelineTitle--OxjuJ";
export var timelineTitleContainer = "timeline-module--timelineTitleContainer--h5o+S";
export var timelineTitleRow = "timeline-module--timelineTitleRow--tYE4X";
export var transparentBg = "timeline-module--transparentBg--+lYFs";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./toggle-switch.module.scss";

const ToggleSwitch = ({ checked, disabled, onClick, text }) => {
  return (
    <div className={styles.toggle} data-testid="toggle-switch">
      <label>
        <input
          defaultChecked={checked}
          disabled={disabled}
          onClick={onClick}
          role="switch"
          type="checkbox"
        />
        <span>{text}</span>
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  checked: false,
  disabled: false,
};

export default ToggleSwitch;

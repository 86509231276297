// extracted by mini-css-extract-plugin
export var buttonBox = "constellation-page-module--buttonBox--JkMif";
export var buttonEnter = "constellation-page-module--buttonEnter--D4DBq";
export var buttonEnterActive = "constellation-page-module--buttonEnterActive--ZCqF8";
export var constellationIntro = "constellation-page-module--constellationIntro--Eh7C2";
export var constellationTimeline = "constellation-page-module--constellationTimeline--oKAue";
export var container = "constellation-page-module--container--9kdKo";
export var date = "constellation-page-module--date--MVMZ8";
export var dateRange = "constellation-page-module--dateRange--Mxupj";
export var description = "constellation-page-module--description--X9+UG";
export var innerSlideContainer = "constellation-page-module--innerSlideContainer--hQpzx";
export var navigation = "constellation-page-module--navigation--NAh+H";
export var outerSlideContainer = "constellation-page-module--outerSlideContainer--YY3nQ";
export var startDate = "constellation-page-module--startDate--9sVU0";
export var subtitle = "constellation-page-module--subtitle--pjgrR";
export var timelineTicks = "constellation-page-module--timelineTicks--560GU";
export var timelineToggle = "constellation-page-module--timelineToggle--Cph1g";
export var toggleActive = "constellation-page-module--toggleActive--c5VlN";
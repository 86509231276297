// extracted by mini-css-extract-plugin
export var activeNavDot = "carousel-wrapper-module--activeNavDot--lWeuh";
export var carousel = "carousel-wrapper-module--carousel--YzQEm";
export var carouselButton = "carousel-wrapper-module--carouselButton--TMQAW";
export var carouselButtonNext = "carousel-wrapper-module--carouselButtonNext--o5FA8";
export var carouselButtonPrev = "carousel-wrapper-module--carouselButtonPrev--7DAy6";
export var carouselContainer = "carousel-wrapper-module--carouselContainer--ZqsKk";
export var carouselItem = "carousel-wrapper-module--carouselItem--Gk4d9";
export var carouselItemImg = "carousel-wrapper-module--carouselItemImg--rbsu9";
export var carouselItemImgWrapper = "carousel-wrapper-module--carouselItemImgWrapper--xPPt3";
export var carouselNav = "carousel-wrapper-module--carouselNav--RzUvE";
export var carouselNavDot = "carousel-wrapper-module--carouselNavDot--C6xiN";
export var carouselNavDots = "carousel-wrapper-module--carouselNavDots--f1lJL";
export var carouselSlide = "carousel-wrapper-module--carouselSlide--oI3iv";
export var carouselViewport = "carousel-wrapper-module--carouselViewport--c7GOp";
export var expandButton = "carousel-wrapper-module--expandButton--g6hUz";
export var leftAlign = "carousel-wrapper-module--leftAlign--Me9n7";
export var lightFont = "carousel-wrapper-module--lightFont--jDeJG";
// extracted by mini-css-extract-plugin
export var ctnBtn = "exhibitions-card-module--ctnBtn--y3I65";
export var ctnDescription = "exhibitions-card-module--ctnDescription--zsvoE";
export var ctnImg = "exhibitions-card-module--ctnImg--QPkY6";
export var ctnText = "exhibitions-card-module--ctnText--4F8zk";
export var darkFont = "exhibitions-card-module--darkFont--CY2ip";
export var doubleColumn = "exhibitions-card-module--doubleColumn--MGeUI";
export var exhibitDuration = "exhibitions-card-module--exhibitDuration--kpfzf";
export var exhibitionsCard = "exhibitions-card-module--exhibitionsCard--+ObX3";
export var highlightedStories = "exhibitions-card-module--highlightedStories--alerW";
export var historicDates = "exhibitions-card-module--historicDates--ZEqvN";
export var imgWrapper = "exhibitions-card-module--imgWrapper--KQ8zS";
export var innerWrapper = "exhibitions-card-module--innerWrapper--+nm8V";
export var lightFont = "exhibitions-card-module--lightFont--7Nwe4";
export var location = "exhibitions-card-module--location--0C0Zg";
export var longDescription = "exhibitions-card-module--longDescription--YOiyJ";
export var showImg = "exhibitions-card-module--showImg--YoDIT";
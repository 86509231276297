import React from "react";

import * as styles from "./error-404.module.scss";

const Error404 = () => (
  <div className={styles.error404} data-testid="error-404">
    <p className={styles.statusCode}>404</p>
    <p className={styles.statusMessage}>Ooops, Page not found.</p>
  </div>
);

export default Error404;

import { flattenImageFields } from "assets";
import { ActionButton } from "atoms";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./constellation-link.module.scss";

const ConstellationLink = ({ buttonText, entry, externalLink }) => (
  <div
    className={`row center-xs ${styles.linkPromo}`}
    data-testid="constellation-link"
  >
    <div className="col-xs-10 col-md-8 col-lg-7">
      <ActionButton
        href={externalLink}
        icon={externalLink ? "leave-arrow" : undefined}
        text={buttonText}
        textPlacement="left"
        to={entry?.[0]?.uri ? `/${entry?.[0]?.uri}` : undefined}
      />
    </div>
  </div>
);

ConstellationLink.propTypes = {
  buttonText: PropTypes.string.isRequired,
  entry: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
    })
  ),
  externalLink: PropTypes.string,
};

/**
 * The GraphQL fragment for retrieving external link data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ConstellationEntryFragment = graphql`
  fragment ConstellationEntryFragment on CraftAPI_constellationComponentList_entry_BlockType {
    buttonText
    entry {
      uri
    }
  }
`;

/**
 * The GraphQL fragment for retrieving external link data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ConstellationExternalLinkFragment = graphql`
  fragment ConstellationExternalLinkFragment on CraftAPI_constellationComponentList_externalLink_BlockType {
    buttonText
    externalLink
  }
`;

const convert = (entryData) => {
  return (
    <ConstellationLink
      key="constellation-component"
      {...flattenImageFields(entryData)}
    />
  );
};

export {
  ConstellationEntryFragment,
  ConstellationExternalLinkFragment,
  convert,
  ConstellationLink as default,
};
